import {
  Component,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { Attribute } from '@app/interfaces';
import { ListSelectSelectedComponent, TooltipAlignment, TooltipPlacement } from 'lib-juniper';

@Component({
  selector: 'app-filtered-list-selection',
  templateUrl: './filtered-selected-list.component.html',
  styleUrls: ['./filtered-selected-list.component.scss'],
})
export class FilteredSelectedListComponent extends ListSelectSelectedComponent<Attribute, keyof Attribute> implements OnChanges {

  TooltipPlacement = TooltipPlacement;
  TooltipAlign = TooltipAlignment;

  filterSelected = true;
  filteredSelectedAttributes: Attribute[] = [];

  @Input()
  lockedAttributes?: Attribute[];

  @Input()
  set onlySystem(onlySystem: boolean) {
    this.filterSelected = false;
    if (onlySystem) {
      this.filterSelected = true;
      this.filteredSelectedAttributes = this.selectedItems.filter(({ isSystem }) => isSystem);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.filterSelected) {
      this.filteredSelectedAttributes = this.selectedItems.filter(({ isSystem }) => isSystem);
    }
  }

  get attributes() {
    return this.filterSelected ? this.filteredSelectedAttributes : this.selectedItems;
  }

  changeIndex({ from, to }: { from: number, to: number }) {
    const currentIndex = Math.max(0, Math.min(to - 1, this.selectedItems.length - 1));
    const previousIndex = from - 1;
    this.parent?.handleReorder({ currentIndex, previousIndex });
  }

}
