<pup-table class="skeleton">
  <pup-table-head>
    <pup-table-cell *ngFor="let i of range(2)">
      <div class="paragraph"></div>
    </pup-table-cell>
  </pup-table-head>

  <pup-table-row *ngFor="let i of range(2)">
    <pup-table-cell *ngFor="let i of range(2)">
      <div class="paragraph"></div>
    </pup-table-cell>
  </pup-table-row>
</pup-table>
