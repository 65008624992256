<pup-switch [size]="SwitchSize.Small" class="additional-header">
  <pup-switch-item [selected]="selected === 'All'" (select)="select.emit('All')"
    >All ({{ items.length }})</pup-switch-item
  >
  <pup-switch-item
    [selected]="selected === 'System'"
    (select)="select.emit('System')"
    >System ({{ systemItems.length }})</pup-switch-item
  >
</pup-switch>
