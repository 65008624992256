import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ProductsModule } from '@app/pages/products/products.module';
import { JuniperModule } from 'lib-juniper';
import { TranslateModule } from '@ngx-translate/core';
import { environment } from '@env/environment';
import { SharedModule } from '@app/shared/shared.module';
import { MarketplacesModule } from '@app/pages/marketplaces/marketplaces.module';
import { ProductDetailsModule } from '@app/pages/product-details/product-details.module';
import { MarketplaceModule } from '@app/pages/marketplace/marketplace.module';
import { IntroModalComponent } from '@app/components/modals/intro-modal/intro-modal.component';
import { LoginModule } from '@app/pages/login/login.module';
import { JwtModule } from '@auth0/angular-jwt';
import { WizardsModule } from '@app/pages/wizards/wizards.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from '@app/interceptors/auth.interceptor';

@NgModule({
  declarations: [
    AppComponent,
    IntroModalComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    TranslateModule.forRoot(),
    JuniperModule,
    LoginModule,
    MarketplacesModule,
    ProductsModule,
    MarketplaceModule,
    ProductDetailsModule,
    WizardsModule,
    SharedModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: () => {
          return localStorage.getItem('access_token');
        },
        allowedDomains: environment.allowedDomains,
      },
    }),
  ],
  providers: [
    { provide: 'environment', useValue: environment },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: 'heapAppId', useValue: '357116817' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
