import { Component, Input } from '@angular/core';
import { JuniperComponent } from 'lib-juniper';
import { Marketplace } from '@app/interfaces/marketplace';
import { getCountryTranslateKey } from '@app/shared/utils';

@Component({
  selector: 'app-marketplace-tag',
  styleUrls: ['marketplace-tag.component.scss'],
  templateUrl: 'marketplace-tag.component.html'
})
export class MarketplaceTagComponent extends JuniperComponent {

  @Input()
  marketplace!: Marketplace;

  get flagImageSrc() {
    return `/assets/visuals/flags/${this.marketplace?.country?.code}.svg`
  }

  getMerchantLogo(merchant: string) {
    return `/assets/visuals/merchants/${merchant}.svg`
  }

  getMerchantCentral(central: string) {
    return `/assets/visuals/merchants/amazon_${central}_icon_small.svg`
  }

  get country() {
    return getCountryTranslateKey(this.marketplace?.country.code);
  }

}
