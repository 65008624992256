<section class="info-heading">
  <div class="info-heading--title">
    <pup-icon path="solid/ic-info"></pup-icon>
    <span translate>marketplaces.info-bar.notice</span>
  </div>
  <pup-icon class="close-icon" path="line/ic-close" size="2rem" (click)="visible = false"></pup-icon>
</section>
<pup-paragraph>{{ mainMessage }}</pup-paragraph>
<pup-paragraph *ngIf="extraMessage" class="extra">{{ extraMessage }}</pup-paragraph>

