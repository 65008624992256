import { Injectable } from '@angular/core';

import { GroupedError, ListingStatus, Product } from '@app/interfaces';
import { Error, ErrorByListing } from '@app/interfaces/errors';
import { PaginatedResponse } from '@app/services/products.service';
import { TableSortingState } from '@app/services/ui-state.service';
import { extendParamsWithSorting } from '@app/shared/utils';
import { ApiClient, RequestParameters } from 'lib-platform-common';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

type AllOrIdList = { all: boolean, ids: string[] };

@Injectable({
  providedIn: 'root'
})
export class ListingsService {

  constructor(private api: ApiClient) {}

  fetchListingStatus(marketplaceId: number, category?: string): Observable<{ [x in ListingStatus]: number }> {
    const params: { category?: string } = { category };

    return this.api.get<{ [x in ListingStatus]: number }>({
      parameters: params,
      endpoint: `marketplaces/${marketplaceId}/listing_status`
    });
  }

  listMultipleProducts(marketplaceId: number, ids: string[], all: boolean = false, language: string = '', categories?: string[]) {
    interface Request extends AllOrIdList {
      language: string;
      categories?: string[];
    }

    const body: Request = {
      all,
      ids,
      language
    };

    if (categories?.length) {
      body.categories = categories;
    }

    return this.api.post({
      endpoint: `marketplaces/${marketplaceId}/listings`,
      data: body
    });
  }

  submitListings(ids: string[], all: boolean = false, marketplaceId: number, category?: string): Observable<{ updated_count: number, excluded?: number }> {
    interface Request extends AllOrIdList {
      marketplace_id: number;
      category?: string;
    }

    const body: Request = {
      all,
      ids,
      marketplace_id: marketplaceId
    };

    if (category) {
      body.category = category;
    }

    return this.api.post({
      endpoint: 'listings/submit',
      data: body
    });
  }

  listSingleProduct(marketplaceId: number, productId: string) {
    return this.api.post({ endpoint: `marketplaces/${marketplaceId}/product/${productId}` });
  }

  fetchListings(options: {
    category?: string,
    marketplaceId: number,
    pageSize: number,
    currentPage: number,
    statuses?: ListingStatus[],
    query?: string,
    sorting?: TableSortingState,
  }): Observable<PaginatedResponse<Product>> {
    const { category, marketplaceId, pageSize, currentPage, statuses, query, sorting } = options;
    const params: RequestParameters = { currentPage, pageSize };

    if (statuses?.length) {
      params.status = statuses.join(',');
    }
    if (category) {
      params.category = category;
    }
    if (query) {
      params.query = query;
    }

    extendParamsWithSorting(params, sorting);

    return this.api.get<PaginatedResponse<Product>>({
      parameters: params,
      endpoint: `marketplaces/${marketplaceId}/listings`
    });
  }

  getSingleListing(listingId: string): Observable<Product> {
    return this.api.get<Product>({ endpoint: `listings/${listingId}` });
  }

  getSingleListingJson(listingId: string): Observable<Product> {
    return this.api.get<{ data: Product }>({ endpoint: `listings/${listingId}`, parameters: { format: 'json' } })
      .pipe(map(({ data }) => data));
  }

  editListing(listingId: string, product: Product, submitListing: boolean = true) {
    return this.api.put({
      endpoint: `listings/${listingId}`,
      data: product,
      parameters: {
        submitListing,
        format: 'raw_json'
      }
    });
  }

  fetchErrorsByCode(options: { marketplaceId: number, pageSize: number, currentPage: number, sorting?: TableSortingState, group?: string }, query?: string): Observable<PaginatedResponse<GroupedError>> {
    const { marketplaceId, pageSize, currentPage, sorting, group } = options;
    const params: RequestParameters = { currentPage, pageSize, query, group };

    extendParamsWithSorting(params, sorting);

    return this.api.get<PaginatedResponse<GroupedError>>({
      parameters: params,
      endpoint: `errors/code/marketplace/${marketplaceId}`
    });
  }

  fetchErrorsByListing(options: { marketplaceId: number, pageSize: number, currentPage: number, sorting?: TableSortingState, group?: string }, query?: string): Observable<PaginatedResponse<ErrorByListing>> {
    const { marketplaceId, pageSize, currentPage, sorting, group } = options;
    const params: RequestParameters = { currentPage, pageSize, group };

    if (query?.length) {
      params.query = query;
    }

    extendParamsWithSorting(params, sorting);

    return this.api.get<PaginatedResponse<ErrorByListing>>({
      parameters: params,
      endpoint: `errors/product/marketplace/${marketplaceId}`
    });
  }

  fetchProductErrorDetails(errorId: number): Observable<Error> {
    return this.api.get<{ data: Error }>({ endpoint: `errors/${errorId}` }).pipe(map(({ data }) => data));
  }

  /**
   * Method for fetching an array of errors for the provided error code, on the provided marketplace
   * @param errorCode Defines code for grouped errors
   * @param marketplaceId Defines id for the marketplace currently viewed
   */
  fetchProductErrorDetailsCode(errorCode: number | undefined, marketplaceId: number): Observable<Error[]> {
    return this.api.get<Error[]>({ endpoint: `errors/marketplace/${marketplaceId}?code=${errorCode}` });
  }

  fetchDeleteFailedListings(): Observable<Partial<Product>[]> {
    return this.api.get({ endpoint: `listings/status/DELETION_FAILED` });
  }

  submitRetryDeleteListings() {
    return this.api.post({ endpoint: `listings/status/DELETION_FAILED` });
  }

}
