<pup-button class="edit-button" [variant]="ButtonVariant.Tool" *ngIf="!editMode" (click)="toggleEditMode()">{{ value }}</pup-button>
<ng-container *ngIf="editMode">
  <pup-numeric-input [(value)]="value"></pup-numeric-input>
  <pup-button
    class="control-button"
    [variant]="ButtonVariant.Icon"
    (click)="save()"
    [tooltip]="'marketplaces.modal.attribute-select.save' | translate"
    [tooltipPlacement]="TooltipPlacement.Top"
  >
    <pup-icon path="line/ic-check"></pup-icon>
  </pup-button>
  <pup-button
    class="control-button"
    [variant]="ButtonVariant.Icon"
    (click)="cancel()"
    [tooltip]="'marketplaces.modal.attribute-select.cancel' | translate"
    [tooltipPlacement]="TooltipPlacement.Top"
  >
    <pup-icon path="line/ic-close"></pup-icon>
  </pup-button>
</ng-container>
