<app-header></app-header>
<pup-content>
  <pup-breadcrumbs home="/" [navigate]="navigate">
    <pup-breadcrumb>{{ 'marketplaces.header.tabs.marketplaces' | translate }}</pup-breadcrumb>
  </pup-breadcrumbs>
  <pup-heading [level]="2">
    {{ 'marketplaces.marketplaces.page-title' | translate }}

  </pup-heading>

  <ng-container *ngIf="!loading">

    <div class="marketplaces-container" *ngIf="regions.length">
      <div class="controls" *ngIf="!loading && regions.length">
        <pup-input
          class="marketplaces--search"
          [clearable]="true"
          [disabled]="loading"
          [value]="filter"
          (valueChange)="onFilterUpdate($event)"
          [placeholder]="'marketplaces.marketplaces.search-marketplace' | translate"
        >
          <pup-icon path="line/ic-search"></pup-icon>
        </pup-input>
        <pup-button (click)="handleAddMarketPlaceClick()" [variant]="ButtonVariant.Secondary">
          {{ "marketplaces.marketplaces.add-marketplace" | translate }}
        </pup-button>
      </div>

      <div class="marketplaces__filters">
        <app-marketplaces-filters
          [attributes]="marketplaceFilters"
          [selected]="selectedFilter"
          (select)="filterMarketplaces($event)">
        </app-marketplaces-filters>
      </div>

      <app-empty-placeholder
        *ngIf="!filterResults"
        class="marketplaces__empty-placeholder"
        [emptyPlaceholderDescription]="'marketplaces.marketplaces.not-found.description'"
      ></app-empty-placeholder>

      <ng-container *ngFor="let region of filteredRegions">
        <app-region-tag [region]="region" *ngIf="filterResults"></app-region-tag>

        <pup-cards>
          <pup-card
            (select)="handleMarketplaceSelect(marketplace.id)"
            *ngFor="let marketplace of filteredRegionMarketplaces[region]"
          >
            <pup-paragraph>
              <app-marketplace-type
                *ngIf="marketplace.active !== null"
                [marketplaceType]="marketplace.active"
                [showName]="true"
                [showLabel]="false"
              ></app-marketplace-type>

              <app-country-tag [marketplace]="marketplace"></app-country-tag>

              <div class="product-count">
                <span *ngIf="marketplace.totalListings !== undefined">
                  {{ marketplace.totalListings }} {{ 'marketplaces.marketplaces.products' | translate }}
                </span>

              </div>

              <app-marketplace-activity [invalid]="marketplace.invalidAuth" [active]="marketplace.activated"></app-marketplace-activity>

              <pup-icon path="line/ic-chevron/right" size="32px" color="#2f4255"></pup-icon>

            </pup-paragraph>
          </pup-card>
        </pup-cards>
      </ng-container>
    </div>
    <ng-container *ngIf="!regions.length && !filter">
      <pup-placeholder
        message="marketplaces.marketplaces.empty.message"
        description="marketplaces.marketplaces.empty.description"
        imageSrc="/assets/visuals/marketplace.svg"
      >
        <pup-button (click)="handleAddMarketPlaceClick()">
          {{ "marketplaces.marketplaces.add-marketplace" | translate }}
        </pup-button>
      </pup-placeholder>
    </ng-container>

    <ng-container *ngIf="!filteredRegions.length && filter">
      <app-empty-placeholder
        class="empty-placeholder"
        [emptyPlaceholderVariant]="PlaceholderVariant.NotFoundV2"
        [emptyPlaceholderMessage]="'marketplaces.errors.no-results.message'"
        [emptyPlaceholderDescription]="'marketplaces.marketplace.not-found.description'"
      >
      </app-empty-placeholder>
    </ng-container>
  </ng-container>

  <pup-loading-visual *ngIf="loading"></pup-loading-visual>
</pup-content>
