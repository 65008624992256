import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { JuniperModule } from 'lib-juniper';

import { ProductsPage } from './products.page';
import { SharedModule } from '@app/shared/shared.module';
import { MarketplaceSelectModal } from '@app/components/modals/marketplace-selection/marketplace-select-modal.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    RouterModule,
    TranslateModule,
    JuniperModule,
    SharedModule
  ],
  exports: [],
  declarations: [
    ProductsPage,
    MarketplaceSelectModal,
  ]
})
export class ProductsModule { }
