<ng-container *ngIf="status">
  <pup-badge *ngIf="status === ListingStatus.NotSubmitted" [class]="status">
    {{ 'marketplaces.marketplace.listing-status.not-submitted' | translate }}
  </pup-badge>

  <pup-badge *ngIf="status === ListingStatus.Queued" [class]="status">
    {{ 'marketplaces.marketplace.listing-status.queued' | translate }}
  </pup-badge>

  <pup-badge *ngIf="status === ListingStatus.Submitted" [class]="status">
    {{ 'marketplaces.marketplace.listing-status.submitted' | translate }}
  </pup-badge>

  <pup-badge *ngIf="status === ListingStatus.SubmittedWithErrors" [class]="status">
    {{ 'marketplaces.marketplace.listing-status.submitted-with-errors' | translate }}
  </pup-badge>

  <pup-badge *ngIf="status === ListingStatus.PendingFeedback" [class]="status">
    {{ 'marketplaces.marketplace.listing-status.pending-feedback' | translate }}
  </pup-badge>

  <pup-badge *ngIf="status === ListingStatus.Failed" [class]="status">
    {{ 'marketplaces.marketplace.listing-status.failed' | translate }}
  </pup-badge>

  <pup-badge
    *ngIf="status === ListingStatus.PendingDeletion || status === ListingStatus.PendingDeletionFailed"
    [class]="status"
  >
    {{ 'marketplaces.marketplace.listing-status.pending-deletion' | translate }}
  </pup-badge>
</ng-container>
