<pup-header>
  <img src="/assets/visuals/home-icon.svg" class="home-icon" routerLink="">

  <pup-menu [horizontal]="true" class="header--menu">
    <pup-menu-item [selected]="!router.url.includes('marketplaces')" routerLink="">
      <pup-icon path="line/ic-catalog"></pup-icon>
      {{ "marketplaces.header.tabs.product-catalog" | translate }}
    </pup-menu-item>
    <pup-menu-item [selected]="router.url.includes('marketplaces')"
                   class="menu--item-marketplaces"
                   routerLink="/marketplaces">
      <pup-icon path="line/ic-market"></pup-icon>
      <span>
      {{ 'marketplaces.header.tabs.marketplaces' | translate }}
      </span>
    </pup-menu-item>
  </pup-menu>
  <div class="header--logout">
    <pup-icon path="solid/ic-question" size="2rem"></pup-icon>
    <pup-button [variant]="ButtonVariant.Link" routerLink="/logout">
      <span>{{ 'marketplaces.header.logout' | translate }}</span>
      <pup-icon path="line/ic-arrow/right"></pup-icon>
    </pup-button>
  </div>
</pup-header>

<ng-template #avatar>
  <pup-avatar name="User"></pup-avatar>
</ng-template>
