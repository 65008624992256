<div [formGroup]="form" *ngIf="form">

  <div [ngSwitch]="input.controlType">
    <div *ngSwitchDefault class="image-container">
      <app-label-with-tooltip
        [label]="input?.label"
        [description]="input?.description"
        [required]="input?.required"
      ></app-label-with-tooltip>
      <div class="input-with-tooltip">
        <pup-input
          [class.numeric]="input.controlType === InputControlType.Number"
          [required]="input.required"
          [id]="input.key"
          [formControlName]="input.key"
          [invalid]="!isValid"
          [validationHint]="hint"
          [optional]="!input.required"
          [type]="inputType"
          [resizable]="input.controlType === InputControlType.TextArea"
          [maximumLength]="getAsText(input).maxLength"
          [disabled]="disabled"
          placeholder=""
        >
        </pup-input>
        <app-imported-value-tooltip
          [importedValue]="input.importedValue"
          (resetValueClick)="resetInputValue(input)"
        ></app-imported-value-tooltip>
      </div>
    </div>

    <div *ngSwitchCase="InputControlType.Image" class="image-container">
      <app-label-with-tooltip
        [label]="input?.label"
        [description]="input?.description"
        [required]="input?.required"
      ></app-label-with-tooltip>
      <img class="thumbnail" default-src [src]="input.value" />
    </div>

    <app-dynamic-toggle-input
      *ngSwitchCase="InputControlType.Toggle"
      [formControlName]="input.key"
      [input]="input"
      [invalid]="!isValid"
      [hint]="hint"
      (resetInputValue)="resetInputValue(input)"
      [disabled]="disabled"
    >
    </app-dynamic-toggle-input>

    <ng-container *ngSwitchCase="InputControlType.Group">
      <div [formGroupName]="input.key">
        <app-dynamic-form-input
          [disabled]="disabled"
          *ngFor="let item of getGroupItems(input)"
          [input]="item"
          [form]="getFormGroup(form.get(input.key))"
        >
        </app-dynamic-form-input>
      </div>
    </ng-container>

    <app-dynamic-dropdown-input
      *ngSwitchCase="InputControlType.Choice"
      [formControlName]="input.key"
      [items]="getChoiceItems(input)"
      [input]="input"
      [selectedKey]="input.value"
      [invalid]="!isValid"
      [hint]="hint"
      (resetInputValue)="resetInputValue(input)"
      [disabled]="disabled"
    ></app-dynamic-dropdown-input>


    <ng-container *ngSwitchCase="InputControlType.Date">
      <app-label-with-tooltip
        [label]="input?.label"
        [description]="input?.description"
        [required]="input?.required"
      ></app-label-with-tooltip>
      <div class="input-with-tooltip end">
        <app-dynamic-date-input
          [input]="input"
          [formControlName]="input.key"
          [invalid]="!isValid"
          [hint]="hint"
          [disabled]="disabled"
        ></app-dynamic-date-input>
        <app-imported-value-tooltip
          [importedValue]="input.importedValue"
          (resetValueClick)="resetInputValue(input)"
        ></app-imported-value-tooltip>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="InputControlType.TextGroup">
      <app-label-with-tooltip
        [label]="input?.label"
        [description]="input?.description"
        [required]="input?.required"
      ></app-label-with-tooltip>
      <app-text-group-input [input]="input" [form]="form" [disabled]="disabled"></app-text-group-input>
    </ng-container>
  </div>
</div>
