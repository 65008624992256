import { Component, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { MarketplacePage } from '@app/pages/marketplace/marketplace.page';
import { MarketplacesService } from '@app/services/marketplaces.service';
import { Marketplace } from '@app/interfaces';
import { takeUntil } from 'rxjs/operators';
import { getCountryTranslateKey } from '@app/shared/utils';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-marketplace-settings-page',
  templateUrl: './marketplace-settings.page.html',
  styleUrls: ['../../page.scss', './marketplace-settings.page.scss'],
})
export class MarketplaceSettingsPage extends MarketplacePage implements OnInit {

  marketplace!: Marketplace;
  countryName: string = '';

  constructor(
    ref: ElementRef,
    public router: Router,
    public marketplacesService: MarketplacesService,
    public activatedRoute: ActivatedRoute,
    public translateService: TranslateService
  ) {
    super(ref, router, marketplacesService, activatedRoute);
  }

  ngOnInit(): void {
    this.marketplace$.pipe(takeUntil(this.destroyed$)).subscribe((marketplace) => {
      if (marketplace) {
        this.marketplace = marketplace;

        this.countryName = this.translateService.instant(getCountryTranslateKey(marketplace.country.code));
      }
    });
  }

  updateMarketplace(marketplace: Marketplace) {
    this.marketplace = marketplace;
    this.marketplacesService.updateMarketplace(marketplace).subscribe();
  }

  handleEditMarketplaceClick() {
    this.router.navigate(['edit-marketplace', this.marketplace.id]);
  }
}
