import { Injectable } from '@angular/core';
import { ListingErrorsDisplay } from '@app/interfaces';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

const DisplayListingErrors: ListingErrorsDisplay[] = [
  { fieldName: 'action', columnKey: '', isSortable: false },
  { fieldName: 'image', columnKey: 'marketplaces.products.columns.image', isSortable: false },
  { fieldName: 'sku', columnKey: 'marketplaces.products.columns.sku', isSortable: true },
  { fieldName: 'title', columnKey: 'marketplaces.products.columns.title', isSortable: true },
  { fieldName: 'code', columnKey: 'marketplaces.errors.table.error-code', isSortable: false },
  { fieldName: 'type', columnKey: 'marketplaces.errors.table.error-type', isSortable: false },
  { fieldName: 'message', columnKey: 'marketplaces.errors.table.error-description', isSortable: false },
  { fieldName: 'submissionTimestamp', columnKey: 'marketplaces.errors.table.submission-timestamp', isSortable: true },
  { fieldName: 'submissionId', columnKey: 'marketplaces.errors.table.submission-id', isSortable: true }
];

const DisplayErrorCodes: ListingErrorsDisplay[] = [
  { fieldName: 'action', columnKey: '', isSortable: false },
  { fieldName: 'code', columnKey: 'marketplaces.errors.table.error-code', isSortable: true },
  { fieldName: 'type', columnKey: 'marketplaces.errors.table.error-type', isSortable: false },
  { fieldName: 'message', columnKey: 'marketplaces.errors.table.error-description', isSortable: false },
  { fieldName: 'affectedListings', columnKey: 'marketplaces.errors.table.affected-products', isSortable: false },
  { fieldName: 'submissionId', columnKey: 'marketplaces.errors.table.submission-id', isSortable: true },
  { fieldName: 'submissionTimestamp', columnKey: 'marketplaces.errors.table.submission-timestamp', isSortable: true }
];

@Injectable()
export class ErrorsService {

  constructor(private http: HttpClient) {}

  /**
   * Returns table columns for table sorted by products
   */
  getDisplayListingAttributes () {
    return DisplayListingErrors;
  }

  /**
   * Returns table columns for table sorted by error codes
   */
  getDisplayCodeAttributes () {
    return DisplayErrorCodes;
  }

  getDownloadLinkErrorsByCode (marketplaceId: number): Observable<string> {
    return this.http.get(`${environment.apiUrl}/errors/download/code/marketplace/${marketplaceId}?sortBy=code&orderBy=ASC`, { responseType: 'text' as 'text' });
  }

  getDownloadLinkErrorsByProduct (marketplaceId: number): Observable<string> {
    return this.http.get(`${environment.apiUrl}/errors/download/product/marketplace/${marketplaceId}?sortBy=sku&orderBy=ASC`, { responseType: 'text' as 'text' });
  }

  createDownload(data: string, type: string) {
    const formattedDate: string = new Date().toISOString().split('T')[0];
    const a = document.createElement('a');
    const filename = `${formattedDate}-${type}-errors`;

    a.href = `data:text/csv,${data}`;
    a.setAttribute('download', `${filename}.csv`);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
}
