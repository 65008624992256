<pup-floating-label *ngIf="label">
  <div class="label-wrapper">
    <span>{{ label }}</span>
    <span *ngIf="required === false" class="label-optional">Optional</span>
    <pup-icon
      *ngIf="description"
      path="solid/ic-info"
      [tooltip]="description"
      [tooltipPlacement]="TooltipPlacement.Top"
    ></pup-icon>
  </div>
</pup-floating-label>
