export const DEFAULT_CURRENT_PAGE = 1;
export const DEFAULT_PAGE_SIZE = 20;
export const MIN_PAGE_SIZE = DEFAULT_PAGE_SIZE;
export const DEFAULT_TOTAL_COUNT = 0;

export interface Pagination {
  totalCount: number;
  pageSize: number;
  currentPage: number;
  deleted?: number;
}
