import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Attribute } from '@app/interfaces';
import { JuniperComponent, SwitchSize } from 'lib-juniper';

export type Switcher = 'All' | 'System';

@Component({
  selector: 'app-filter-switcher',
  templateUrl: './filter-switcher.component.html',
  styleUrls: ['./filter-switcher.component.scss']
})
export class FilterSwitcherComponent extends JuniperComponent {

  SwitchSize = SwitchSize;

  @Input() selected: Switcher = 'All';
  @Input() items: Attribute[] = [];

  @Output() select = new EventEmitter<Switcher>();


  constructor(public ref: ElementRef) {
    super(ref);
  }

  get systemItems() {
    return this.items.filter(({isSystem}) => isSystem);
  }
}
