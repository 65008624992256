export enum MarketplaceType {
  Seller = 'seller',
  Vendor = 'vendor'
}

export type MarketplaceTypeInfo = {
  [key in MarketplaceType]: {
    name: string,
    provider: string,
    icon: string
  }
}

export type MarketplaceStatus = MarketplaceType | null;

export interface MarketplaceFilter {
  key: MarketplaceStatus,
  value: string,
  count: number
}

// TODO should coming from the api eventually
export const MARKETPLACE_TYPE_INFO: MarketplaceTypeInfo = {
  [MarketplaceType.Seller]: {
    name: 'Amazon Seller Central',
    provider: 'Amazon',
    icon: 'amazon_seller_icon.svg'
  },
  [MarketplaceType.Vendor]: {
    name: 'Amazon Vendor Central',
    provider: 'Amazon',
    icon: 'amazon_vendor_icon.svg'
  }
};
