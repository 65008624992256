import { moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, Component, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_PAGE_SIZE, ErrorGroupType,
  GroupedError,
  ListingErrorsDisplay,
  Pagination
} from '@app/interfaces';
import { ErrorByListing } from '@app/interfaces/errors';

import { Marketplace } from '@app/interfaces/marketplace';
import { MarketplacePage } from '@app/pages/marketplace/marketplace.page';
import { ErrorsService } from '@app/services/errors.service';
import { ListingsService } from '@app/services/listings.service';
import { MarketplacesService } from '@app/services/marketplaces.service';
import { PaginatedResponse } from '@app/services/products.service';
import { UiStateService } from '@app/services/ui-state.service';
import { ButtonVariant } from 'lib-juniper';
import { Observable } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';


enum ErrorType {
  Listings,
  ErrorCodes,
}

@Component({
  selector: 'app-marketplace-errors-page',
  templateUrl: './marketplace-errors.page.html',
  styleUrls: ['../../page.scss', '../marketplace.page.scss', './marketplace-errors.page.scss'],
  providers: [ErrorsService]
})
export class MarketplaceErrorsPage extends MarketplacePage {

  errors: GroupedError[] | ErrorByListing[] = [];
  markedErrors: string[] = [];
  query: string = '';

  marketplace?: Marketplace;

  loading = false;

  errorType: ErrorType = ErrorType.Listings;
  errorTypeFilter: ErrorGroupType[] = [];


  pagination: Pagination = {
    currentPage: DEFAULT_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    totalCount: 0
  };

  ButtonVariant = ButtonVariant;
  ErrorType = ErrorType;
  ErrorGroupType = ErrorGroupType;
  queryResults: boolean = true;
  displayListingColumns: ListingErrorsDisplay[] = [];
  displayCodeColumns: ListingErrorsDisplay[] = [];

  constructor(
    ref: ElementRef,
    public router: Router,
    public marketplacesService: MarketplacesService,
    private listingsService: ListingsService,
    public activatedRoute: ActivatedRoute,
    public uiStateService: UiStateService,
    private errorService: ErrorsService,
    public cdr: ChangeDetectorRef
  ) {
    super(ref, router, marketplacesService, activatedRoute);
  }

  get listingErrors(): ErrorByListing[] {
    return this.errors as ErrorByListing[];
  }

  get codeErrors(): GroupedError[] {
    return this.errors as GroupedError[];
  }

  ngOnInit(): void {
    this.loading = true;

    this.route.queryParams
      .subscribe((params) => {
        if (params.sku) {
          this.query = params.sku;
        }
      }
    );

    this.marketplace$.pipe(takeUntil(this.destroyed$)).subscribe((marketplace) => {
      if (marketplace) {
        this.marketplace = marketplace;

        const { type } = this.activatedRoute.snapshot.queryParams;
        this.errorType = type === '1' ? ErrorType.ErrorCodes : ErrorType.Listings;

        this.loadErrors();
      }
    });
  }

  loadErrors() {
    if (!this.marketplace) {
      return;
    }
    this.loading = true;
    const { pageSize, currentPage } = this.pagination;

    const sorting = this.errorType === ErrorType.ErrorCodes ?
      this.uiStateService.state?.sorting?.errorsCode :
      this.uiStateService.state?.sorting?.errorsProduct;

    const options = {
      pageSize,
      currentPage,
      sorting,
      marketplaceId: this.marketplace.id,
      group: this.errorTypeFilter[0]
    };

    const query = this.query?.length ? this.query : undefined;
    const stream$: Observable<PaginatedResponse<ErrorByListing> | PaginatedResponse<GroupedError>> = this.errorType === ErrorType.Listings ?
      this.listingsService.fetchErrorsByListing(options, query) :
      this.listingsService.fetchErrorsByCode(options, query);

    const loadingErrorType = this.errorType;

    stream$.pipe(first()).subscribe(
      ({ data, meta }) => {
        if (loadingErrorType !== this.errorType) { // TODO a better approach would be to cancel a subscription, but would require more refactoring
          return;
        }
        this.errors = data;
        this.pagination = meta;
        this.loading = false;
        this.displayListingColumns = this.errorService.getDisplayListingAttributes();
        this.displayCodeColumns = this.errorService.getDisplayCodeAttributes();
        this.queryResults = !(this.query && !this.errors.length);
      }
    );
  }

  reorderAttributes({
                      currentIndex,
                      previousIndex
                    }: { currentIndex: number, previousIndex: number }, columns: ListingErrorsDisplay[]) {
    moveItemInArray(columns, previousIndex + 1, currentIndex + 1);
    this.cdr.detectChanges();
  }

  handlePaginationChange(pagination: Partial<Pagination>) {
    this.pagination.pageSize = pagination.pageSize || DEFAULT_PAGE_SIZE;
    this.pagination.currentPage = pagination.currentPage || DEFAULT_CURRENT_PAGE;

    this.loadErrors();
  }

  openProductErrorDetails(errorId: number) {
    this.router.navigate([errorId], { relativeTo: this.activatedRoute });
  }

  openListingDetails(listingId: number) {
    this.router.navigate(['../listings', listingId], { relativeTo: this.activatedRoute });
  }

  toggleErrorType(errorType: ErrorType) {
    this.errorType = errorType;
    this.loadErrors();
    this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams: { type: this.errorType },
        queryParamsHandling: 'merge'
      });
  }

  filterErrorType(filter: ErrorGroupType) {
    if (this.errorTypeFilter.includes(filter)) {
      this.errorTypeFilter = [];
      this.loadErrors();
      return;
    }

    if (!this.errorTypeFilter.includes(filter)) {
      this.errorTypeFilter = [];
      this.errorTypeFilter.push(filter);
      this.loadErrors();
      return;
    }
  }

  handleSortingChange(change: { ascending: boolean, column: string }) {
    this.errorType === ErrorType.ErrorCodes ?
      this.uiStateService.setErrorsByCodeChange(change.column, change.ascending) :
      this.uiStateService.setErrorsByProductChange(change.column, change.ascending);
    this.loadErrors();
  }

  downloadCSV() {
    if (this.marketplace?.id) {
      if (this.errorType === ErrorType.Listings) {
        this.errorService.getDownloadLinkErrorsByProduct(this.marketplace.id).subscribe(
          (data) => {
            this.errorService.createDownload(data, 'product');
          }
        );
      }

      if (this.errorType === ErrorType.ErrorCodes) {
        this.errorService.getDownloadLinkErrorsByCode(this.marketplace.id).subscribe(
          (data) => {
            this.errorService.createDownload(data, 'code');
          }
        );
      }
    }
  }
}
