import {
  Component,
  Input
} from '@angular/core';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import { InputBase } from '@app/components/dynamic-form/inputs/input-base';
import { DynamicInputComponent } from '@app/components/dynamic-form/view/dynamic-input.component';

@Component({
  selector: 'app-text-group-input',
  styleUrls: ['./text-group-input.component.scss', '../../inputs-common.scss'],
  templateUrl: './text-group-input.component.html'
})
export class TextGroupInputComponent extends DynamicInputComponent {

  @Input()
  input!: InputBase;

  @Input()
  invalid: boolean = false;

  @Input()
  hint: string = '';

  @Input()
  form!: FormGroup;

  getFormArray(group: AbstractControl | null): FormArray {
    if (group === null) {
      return new FormArray([]);
    }
    return group as FormArray;
  }
}
