<ng-container *ngIf="marketplace">

  <pup-paragraph class="settings__auth">
    <div class="feature-container">
      <div class="toggle-container">
        <span>{{'marketplaces.marketplace-settings.auth.title' | translate}}</span>
      </div>

      <span
        class="feature-description"
        [innerHTML]="'marketplaces.marketplace-settings.auth.description' | translate"
      ></span>

      <app-marketplace-type
        *ngIf="marketplace.active !== null"
        [marketplaceType]="marketplace.active"
      ></app-marketplace-type>

      <div class="settings__marketplace-info">
        <span class="label">{{'marketplaces.marketplace-settings.auth.marketplace' | translate}}:</span>
        <span class="info">
          <app-country-tag [marketplace]="marketplace"></app-country-tag>
          <app-marketplace-activity *ngIf="marketplace.invalidAuth" [invalid]="true"></app-marketplace-activity>
        </span>
      </div>
      <div class="settings__edit-auth" *ngIf="showEdit">
        <pup-button [variant]="ButtonVariant.Secondary" (click)="handleEditMarketplaceClick()">
          {{ 'marketplaces.marketplace-settings.edit' | translate }}
        </pup-button>
      </div>
    </div>
  </pup-paragraph>

  <pup-paragraph class="settings__management">
    <div class="feature-container">
      <div class="toggle-container">
        <span>{{'marketplaces.settings.listing-submission.title' | translate}}</span>
        <pup-toggle
          [variant]="ToggleVariant.Switch"
          [toggled]="marketplace.activated"
          [tooltip]="(marketplace.activated ? 'marketplaces.marketplace.settings.toggle-management.tooltip': '') | translate"
          (toggledChange)="toggleMarketplaceProp(MarketplaceProp.Activity)"
        ></pup-toggle>
      </div>
      <span class="feature-description">
        {{'marketplaces.settings.listing-submission.body' | translate}}
      </span>
    </div>
  </pup-paragraph>

  <pup-paragraph class="setting__order-sync">
    <div class="feature-container">
      <div class="toggle-container">
        <span>{{'marketplaces.add-marketplace.features.order-sync.title' | translate}}</span>
        <span class="settings__badge">{{'marketplaces.add-marketplace.features.order-sync.badge.coming-soon' | translate}}</span>
      </div>
      <span class="feature-description">
        {{'marketplaces.add-marketplace.features.order-sync.body' | translate}}
      </span>
    </div>
  </pup-paragraph>
</ng-container>
