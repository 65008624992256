<app-table-controls
  [allToggled]="allSelected || marked.length > 0"
  [disabled]="!items.length || loading"
  [hideSelect]="true"
  [showSelectBox]="allSelected || marked.length > 0"
  [showSelectAll]="isSelectAllVisible"
  [selectedCount]="allSelected ? (total - marked.length) : marked.length"
  [selectLabel]="selectedLabel"
  [total]="total"
  [(page)]="page"
  [(perPage)]="perPage"
  (selectAll)="selectAll()"
  (toggleAll)="toggleAllOnPage()"
>
  <ng-content select=".actions"></ng-content>
</app-table-controls>
<pup-table *ngIf="!loading">
  <pup-table-head  cdkDropList cdkDropListOrientation="horizontal" (cdkDropListDropped)="drop($event)">

    <ng-container *ngFor="let column of displayColumns">
      <pup-table-cell *ngIf="column.fieldName === 'action'"></pup-table-cell>
      <pup-table-cell cdkDrag cdkDragLockAxis="x"
                      *ngIf="column.fieldName !== 'action'
                      && !column.isSortable">
        <div *cdkDragPlaceholder class="table-cell-drag-placeholder"></div>
        <pup-icon class="drag-handle" path="line/ic-drag-line"></pup-icon>
        {{ column.columnKey | translate }}
      </pup-table-cell>

      <pup-table-cell
        *ngIf="column.isSortable"
        cdkDrag
        cdkDragLockAxis="x"
        [sortable]="true"
        (sortClick)="setColumnSorting($event, column.fieldName)"
        [ascending]="sorting?.[column.fieldName]?.ascending"
        [descending]="sorting?.[column.fieldName]?.descending">
        <div *cdkDragPlaceholder class="table-cell-drag-placeholder"></div>
        <pup-icon class="drag-handle" path="line/ic-drag-line"></pup-icon>
        {{ column.columnKey | translate }}
      </pup-table-cell>
    </ng-container>
  </pup-table-head>

  <ng-container *ngIf="items.length > 0 && queryResults; else empty">
    <ng-container *ngFor="let group of items; let groupIndex = index">
      <ng-container *ngFor="let error of group.errors; let detailIndex = index">
        <pup-table-row
          *ngIf="detailIndex < 1 || expandedRows.includes(groupIndex)"
          [class]="detailIndex >= 1 && expandedRows.includes(groupIndex) ? 'listing-errors expanded':'listing-errors'"
        >
          <ng-container *ngFor="let column of displayColumns">
            <pup-table-cell  *ngIf="column.fieldName === 'action'"
                             [class.blank]="detailIndex > 0">
              <ng-container *ngIf="detailIndex === 0">
                <pup-icon
                  *ngIf="detailIndex === 0 && group.errors.length > 1"
                  class="action"
                  path="{{ expandedRows.includes(groupIndex) ? 'line/ic-chevron/down' : 'line/ic-chevron/right' }}"
                  (click)="toggleRowExpand($event, groupIndex)"
                ></pup-icon>
              </ng-container>
            </pup-table-cell>

            <pup-table-cell *ngIf="column.fieldName === 'code'"
                            class="code strong"
                            [class.blank]="detailIndex > 0"
                            (click)="handleSelectError(group.code)">
              <ng-container *ngIf="detailIndex === 0">
                {{ group?.[column.fieldName] }}
              </ng-container>
            </pup-table-cell>

            <pup-table-cell *ngIf="column.fieldName === 'type'"
                            class="error-type"
                            [ngClass]="{ other: group.group === ErrorGroupType.Other }">
              <span>
                {{ 'marketplaces.errors.table.error-type.' + group.group | translate }}
              </span>
            </pup-table-cell>

            <pup-table-cell *ngIf="column.fieldName === 'message'"
                            class="error-count">
              {{ formatErrorMessage(error, group.group) }}
              <span class="extra-error-count" *ngIf="detailIndex === 0 && group.errors.length > 1">
                +{{ group.errors.length - 1 }}
              </span>
            </pup-table-cell>

            <pup-table-cell *ngIf="column.fieldName === 'affectedListings'">
              <ng-container *ngIf="error?.[column.fieldName]">
                {{ error?.[column.fieldName]?.length }}
              </ng-container>
            </pup-table-cell>
            <pup-table-cell *ngIf="column.fieldName === 'submissionId'"
                            [class.blank]="detailIndex > 0">
              <ng-container *ngIf="detailIndex === 0">
                {{ group?.[column.fieldName] }}
              </ng-container>
            </pup-table-cell>

            <pup-table-cell *ngIf="column.fieldName === 'submissionTimestamp'"
                            [class.blank]="detailIndex > 0">
              <ng-container *ngIf="detailIndex === 0">
                {{ group.submissionTimestamp| dateTime }}
              </ng-container>
            </pup-table-cell>
          </ng-container>
        </pup-table-row>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="!queryResults">
    <app-empty-placeholder
      class="empty-placeholder"
      [emptyPlaceholderVariant]="PlaceholderVariant.NotFoundV2"
      [emptyPlaceholderMessage]="'marketplaces.errors.no-results.message'"
      [emptyPlaceholderDescription]="'marketplaces.errors.no-results.description'"
    >
    </app-empty-placeholder>
  </ng-container>

</pup-table>

<ng-template #empty>
    <app-empty-placeholder
      *ngIf="queryResults"
      class="empty-placeholder"
      [emptyPlaceholderVariant]="placeholderVariant"
      [emptyPlaceholderImgSrc]="placeholderImgSrc"
      [emptyPlaceholderMessage]="noProductsMessageKey"
      [emptyPlaceholderDescription]="noProductsDescriptionKey"
    >
    </app-empty-placeholder>
</ng-template>


<app-skeleton-table *ngIf="loading"></app-skeleton-table>
