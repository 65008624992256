import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
  ButtonSize,
  ButtonType,
  ButtonVariant,
  InputType,
  ToastService,
} from 'lib-juniper';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Login } from '@app/interfaces';
import { AuthService } from '@app/services/auth.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit {
  ButtonVariant = ButtonVariant;
  ButtonSize = ButtonSize;
  ButtonType = ButtonType;
  InputType = InputType;

  model: Login = {
    email: '',
    password: '',
  };

  submitted = false;
  loading = false;

  errorMessage: string = '';

  loginForm = new FormGroup({
    email: new FormControl(
      '',
      Validators.compose([Validators.required])
    ),
    password: new FormControl(
      '',
      Validators.compose([Validators.required])
    ),
  });

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toastService: ToastService,
    private authService: AuthService,
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      if (params.email) {
        this.loginForm.patchValue({ email: params.email });
      }
    });
  }

  validateField(key: string) {
    const control = this.loginForm.get(key);
    return !!this.errorMessage?.length || !!(control?.touched && control?.invalid);
  }

  login() {
    this.errorMessage = '';
    if (this.loginForm.invalid) {
      this.loginForm.markAllAsTouched();
      return;
    }

    this.submitted = true;
    this.loading = true;

    this.authService.login(this.loginForm.value.email, this.loginForm.value.password)
      .subscribe(
        () => {
          this.loading = false;
          this.errorMessage = '';
        },
        (error: HttpErrorResponse) => {
          this.loading = false;
          if (error.status === 401) {
            this.errorMessage = error?.error?.message;
            return;
          }
          this.errorMessage = 'markeplaces.login.error';
        }
      );
  }
}
