import { ChangeDetectorRef, Component, ElementRef, OnInit } from '@angular/core';
import {
  ButtonSize,
  ButtonVariant,
  ModalService,
  SelectVariant,
  ToastService
} from 'lib-juniper';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';

import { ActivatedRoute, Router } from '@angular/router';
import { ComponentType, MarketplacesService } from '@app/services/marketplaces.service';
import { Marketplace } from '@app/interfaces/marketplace';
import { Product, ListingStatus } from '@app/interfaces';
import { MarketplaceWithProductsPage } from '@app/pages/marketplace/marketplace-with-products.page';
import { CategoryService } from '@app/services/category.service';
import { ListingsService } from '@app/services/listings.service';
import { MarketplaceCategory } from '@app/interfaces/marketplaceCategory';
import { getCountryTranslateKey } from '@app/shared/utils';
import { LocalStorageService } from '@app/services/local-storage.service';
import { UiStateService } from '@app/services/ui-state.service';
import { SubmissionModalComponent } from '@app/components/modals/submission-modal/submission-modal.component';

@Component({
  selector: 'app-listings-page',
  templateUrl: './listings.page.html',
  styleUrls: ['../../page.scss', '../marketplace.page.scss', './listings.page.scss']
})
export class ListingsPage extends MarketplaceWithProductsPage implements OnInit {

  marketplace?: Marketplace;
  status?: { [x in ListingStatus]: number };
  categories?: MarketplaceCategory[];
  category?: MarketplaceCategory;

  get selectedCategory(): MarketplaceCategory | undefined {
    return this.category;
  }

  set selectedCategory(category: MarketplaceCategory | undefined) {
    if (category === this.category) {
      return;
    }
    this.category = category;
    this.breadcrumbs[this.breadcrumbs.length - 1].name = this.category?.categoryName ?
      this.translateService.instant(`marketplaces.listings.category.${this.category.categoryName}`) : '';

    this.resetPaginationAndLoad();
    this.fetchStatus();

    // reset selections
    this.selectedProductIds = [];
    this.allProductsSelected = false;
  }

  ProductStatus = ListingStatus;
  ButtonVariant = ButtonVariant;
  ButtonSize = ButtonSize;
  SelectVariant = SelectVariant;
  selectedStatusList: ListingStatus[] = [];
  submitTooltip: string = '';
  selectTooltip: string = '';
  tooltipType = ComponentType;

  outerSidebarOpen: boolean;
  opened: boolean = true;
  queryResults: boolean = true;

  constructor(
    public activatedRoute: ActivatedRoute,
    private marketplacesService: MarketplacesService,
    private listingsService: ListingsService,
    public modalService: ModalService,
    private toastService: ToastService,
    private translateService: TranslateService,
    public categoryService: CategoryService,
    public router: Router,
    ref: ElementRef,
    public cdr: ChangeDetectorRef,
    public localStorageService: LocalStorageService,
    public uiStateService: UiStateService
  ) {
    super(ref, router, marketplacesService, categoryService, modalService, cdr, activatedRoute, localStorageService);
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.outerSidebarOpen = this.uiStateService.state.marketplaceSidebarOpen;
  }

  ngOnInit(): void {
    this.marketplace$.pipe(takeUntil(this.destroyed$)).subscribe(async (marketplace) => {
      if (!marketplace) {
        return;
      }
      this.marketplace = marketplace;

      const countryName = this.translateService.instant(getCountryTranslateKey(marketplace.country.code));
      this.breadcrumbs = [
        { url: '../..', name: 'marketplaces.breadcrumbs.marketplaces' },
        { url: '.', name: `${marketplace.description} ${countryName} (${marketplace.domain})` },
        { url: '.', name: 'marketplaces.marketplace-products.page-title' },
      ];

      this.categories = await this.marketplacesService.getMarketplaceCategories(this.marketplace.id).toPromise();
      if (!this.categories?.length) {
        this.loading = false;
        return;
      }

      let category: MarketplaceCategory | undefined;
      const categoryParam = this.activatedRoute.snapshot.queryParams.category;
      if (categoryParam?.length) {
        category = this.categories.find((c) => c.categoryName === categoryParam);
      }
      // select either category passed in from query parameters or first category in the list as selection
      this.category = category || this.categories[0];

      this.fetchStatus();
      this.loadProducts();

      this.breadcrumbs.push({
        url: '.',
        name: this.translateService.instant(`marketplaces.listings.category.${this.category.categoryName}`)
      });

      this.submitTooltip = this.marketplacesService.getTooltipText(this.marketplace, this.tooltipType.Submit);
      this.selectTooltip = this.marketplacesService.getTooltipText(this.marketplace, this.tooltipType.Select);

    });
  }

  async loadProducts() {
    if (!this.marketplace) {
      return;
    }
    this.loading = true;
    const { pageSize, currentPage } = this.pagination;

    const { data, meta } = await this.listingsService.fetchListings({
      pageSize,
      currentPage,
      statuses: this.selectedStatusList,
      marketplaceId: this.marketplace.id,
      category: this.selectedCategory?.categoryName,
      query: this.query,
      sorting: this.uiStateService.state?.sorting?.listings
    }).toPromise();

    this.products = data;
    this.pagination = meta;

    this.queryResults = !(this.query && !this.products.length);

    this.loading = false;
  }

  openProductDetails(product: Product) {
    this.router.navigate([product.id], { relativeTo: this.activatedRoute });
  }

  toggleStatus(status: ListingStatus | ListingStatus[]) {
    if (Array.isArray(status)) {
      status.forEach(item => this.toggleSingleStatus(item));
    } else {
      this.toggleSingleStatus(status);
    }
    this.loadProducts();
  }

  private toggleSingleStatus(status: ListingStatus) {
    if (this.selectedStatusList.includes(status)) {
      this.selectedStatusList.splice(this.selectedStatusList.indexOf(status), 1);
    } else {
      this.selectedStatusList.push(status);
    }
  }

  clearStatus() {
    this.selectedStatusList = [];
    this.loadProducts();
  }

  async fetchStatus() {
    if (!this.marketplace?.id) {
      return;
    }
    this.status = await this.listingsService.fetchListingStatus(this.marketplace.id, this.selectedCategory?.categoryName).toPromise();
  }

  async submitListings() {
    if (!this.marketplace?.id) {
      return;
    }
    this.loading = true;

    try {
      const { updated_count, excluded } = await this.listingsService.submitListings(
        this.selectedProductIds,
        this.allProductsSelected,
        this.marketplace.id,
        this.category?.categoryName
      ).toPromise();

      if (excluded) {
        const modalRef = this.modalService.open(SubmissionModalComponent);
        modalRef.instance.counter = excluded;
      }

      if (!updated_count) {
        throw new Error('no updated listings');
      }
      this.toastService.success(this.translateService.instant(
        'marketplaces.listings.toast.submit-success',
        { count: updated_count }
      ));
    } catch (e) {
      this.toastService.error(this.translateService.instant('marketplaces.listings.toast.submit-failed'));
      this.loading = false;
    }

    // unselect everything
    this.selectedProductIds = [];
    this.allProductsSelected = false;

    this.loadProducts();
    this.fetchStatus();
  }

  get disabled(): boolean {
    return this.loading || (!this.selectedProductIds.length && !this.allProductsSelected);
  }

  handleSortingChange(change: { ascending: boolean, column: string }) {
    this.uiStateService.setListingsSorting(change.column, change.ascending);
    this.loadProducts();
  }
}
