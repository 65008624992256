<pup-modal (close)="dismiss()">

  <ng-container title>
    <pup-heading [level]="3">
      <span *ngIf="!submitted">
        {{ 'marketplaces.modal.submit-product-deletion.title' | translate: { count: products.length } }}
      </span>
      <div *ngIf="submitted" class="title-with-icon">
        {{ 'marketplaces.modal.submit-product-deletion.title-success' | translate }}
        <pup-icon path="solid/ic-check--fill"></pup-icon>
      </div>
    </pup-heading>

    <pup-button [variant]="ButtonVariant.Transparent" (click)="dismiss()">
      <pup-icon path="line/ic-close" size="2rem"></pup-icon>
    </pup-button>

  </ng-container>

  <ng-container *ngIf="!submitted; else submittedContent">

    <pup-paragraph>{{ 'marketplaces.modal.submit-product-deletion.message.main' | translate }}</pup-paragraph>
    <!--pup-paragraph class="paragraph-with-link">
      {{ 'marketplaces.modal.submit-product-deletion.message.error-page-link.text' | translate }}
      <pup-button [variant]="ButtonVariant.SimpleLink">
        {{ 'marketplaces.modal.submit-product-deletion.message.error-page-link.link' | translate }}
        <pup-icon path="line/ic-export"></pup-icon>
      </pup-button>
    </pup-paragraph-->
    <div class="grid">
      <div class="grid-row" *ngFor="let product of products">
        <pup-image
          [src]="product.image"
          [placeholderVariant]="ImagePlaceholderVariant.ExtraSmall"
        ></pup-image>
        <div class="grid-column">
          <pup-heading [level]="5">
            {{ product.sku }}
            <pup-icon class="clickable" path="line/ic-export" (click)="goToListing(product)"></pup-icon>
          </pup-heading>
          <span class="product-title">{{ product.title }}</span>
        </div>
      </div>
    </div>

    <pup-paragraph class="paragraph-with-link contact-link">
      {{ 'marketplaces.modal.submit-product-deletion.message.contact-link.text' | translate }}
      <a href="mailto:technicalsupport-team@productsup.com">
        <pup-button [variant]="ButtonVariant.SimpleLink">
          {{ 'marketplaces.modal.submit-product-deletion.message.contact-link.link' | translate }}
          <pup-icon path="line/ic-export"></pup-icon>
        </pup-button>
      </a>

    </pup-paragraph>
  </ng-container>
  <ng-container buttons *ngIf="!submitted">

    <pup-button (click)="submitDeleteRequest()" [disabled]="loading">
      <pup-loading-spinner *ngIf="loading"></pup-loading-spinner>
      {{ 'marketplaces.modal.submit-product-deletion.message.button-confirm' | translate }}
    </pup-button>

  </ng-container>
  <ng-container buttons *ngIf="submitted">

    <pup-button (click)="close()">
      {{ 'marketplaces.modal.submit-product-deletion.success.message.button-confirm' | translate }}
    </pup-button>

  </ng-container>
</pup-modal>

<ng-template #submittedContent>
  <pup-paragraph
    [innerHTML]="'marketplaces.modal.submit-product-deletion.success.message.main' | translate"
  >
  </pup-paragraph>

  <pup-paragraph class="paragraph-with-link contact-link-2">
    {{ 'marketplaces.modal.submit-product-deletion.success.message.contact-link.text' | translate }}
    <a href="mailto:technicalsupport-team@productsup.com">
      <pup-button [variant]="ButtonVariant.SimpleLink">
        {{ 'marketplaces.modal.submit-product-deletion.message.contact-link.link' | translate }}
        <pup-icon path="line/ic-export"></pup-icon>
      </pup-button>
    </a>

  </pup-paragraph>
</ng-template>
