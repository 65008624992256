<pup-sidebar-container *ngIf="marketplace">
  <pup-sidebar [(opened)]="opened" [widthOpen]="176" [widthClosed]="56">
    <app-marketplace-domain-tag [class.hidden]="!open" [marketplace]="marketplace"></app-marketplace-domain-tag>
    <app-marketplace-logo-flag
      [marketplace]="marketplace"
      [class.hidden]="open"
      [tooltip]="tooltipMessage"
      [tooltipPlacement]="TooltipPlacement.Right"
    ></app-marketplace-logo-flag>
    <pup-menu>
      <pup-menu-item
        [selected]="isSelected('dashboard')"
        [routerLink]="getRoute('dashboard')"
      >
        <pup-icon path="line/ic-dashboard"></pup-icon>
        <span [class.hidden]="!open">{{ "marketplaces.marketplace.sidenav.dashboard" | translate }}</span>
      </pup-menu-item>
      <pup-menu-item
        [selected]="isSelected('listings')"
        [routerLink]="getRoute('listings')"
      >
        <pup-icon path="line/ic-product-list"></pup-icon>
        <span [class.hidden]="!open">{{ "marketplaces.marketplace.sidenav.products" | translate }}</span>
      </pup-menu-item>
      <pup-menu-item
        [selected]="isSelected('errors')"
        [routerLink]="getRoute('errors')"
      >
        <pup-icon path="line/ic-error"></pup-icon>
        <span [class.hidden]="!open">{{ "marketplaces.marketplace.sidenav.errors" | translate }}</span>
      </pup-menu-item>
      <pup-menu-item
        [selected]="isSelected('orders')"
        [routerLink]="getRoute('orders')"
      >
        <pup-icon path="line/ic-shop"></pup-icon>
        <span [class.hidden]="!open">{{ "marketplaces.marketplace.sidenav.orders" | translate }}</span>
      </pup-menu-item>
      <pup-menu-item
        [selected]="isSelected('settings')"
        [routerLink]="getRoute('settings')"
      >
        <pup-icon path="line/ic-cog"></pup-icon>
        <span [class.hidden]="!open">{{ "marketplaces.marketplace.sidenav.settings" | translate }}</span>
      </pup-menu-item>
    </pup-menu>
  </pup-sidebar>
  <pup-sidebar-content>
    <ng-content></ng-content>
  </pup-sidebar-content>

</pup-sidebar-container>
