<app-marketplace-sidebar [marketplace]="marketplace">
  <pup-content class="errors--content">
    <div class="errors--header-wrapper">
      <pup-breadcrumbs home="/" [navigate]="navigate" *ngIf="marketplace">
        <pup-breadcrumb [url]="'../..'">
          <span>{{ 'marketplaces.breadcrumbs.marketplaces' | translate }}</span>
        </pup-breadcrumb>
        <pup-breadcrumb [url]="'..'">
          <span>{{ marketplace.description }} ({{ marketplace.country.code }})</span>
        </pup-breadcrumb>
        <pup-breadcrumb>{{ 'marketplaces.marketplace-errors.page-title' | translate }}</pup-breadcrumb>
      </pup-breadcrumbs>
      <pup-heading class="errors--title" [level]="3">{{ 'marketplaces.marketplace-errors.page-title' | translate }}</pup-heading>
    </div>

    <pup-content class="errors--table-wrapper">
      <pup-paragraph class="controls">
        <pup-input
          class="mr-6"
          [clearable]="true"
          [disabled]="loading"
          [(value)]="query"
          (enter)="loadErrors()"
          (clear)="loadErrors()"
          [placeholder]="'marketplaces.marketplace-errors.search-placeholder' | translate"
          [className]="'search-input'"
        >
          <pup-icon path="line/ic-search" (click)="loadErrors()"></pup-icon>
        </pup-input>
        <span>{{'marketplaces.marketplace-errors.sorty-by' | translate}}</span>

        <pup-switch class="error-type-switch">
          <pup-switch-item
            [closeOnSelection]="false" [selected]="errorType === ErrorType.Listings"
            (select)="toggleErrorType(ErrorType.Listings)"
          >
            {{ 'marketplaces.marketplace-errors.page-title.error-type-listings' | translate }}
          </pup-switch-item>
          <pup-switch-item
            [closeOnSelection]="false" [selected]="errorType === ErrorType.ErrorCodes"
            (select)="toggleErrorType(ErrorType.ErrorCodes)"
          >
            {{ 'marketplaces.marketplace-errors.page-title.error-type-codes' | translate }}
          </pup-switch-item>
        </pup-switch>

        <div class="vertical-divider"></div>

        <pup-switch class="error-type-switch filter">
          <pup-switch-item
            [closeOnSelection]="false"
            [selected]="errorTypeFilter.includes(ErrorGroupType.MarketplaceFeedback)"
            (select)="filterErrorType(ErrorGroupType.MarketplaceFeedback)"
          >
            {{ 'marketplaces.errors.table.error-type.marketplace_feedback' | translate }}
          </pup-switch-item>
          <pup-switch-item
            [closeOnSelection]="false"
            [selected]="errorTypeFilter.includes(ErrorGroupType.Other)"
            (select)="filterErrorType(ErrorGroupType.Other)"
          >
            {{ 'marketplaces.errors.table.error-type.other_errors' | translate }}
          </pup-switch-item>
        </pup-switch>

        <div class="vertical-divider"></div>

        <pup-button (click)="downloadCSV()">
          <pup-icon path="line/ic-download"></pup-icon>
          <span>
            {{ 'marketplaces.errors.table.csv-download' | translate }}
          </span>
        </pup-button>
      </pup-paragraph>

      <app-errors-table
        *ngIf="errorType === ErrorType.ErrorCodes"
        [items]="codeErrors"
        [displayColumns]="displayCodeColumns"
        (attributesReorder)="reorderAttributes($event, displayCodeColumns)"
        [loading]="loading"
        [(marked)]="markedErrors"
        [total]="pagination.totalCount"
        (paginationChange)="handlePaginationChange($event)"
        (selectError)="openProductErrorDetails($event)"
        [placeholderImgSrc]="'/assets/visuals/placeholders/empty_listing-errors.svg'"
        [noProductsDescriptionKey]="'marketplaces.listing-errors.table.empty-description'"
        [noProductsMessageKey]="'marketplaces.listing-errors.table.empty-message'"
        [queryResults]="queryResults"
        [sorting]="uiStateService.state?.sorting?.errorsCode"
        (sortingChange)="handleSortingChange($event)"
      >
      </app-errors-table>

      <app-listing-errors-table
        *ngIf="errorType === ErrorType.Listings"
        [items]="listingErrors"
        [displayColumns]="displayListingColumns"
        (attributesReorder)="reorderAttributes($event, displayListingColumns)"
        [loading]="loading"
        [(marked)]="markedErrors"
        [total]="pagination.totalCount"
        (paginationChange)="handlePaginationChange($event)"
        (selectError)="openProductErrorDetails($event)"
        [placeholderImgSrc]="'/assets/visuals/placeholders/empty_listing-errors.svg'"
        [noProductsDescriptionKey]="'marketplaces.listing-errors.table.empty-description'"
        [noProductsMessageKey]="'marketplaces.listing-errors.table.empty-message'"
        [queryResults]="queryResults"
        [sorting]="uiStateService.state?.sorting?.errorsProduct"
        (sortingChange)="handleSortingChange($event)"
        (selectSku)="openListingDetails($event)"
      ></app-listing-errors-table>
    </pup-content>

  </pup-content>
</app-marketplace-sidebar>
