import { Component, Input } from '@angular/core';
import { JuniperComponent } from 'lib-juniper';
import { ListingStatus } from '@app/interfaces';

@Component({
  selector: 'app-listing-status',
  templateUrl: './listing-status.component.html',
  styleUrls: ['./listing-status.component.scss']
})
export class ListingStatusComponent extends JuniperComponent {

  @Input()
  status?: ListingStatus;

  ListingStatus = ListingStatus;

}
