import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputBase } from '@app/components/dynamic-form/inputs/input-base';
import { ChoiceInputType } from '@app/components/dynamic-form/inputs/input-choice';
import { DynamicInputComponent } from '@app/components/dynamic-form/view/dynamic-input.component';

@Component({
  selector: 'app-dynamic-dropdown-input',
  styleUrls: ['./dynamic-dropdown-input.component.scss', '../../inputs-common.scss'],
  templateUrl: './dynamic-dropdown-input.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DynamicDropdownInputComponent
    }
  ]
})
export class DynamicDropdownInputComponent<T extends ChoiceInputType> extends DynamicInputComponent {

  value?: T;

  @Input()
  input?: InputBase;

  @Input()
  items: T[] = [];

  @Input()
  selectedKey?: string;

  @Input()
  invalid: boolean = false;

  @Input()
  hint: string = '';

  @Output()
  readonly valueChange = new EventEmitter<T>();

  constructor(
    ref: ElementRef,
    private cdr: ChangeDetectorRef
  ) {super(ref);}

  // TODO some kind of global change detection is needed instead of getters, since FormControl changes are ignored by OnChanges lifecycle hooks
  get valueLabel() {
    if (this.value?.label) {
      return this.value.label;
    }
    this.assignValueFromKey();
    return this.value?.label || 'marketplaces.form.toggle.undefined';
  }

  assignValueFromKey() {
    if (!this.selectedKey && this.value?.label) {
      return;
    }
    const key = this.selectedKey || this.value;

    const value = this.getEnumValueFromKey(key);
    if (!value) {
      return;
    }

    this.value = value;
    this.cdr.detectChanges();
  }

  getEnumValueFromKey(key: T | string | undefined): T | undefined {
    for (let item of this.items) {
      if (item.key === key) {
        return item;
      }
    }
    return undefined;
  }

  writeValue(value: any) {
    this.value = value;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  touch() {
    this.onTouched();
  }

  select(value?: T) {
    if (!value) {
      this.selectedKey = undefined;
    }
    this.value = value;
    this.valueChange.emit(this.value);
    this.onChange(this.value?.key);
  }

  forceFocus() {
    // TODO pending implementation
  }

  forceBlur() {
    // TODO pending implementation
  }
}
