import { InputBase } from '@app/components/dynamic-form/inputs/input-base';
import { InputControlType } from '@app/interfaces';

export interface ChoiceInputType {
  key?: string;
  label?: string;
}

export class ChoiceInput extends InputBase {

  controlType = InputControlType.Choice;

  choices: ChoiceInputType[] = [];
}

