<pup-modal>

  <ng-container title>

    <pup-heading [level]="2">{{ 'marketplaces.modal.submission.not-ready.title' | translate: {count: counter} }}</pup-heading>

    <pup-button [variant]="ButtonVariant.Transparent" (click)="dismiss()">
      <pup-icon path="line/ic-close" size="2rem"></pup-icon>
    </pup-button>

  </ng-container>

  <pup-paragraph>{{ 'marketplaces.modal.submission.not-ready.body-1' | translate }}</pup-paragraph>
  <pup-paragraph>{{ 'marketplaces.modal.submission.not-ready.body-2' | translate }}</pup-paragraph>

  <ng-container buttons>

    <pup-button (click)="close()">{{ 'marketplaces.modal.submission.not-ready.button-confirm' | translate }}</pup-button>

  </ng-container>

</pup-modal>
