<pup-modal (close)="dismiss()">

  <ng-container title>
    <pup-heading [level]="3">
      {{ 'marketplaces.modal.add-to-marketplace.title' | translate: { count: count } }}
    </pup-heading>
    <pup-icon path="line/ic-close" size="2rem" (click)="dismiss()"></pup-icon>
  </ng-container>

  <div class="regions-container">
    <pup-paragraph class="continent" *ngFor="let region of regions">
      <span class="region-title">
        {{ region + ' ' + ('marketplaces.add-marketplace.region' | translate) }}
      </span>
      <ng-container *ngIf="regionMarketplaces[region]?.length">
        <pup-toggle
          [toggled]="selectedMarketplaces.includes(marketplace.id)"
          (toggledChange)="toggleMarketplaceSelection(marketplace)"
          [disabled]="existingMarketplaceIds.includes(marketplace.id)"
          [tooltip]="existingMarketplaceIds.includes(marketplace.id) ? ('marketplaces.add-marketplace.notice.already-added' | translate) : ''"
          [tooltipPlacement]="TooltipPlacement.Right"
          *ngFor="let marketplace of regionMarketplaces[region]"
        >
          <app-marketplace-tag [marketplace]="marketplace"></app-marketplace-tag>
        </pup-toggle>
      </ng-container>
      <span *ngIf="!regionMarketplaces[region]?.length" class="blank-notice">
        {{'marketplaces.add-marketplace.no-marketplaces' | translate }}
      </span>
    </pup-paragraph>
  </div>
  <div class="buttons">
    <pup-button [variant]="ButtonVariant.Cancel" (click)="dismiss()">
      {{ 'marketplaces.modal.add-to-marketplace.cancel' | translate }}
    </pup-button>

    <pup-button [variant]="ButtonVariant.Primary" (click)="resolveModal()" [disabled]="!selectedMarketplaces.length">
      {{ 'marketplaces.modal.add-to-marketplace.add' | translate }}
    </pup-button>
  </div>
</pup-modal>
