import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { JuniperModule } from 'lib-juniper';

import { SharedModule } from '@app/shared/shared.module';
import { ProductDetailsPage } from '@app/pages/product-details/product-details.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
    JuniperModule,
    SharedModule
  ],
  declarations: [
    ProductDetailsPage,
  ]
})
export class ProductDetailsModule { }
