import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Marketplace } from '@app/interfaces/marketplace';
import { MarketplaceType } from '@app/interfaces/marketplaceType';
import { Page } from '@app/pages/page';
import { ExternalAuthService } from '@app/services/external-auth.service';
import { MarketplacesService } from '@app/services/marketplaces.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastService, ToggleVariant, TooltipPlacement } from 'lib-juniper';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

enum WizardPage {
  Info = 1,
  Result = 2,
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-edit-marketplace-page',
  templateUrl: './edit-marketplace.page.html',
  styleUrls: ['./edit-marketplace.page.scss']
})
export class EditMarketplacePage extends Page implements OnInit {

  loading: boolean = false;
  pageIndex: number = WizardPage.Info;

  marketplace?: Marketplace;

  ToggleVariant = ToggleVariant;
  TooltipPlacement = TooltipPlacement;
  MarketplaceType = MarketplaceType;
  WizardPage = WizardPage;

  authSuccess = false;
  authDone = false;

  constructor(
    ref: ElementRef,
    private externalAuthService: ExternalAuthService,
    private marketplacesService: MarketplacesService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private cdr: ChangeDetectorRef,
    public router: Router,
    public activatedRoute: ActivatedRoute
  ) {
    super(ref, router, activatedRoute);
    this.marketplacesService.fetchMarketplaces();
  }

  ngOnInit() {
    this.authSuccess = false;
    this.authDone = false;
    this.pageIndex = WizardPage.Info;

    combineLatest([
      this.activatedRoute.params,
      this.marketplacesService.marketplaces
    ]).pipe(map(([params, marketplaces]) => ({
      params,
      marketplaces
    }))).subscribe(({ params, marketplaces }) => {
      const marketplaceId = parseInt(params.marketplaceId, 10);
      this.marketplace = marketplaces.find(({ id }) => marketplaceId === id);
      this.cdr.detectChanges();
    });
  }

  get page() {
    return this.pageIndex;
  }

  set page(page: number) {
    this.pageIndex = page;

  }

  validate = (page: number): boolean => {
    if (page === WizardPage.Info && this.marketplace) {
      const { id, country, active } = this.marketplace;
      if (active === null) {
        return false;
      }
      this.redirectToExternalUrl(id, country.id, active);
      this.loading = true;
      this.cdr.detectChanges();
      return false;
    }
    return true;
  };

  async finish() {
    if (this.authDone && !this.authSuccess) {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
        this.router.navigate(['/edit-marketplace', this.marketplace?.id]));
      return;
    }
    this.close();
  }

  close() {
    this.router.navigate(['/marketplaces', this.marketplace?.id, 'settings']);
  }

  private redirectToExternalUrl = (marketplaceId: number, countryId: number, type: string | MarketplaceType) => {
    this.externalAuthService.authenticateAmazon({ marketplaceId, countryId, type })
      .then(() => {
        this.authSuccess = true;
      })
      .catch(() => {
        this.authSuccess = false;
      })
      .finally(() => {
        this.authDone = true;
        this.pageIndex = WizardPage.Result;
        this.loading = false;
        this.cdr.detectChanges();
      });
  };
}
