import { Injectable } from '@angular/core';
import { Pagination, Product } from '@app/interfaces';
import { TableSortingState } from '@app/services/ui-state.service';
import { extendParamsWithSorting } from '@app/shared/utils';

import { ApiClient, RequestParameters } from 'lib-platform-common';
import { Observable } from 'rxjs';

export interface PaginatedResponse<T> {
  data: T[];
  meta: Pagination;
}

export type ProductOptions = {
  pageSize?: number;
  currentPage?: number;
  categories?: string[];
  query?: string;
  sorting?: TableSortingState,
  language?: string;
}

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  constructor(private api: ApiClient) {
  }

  getProducts(options: ProductOptions): Observable<PaginatedResponse<Product>> {
    const { currentPage, query, pageSize, sorting, language } = options;
    const params: RequestParameters = { currentPage, pageSize };

    if (query && query.length) {
      params.query = query;
    }

    if (language && language.length) {
      params.language = language;
    }

    extendParamsWithSorting(params, sorting);

    return this.api.get<PaginatedResponse<Product>>({ endpoint: 'products', parameters: params });
  }

  getProductsByCategory(options: ProductOptions): Observable<PaginatedResponse<Product>> {
    const { categories, currentPage, query, pageSize, sorting, language } = options;
    const data: { categories?: string[], query?: string, language?: string } = { categories };
    const params: RequestParameters = { currentPage, pageSize };

    if (query && query.length) {
      data.query = query;
    }

    if (language && language.length) {
      data.language = language;
    }

    extendParamsWithSorting(params, sorting);

    return this.api.post<PaginatedResponse<Product>>({
      data,
      parameters: params,
      endpoint: 'products'
    });
  }

  getSingleProduct(productId: string): Observable<Product> {
    return this.api.get<Product>({ endpoint: `products/${productId}` });
  }

  getSingleProductJson(productId: string): Observable<any> { // TODO needs a type
    return this.api.get<Product>({ endpoint: `products/${productId}`, parameters: { format: 'json' } });
  }

  saveProduct(productId: string, product: Product) {
    return this.api.post({ endpoint: `products/${productId}/attributes`, data: product });
  }

  fetchAvailableProductLanguages(): Observable<string[]> {
    return this.api.get<string[]>({ endpoint: 'product/languages' });
  }
}
