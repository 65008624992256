<app-marketplace-sidebar [(opened)]="outerSidebarOpen" [marketplace]="marketplace">
  <pup-sidebar-container>
    <pup-sidebar
      *ngIf="categories"
      [(opened)]="opened"
      [toggleOffset]="outerSidebarOpen ? 176 : 56"
      [widthOpen]="288"
      [widthClosed]="25"
    >
      <app-category-filter
        [categories]="categories"
        [(selectedCategory)]="selectedCategory"
        [class.hidden]="!opened"
      ></app-category-filter>
    </pup-sidebar>
    <pup-sidebar-content>
      <div class="header">
        <app-top-error-notification [marketplaceActivated]="marketplace?.activated"></app-top-error-notification>

        <pup-breadcrumbs home="/" [navigate]="navigate">
          <pup-breadcrumb *ngFor="let breadcrumb of breadcrumbs" [url]="breadcrumb.url">
            <span [tooltip]="breadcrumb.name | translate">{{ breadcrumb.name | translate }}</span>
          </pup-breadcrumb>
        </pup-breadcrumbs>
        <div class="sub-header" *ngIf="category">
          <pup-heading [level]="3">{{ ('marketplaces.listings.category.' + category.categoryName)  | translate }}</pup-heading>
          <div class="listing-info">
            <span [innerHTML]="'marketplaces.listings.listing-count' | translate: { count: category.listingCount }"></span>
            <div class="vertical-divider"></div>
            <app-readiness-indicator [ready]="category.ready"></app-readiness-indicator>
          </div>
        </div>
      </div>
      <pup-content>
        <pup-paragraph class="filter-box">
          <pup-input
            class="mr-6 listings--search"
            [clearable]="true"
            [disabled]="loading"
            [placeholder]="'marketplaces.listings.search-placeholder' | translate"
            [(value)]="query"
            (enter)="resetPaginationAndLoad()"
            (clear)="resetPaginationAndLoad()"
          >
            <pup-icon path="line/ic-search"></pup-icon>
          </pup-input>

          <pup-button [variant]="ButtonVariant.DarkLink" (click)="showAttributeSelectionModal(category?.categoryName)">
            <pup-icon path="line/ic-setup" class="mr-2"></pup-icon>
            {{ 'marketplaces.products.attributes' | translate }}
          </pup-button>
        </pup-paragraph>

        <div class="extra-controls">
          <app-product-status-filter
            *ngIf="status"
            [status]="status"
            (selectStatus)="toggleStatus($event)"
            (clearStatus)="clearStatus()"
            [selectedStatusList]="selectedStatusList"
          >
          </app-product-status-filter>
        </div>
        <app-product-table
          [disabledSelect]="!!marketplace?.invalidAuth || !marketplace?.activated"
          [displayedAttributes]="categoryService.displayedListingAttributes"
          [lockedAttributes]="categoryService.lockedAttributes"
          [(allSelected)]="allProductsSelected"
          [items]="products"
          [loading]="loading"
          [marketplaceActivity]="marketplace?.activated"
          [(marked)]="selectedProductIds"
          [total]="pagination.totalCount"
          (paginationChange)="handlePaginationChange($event)"
          (select)="openProductDetails($event)"
          (attributesReorder)="reorderAttributes($event, true)"
          [sorting]="uiStateService.state?.sorting?.listings"
          (sortingChange)="handleSortingChange($event)"
          [queryResults]="queryResults"
          [placeholderImgSrc]="'/assets/visuals/placeholders/empty_product-list.svg'"
          [noProductsMessageKey]="'marketplaces.listings.table.empty-message'"
          [noProductsDescriptionKey]="'marketplaces.listings.table.empty-description'"
          [selectTooltip]="selectTooltip | translate"
          [excludedFromTotalCount]="pagination.deleted || 0"
        >
          <div class="actions">
            <div class="vertical-divider"></div>
            <pup-button
              [variant]="ButtonVariant.Secondary"
              [size]="ButtonSize.Small"
              [disabled]="disabled"
              [tooltip]="submitTooltip | translate"
              (click)="submitListings()"
            >
              {{ "marketplaces.marketplace.products.list" | translate }}
            </pup-button>
          </div>
        </app-product-table>

      </pup-content>
    </pup-sidebar-content>

  </pup-sidebar-container>
</app-marketplace-sidebar>
