<app-label-with-tooltip
  [label]="input?.label"
  [description]="input?.description"
  [required]="input?.required"
></app-label-with-tooltip>
<div class="input-with-tooltip">
  <pup-dropdown-select (click)="touch()" [invalid]="invalid" [validationHint]="hint" [disabled]="disabled">
    <pup-paragraph label>{{ valueLabel | translate }}</pup-paragraph>
    <pup-dropdown-menu-item (select)="select(true)">
      <span>{{ 'marketplaces.form.toggle.yes' | translate }}</span>
    </pup-dropdown-menu-item>
    <pup-dropdown-menu-item (select)="select(false)">
      <span>{{ 'marketplaces.form.toggle.no' | translate }}</span>
    </pup-dropdown-menu-item>
    <pup-dropdown-menu-item (select)="select()">
      <span>{{ 'marketplaces.form.toggle.undefined' | translate }}</span>
    </pup-dropdown-menu-item>
  </pup-dropdown-select>
  <app-imported-value-tooltip
    *ngIf="input && input.importedValue !== null"
    [importedValue]="getStringValueLabel(input.importedValue) | translate"
    (resetValueClick)="resetInputValueClick()"
  ></app-imported-value-tooltip>
</div>
