import { Component, Input } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { ProductKey } from '@app/interfaces';

const COLUMN_TRANSLATE_PREFIX = 'marketplaces.products.columns';

@Component({
  selector: 'app-column-title',
  templateUrl: './column-title.component.html',
  styleUrls: ['./column-title.component.scss'],
})
export class ColumnTitleComponent {

  @Input()
  column!: ProductKey;

  constructor(private translateService: TranslateService) {}

  get translateKey(): string {
    return `${COLUMN_TRANSLATE_PREFIX}.${this.column}`;
  }

  hasTranslation(): boolean {
    const translations = this.translateService.translations[this.translateService.currentLang];
    return translations && translations[this.translateKey] !== undefined;
  }

}
