export enum ListingStatus {
  All = 'total',
  Submitted = 'submitted',
  NotSubmitted = 'not_submitted',
  Queued = 'queued',
  Failed = 'failed',
  PendingFeedback = 'pending_feedback',
  SubmittedWithErrors = 'submitted_with_errors',
  PendingDeletion = 'pending_deletion',
  PendingDeletionFailed = 'deletion_failed'
}
