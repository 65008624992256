<pup-content>
  <app-notification-bar
    *ngIf="isListingDeleted()"
    iconPath="solid/ic-authentication"
  >
    <span>
      {{ 'marketplaces.marketplace.listing-details.delete-pending.error.message' | translate }}
      <ng-container *ngIf="failedToDeleteProducts.length">
        <a class="action-link" href="#" (click)="showSubmitDeleteModal($event)">
          {{ 'marketplaces.marketplace.listing-details.delete-pending.error.link' | translate }}
        </a>
      </ng-container>
    </span>
  </app-notification-bar>
  <ng-container *ngIf="listing; else spinner">
    <app-top-error-notification [marketplaceActivated]="marketplace?.activated"></app-top-error-notification>

    <pup-breadcrumbs home="/" [navigate]="navigate">
      <pup-breadcrumb *ngFor="let breadcrumb of breadcrumbs" [url]="breadcrumb.url" [queryParams]="breadcrumb.queryParams">
        <span [tooltip]="breadcrumb.name | translate">{{ breadcrumb.name | translate }}</span>
      </pup-breadcrumb>
    </pup-breadcrumbs>

    <div class="header-container">
      <pup-heading [level]="2">
        <div>
          <span class="sku">{{ listing.sku }}</span> - {{ listing.title }}
        </div>
        <div class="header-controls">
          <app-listing-status [status]="listing.status"></app-listing-status>
          <div class="vertical-divider"></div>
          <app-readiness-indicator [ready]="ready"></app-readiness-indicator>
          <div class="vertical-divider"></div>
          <div class="controls-container" >
            <pup-button [disabled]="!hasUnsavedChanges" [variant]="ButtonVariant.Secondary" (click)="saveListing()">
              {{ 'marketplaces.product-listing-details-page.save' | translate }}
            </pup-button>

            <ng-container *ngIf="listingValidationService.currentErrorCount$ | async as errorCount; else buttonEnabled">
              <ng-container *ngTemplateOutlet="buttonDisabled"></ng-container>
            </ng-container>

            <ng-template #buttonEnabled>
              <ng-container *ngIf="marketplace?.activated; else buttonDisabled">
                <pup-button
                  (click)="listItem()"
                  [disabled]="hasUnsavedChanges || !!marketplace?.invalidAuth"
                  [tooltip]="submitTooltip"
                  tooltipClass="capped-width-225"
                >
                  {{ 'marketplaces.marketplace.products.list' | translate }}
                </pup-button>
              </ng-container>
            </ng-template>

            <ng-template #buttonDisabled>
              <pup-button
                [disabled]="true"
                [tooltip]="submitTooltip | translate"
                tooltipClass="capped-width-225"
              >
                {{ 'marketplaces.marketplace.products.list' | translate }}
              </pup-button>
            </ng-template>
          </div>
        </div>
      </pup-heading>

      <div class="listing-errors" *ngIf="hasSubmissionErrors">
        <div class="message">
          <pup-icon path="solid/ic-warning--fill"></pup-icon>
          <span>
          {{'marketplaces.marketplace.listing-details.submission-errors' | translate}}
        </span>
        </div>
        <div class="link" (click)="navigateErrors(listing.sku)">
        <span>
          {{'marketplaces.marketplace.listing-details.submission-errors.link' | translate}}
        </span>
          <pup-icon path="line/ic-export"></pup-icon>
        </div>
      </div>
    </div>

    <div class="main-container">
      <div class="side-container">
        <div class="side-menu">
          <pup-menu class="menu" *ngIf="initDone">
            <ng-container *ngIf="listingValidationService.errorCountByGroup$ | async as groupErrorCounts">
            <pup-menu-item
              (select)="scrollToTop()"
              [selected]="selectedMenuIndex === 0"
            >
              {{ 'marketplaces.marketplace.listing-details.menu-item.main' | translate }}
              <pup-badge
                *ngIf="groupErrorCounts.main"
                [tooltip]="'marketplaces.listings.details.errors.missing-required-attributes' | translate: { count: groupErrorCounts.main }"
                [tooltipPlacement]="TooltipPlacement.Right"
              >{{ groupErrorCounts.main }}</pup-badge>
            </pup-menu-item>
            <pup-menu-item
              *ngFor="let menu of menuItems; let index = index"
              (select)="scrollToIndex(index)"
              [selected]="selectedMenuIndex === index + 1"
            >
              {{ menu.label }}
              <pup-badge
                *ngIf="groupErrorCounts[menu.key]"
                [tooltip]="'marketplaces.listings.details.errors.missing-required-attributes' | translate: { count: groupErrorCounts[menu.key] }"
                [tooltipPlacement]="TooltipPlacement.Right"
              >
                {{ groupErrorCounts[menu.key] }}
              </pup-badge>
            </pup-menu-item>
            </ng-container>
          </pup-menu>
          <ng-container *ngIf="requiredCount">
            <pup-floating-label>{{ 'marketplaces.marketplace.listing-details.filter' | translate }}</pup-floating-label>
            <pup-select [variant]="SelectVariant.Multiple">
              <pup-select-item [selected]="onlyRequired" (select)="toggleRequired()"
              >
                {{ "marketplaces.marketplace-product-details.required" | translate: { count: requiredCount } }}
              </pup-select-item>
              <pup-select-item [selected]="onlyOptional" (select)="toggleOptional()"
              >
                {{ "marketplaces.marketplace-product-details.optional" | translate: { count: inputsArray.length - requiredCount } }}
              </pup-select-item>
            </pup-select>
          </ng-container>
        </div>
      </div>
      <div class="form-container" #formContainer (scroll)="onFormScroll($event)" *ngIf="form">
        <app-dynamic-form
          [form]="form"
          [inputs]="inputsArray"
          [onlyOptional]="onlyOptional"
          [onlyRequired]="onlyRequired"
          (formChange)="onFormChange()"
          [disabled]="isListingDeleted()"
        ></app-dynamic-form>
      </div>
    </div>
  </ng-container>

  <ng-template #spinner>
    <pup-loading-visual></pup-loading-visual>
  </ng-template>
</pup-content>
