import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { JuniperComponent, PlaceholderVariant } from 'lib-juniper';
import { MarketplaceCategory } from '@app/interfaces/marketplaceCategory';
import { TranslateService } from '@ngx-translate/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-category-filter',
  templateUrl: './category-filter.component.html',
  styleUrls: ['./category-filter.component.scss']
})
export class CategoryFilterComponent extends JuniperComponent {

  filter: string = '';

  filteredCategories: MarketplaceCategory[] = [];

  categories: MarketplaceCategory[] = [];

  ready: boolean = false;

  PlaceholderVariant = PlaceholderVariant;

  @Input('categories')
  set setCategories(categories: MarketplaceCategory[]) {
    this.categories = categories;
    this.filteredCategories = [...categories];
  }

  @Input()
  selectedCategory?: MarketplaceCategory;

  @Output()
  selectedCategoryChange: EventEmitter<MarketplaceCategory> = new EventEmitter<MarketplaceCategory>();

  constructor(
    public router: Router,
    public translateService: TranslateService,
    ref: ElementRef,
  ) {
    super(ref);
  }

  onFilterUpdate(filter: string) {
    this.filter = filter;
    if (filter && filter.length) {
      this.filteredCategories = this.categories.filter(
        ({ categoryName }) => this.translateService.instant(`marketplaces.listings.category.${categoryName}`).includes(filter)
      );
    } else {
      this.filteredCategories = [...this.categories];
    }
  }

  isOverflown(name: string): boolean {
    return name.length > 20; // TODO needs a proper solution for detecting overflowing text
  }

}
