import { Component, ElementRef } from '@angular/core';
import { AvatarSize, ButtonVariant, DropdownAlignment, JuniperComponent } from 'lib-juniper';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent extends JuniperComponent {

  AvatarSize = AvatarSize;
  ButtonVariant = ButtonVariant;
  DropdownAlign = DropdownAlignment;

  constructor(public router: Router, ref: ElementRef) {super(ref);}

}
