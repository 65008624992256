import { Directive, EventEmitter, Injectable, Input, Output } from '@angular/core';
import { DEFAULT_CURRENT_PAGE, DEFAULT_PAGE_SIZE, Pagination } from '@app/interfaces';
import { Identifiable } from '@app/interfaces/identifiable';
import { TableSortingState } from '@app/services/ui-state.service';

import { JuniperComponent, PlaceholderVariant } from 'lib-juniper';

enum SelectedLabels {
  AllOnPage = 'marketplaces.products.selected.all-on-page',
  AllInCatalog = 'marketplaces.products.selected.all-on-catalog',
  Default = 'marketplaces.products.selected',
}

@Injectable()
@Directive()
export abstract class TableBaseComponent<Item extends Identifiable> extends JuniperComponent {

  items: Item[] = [];

  @Input('items')
  set itemsSetter(items: Item[]) {
    this.items = items;
    this.syncMarked();
  };

  @Input()
  loading: boolean = false;

  @Input()
  total: number = 0;

  // this is the array of checked (or unchecked in case of 'all' toggled) products
  @Input()
  marked: string[] = [];

  @Input()
  allSelected: boolean = false;

  @Input()
  noProductsMessageKey = 'marketplaces.products.empty-message';

  @Input()
  noProductsDescriptionKey = 'marketplaces.products.empty-description';

  @Input()
  placeholderVariant = PlaceholderVariant.Categories;

  @Input()
  placeholderImgSrc = '';

  @Input()
  noResultsFoundDescription = 'marketplaces.products.no-results.description';

  @Input()
  searchResults: boolean = false;

  @Input()
  currentPage: number = DEFAULT_CURRENT_PAGE;

  @Input()
  queryResults: boolean = true;

  @Input()
  sorting?: TableSortingState = {};

  @Output()
  sortingChange = new EventEmitter<{ column: string, ascending: boolean }>();

  @Output()
  paginationChange = new EventEmitter<Partial<Pagination>>();

  @Output()
  markedChange = new EventEmitter<string[]>();

  @Output()
  allSelectedChange = new EventEmitter<boolean>();

  @Output()
  select = new EventEmitter<Item>();

  markedOnPage: string[] = [];

  pageSize: number = DEFAULT_PAGE_SIZE;

  handlePaginationChange() {
    this.paginationChange.emit({ pageSize: this.pageSize, currentPage: this.currentPage });
  }

  get page() {
    return this.currentPage;
  }

  set page(page: number) {
    if (this.currentPage === page) {
      return;
    }
    this.currentPage = page;
    this.handlePaginationChange();
  }

  get perPage() {
    return this.pageSize;
  }

  set perPage(count: number) {
    this.page = DEFAULT_CURRENT_PAGE;
    this.pageSize = count;

    this.handlePaginationChange();
  }

  toggleItem(item: Item, disabled: boolean) {
    if (disabled) {
      return;
    }
    this.toggleItemInArray(item.id, this.marked);
    this.toggleItemInArray(item.id, this.markedOnPage);

    this.markedChange.emit(this.marked);
  }

  toggleAllOnPage() {
    if (this.markedOnPage.length) {
      this.clearAllOnPage();
    } else {
      this.markAllOnPage();
    }

    this.markedChange.emit(this.marked);
  }

  handleSelect(item: Item) {
    this.select.emit(item);
  }

  selectAll() {
    this.allSelected = true;
    this.allSelectedChange.emit(true);
    this.marked = [];
    this.markedChange.emit([]);
  }

  private syncMarked() {
    this.markedOnPage = [];
    this.items.forEach((item: Item) => {
      const { id } = item;
      const isMarked = this.allSelected ? !this.marked.includes(id) : this.marked.includes(id);
      if (isMarked) {
        this.markedOnPage.push(id);
      }
    });
  }

  private markAllOnPage() {
    this.markedOnPage.push(...this.getSelectableItems().map(({ id }) => id));

    if (this.allSelected) {
      this.marked = this.marked.filter((item) => !this.markedOnPage.includes(item));
    } else {
      this.getSelectableItems().forEach((item) => {
        if (!this.marked.includes(item.id)) {
          this.marked.push(item.id);
        }
      });
    }
  }

  clearAll() {
    this.allSelected = false;
    this.marked = [];
    this.markedOnPage = [];
    this.markedChange.emit([]);
    this.allSelectedChange.emit(false);
  }

  private clearAllOnPage() {
    if (this.allSelected) {
      this.items.forEach((item) => {
        if (!this.marked.includes(item.id)) {
          this.marked.push(item.id);
        }
      });
    } else {
      this.marked = this.marked.filter((item) => !this.markedOnPage.includes(item));
    }

    this.markedOnPage = [];
  }

  private toggleItemInArray(item: string, array: string[]) {
    const index = array.indexOf(item);

    if (index === -1) {
      array.push(item);
    } else {
      array.splice(index, 1);
    }
  }

  get selectedLabel(): string {
    if (this.searchResults) {
      return SelectedLabels.Default;
    }
    if ((this.allSelected && this.marked.length === 0) || (!this.allSelected && this.marked.length === this.total)) {
      return SelectedLabels.AllInCatalog;
    }
    if (!this.allSelected && this.markedOnPage.length === this.items.length && this.items.length === this.marked.length) {
      return SelectedLabels.AllOnPage;
    }
    return SelectedLabels.Default;
  }

  get isSelectAllVisible(): boolean {
    return !this.allSelected &&
      (this.markedOnPage.length === this.items.length || this.marked.length > this.markedOnPage.length) &&
      this.marked.length < this.total;
  }

  setColumnSorting(ascending: boolean, column: string) {
    this.sortingChange.emit({ column, ascending });
  }

  getSelectableItems(): Item[] {
    return this.items;
  }
}
