import { Component, Input } from '@angular/core';
import { ButtonVariant, ModalDialogComponent } from 'lib-juniper';

@Component({
  selector: 'app-submission-modal',
  styleUrls: ['submission-modal.component.scss'],
  templateUrl: 'submission-modal.component.html'
})
export class SubmissionModalComponent extends ModalDialogComponent {

  readonly ButtonVariant = ButtonVariant;

  @Input()
  counter: number = 0;

}
