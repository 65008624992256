import { Component, Input } from '@angular/core';
import { JuniperComponent } from 'lib-juniper';

@Component({
  selector: 'app-region-tag',
  styleUrls: ['region-tag.component.scss'],
  templateUrl: 'region-tag.component.html'
})
export class RegionTagComponent extends JuniperComponent {

  @Input()
  region?: string;

  @Input()
  store?: string = 'Amazon'; // TODO remove default

  @Input()
  storeLogoUrl: string = '/assets/visuals/merchants/amazon.svg';  // TODO remove default

}
