import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonVariant, PlaceholderVariant } from 'lib-juniper';

import { ErrorTableBase } from '@app/components/tables/error-table-base.component';
import { GroupedError } from '@app/interfaces/errors';

@Component({
  selector: 'app-errors-table',
  templateUrl: './errors-table.component.html',
  styleUrls: ['../table.scss', './errors-table.component.scss'],
})
export class ErrorsTableComponent extends ErrorTableBase<GroupedError> {

  PlaceholderVariant = PlaceholderVariant;

  ButtonVariant = ButtonVariant;

  expandedRows: number[] = [];

  @Output()
  selectErrorId = new EventEmitter<number>();

  toggleRowExpand(event: Event, rowIndex: number) {
    event.stopPropagation();
    const index = this.expandedRows.indexOf(rowIndex);
    if (index === -1) {
      this.expandedRows.push(rowIndex);
    } else {
      this.expandedRows.splice(index, 1);
    }
  }
}
