<ng-template #tooltip>
  <label class="popup-title">{{ 'marketplaces.marketplace.listing-details.imported-value.title' | translate }}</label>
  <pup-paragraph>{{ importedValue }}</pup-paragraph>
  <pup-button [variant]="ButtonVariant.Link" (click)="resetInputValue()">
    <pup-icon path="line/ic-history"></pup-icon>
    <span>{{ 'marketplaces.marketplace.listing-details.imported-value.revert' | translate }}</span>
  </pup-button>
</ng-template>

<pup-button
  [variant]="ButtonVariant.Link"
  [tooltip]="{ content: tooltip, placement: TooltipPlacement.Bottom, openOnClick: true }"
  tooltipClass="popup"
  *ngIf="importedValue !== null"
>
  <pup-icon path="line/ic-show"></pup-icon>
  <span>{{ 'marketplaces.marketplace.listing-details.imported-value.view' | translate }}</span>
</pup-button>
