import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ListingStatus } from '@app/interfaces';

import { ButtonVariant, JuniperComponent, SelectSize, SelectVariant } from 'lib-juniper';

@Component({
  selector: 'app-product-status-filter',
  templateUrl: './status-filter.component.html',
  styleUrls: ['./status-filter.component.scss']
})
export class ProductStatusFilter extends JuniperComponent {

  ProductStatus = ListingStatus;
  SelectVariant = SelectVariant;
  ButtonVariant = ButtonVariant;
  SelectSize = SelectSize;

  @Input()
  selectedStatusList: ListingStatus[] = [];

  @Input()
  status!: { [key in ListingStatus]: number };

  @Output()
  selectStatus = new EventEmitter<ListingStatus | ListingStatus[]>();

  @Output()
  clearStatus = new EventEmitter<void>();

  handleStatusSelect(status: ListingStatus) {
    this.selectStatus.emit(status);
  }

  handlePendingDeletionStatusSelect() {
    this.selectStatus.emit([ListingStatus.PendingDeletion, ListingStatus.PendingDeletionFailed]);
  }

  isSelected(status: ListingStatus): boolean {
    return this.selectedStatusList.includes(status);
  }

  clearSelected() {
    this.clearStatus.emit();
  }
}
