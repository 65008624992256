import { Identifiable } from '@app/interfaces/identifiable';
import { Product } from '@app/interfaces/product';

export enum ErrorGroupType {
  Other = 'other_errors',
  MarketplaceFeedback = 'marketplace_feedback',
}

export interface Error extends Identifiable {
  errorId: number;
  code: number;
  group: ErrorGroupType;
  message: string;
  affectedListings?: Partial<Product>[];
  submissionId: string;
  submissionTimestamp: string;
  attributeName?: string;
}

export interface ErrorByListing extends Identifiable {
  sku: number;
  listingId: number;
  image: string;
  name: string;
  errors: Error[];
}

export interface ListingErrorsDisplay {
  fieldName: string;
  columnKey: string;
  isSortable: boolean;
}

export interface GroupedError extends Pick<Error, 'code' | 'group' | 'submissionId' | 'submissionTimestamp' | 'id' | 'attributeName'> {
  errors: Pick<Error, 'affectedListings' | 'group' | 'errorId' | 'message'>[];
}
