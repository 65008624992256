import { Component, Input } from '@angular/core';
import { JuniperComponent } from 'lib-juniper';

import { MARKETPLACE_TYPE_INFO, MarketplaceType, MarketplaceTypeInfo } from '@app/interfaces/marketplaceType';

@Component({
  selector: 'app-marketplace-type',
  styleUrls: ['marketplace-type.component.scss'],
  templateUrl: 'marketplace-type.component.html'
})
export class MarketplaceTypeComponent extends JuniperComponent {

  @Input()
  marketplaceType!: MarketplaceType;

  @Input()
  showName = false;

  @Input()
  showLabel = true;

  marketplaceTypes: MarketplaceTypeInfo = MARKETPLACE_TYPE_INFO;

}
