import { Component, OnChanges, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IntroModalComponent } from '@app/components/modals/intro-modal/intro-modal.component';
import { AuthService } from '@app/services/auth.service';
import { CategoryService } from '@app/services/category.service';
import { HeapService } from '@app/services/heap.service';

import { LocalStorageService } from '@app/services/local-storage.service';
import { UiStateService } from '@app/services/ui-state.service';
import { getCountryTranslateKey } from '@app/shared/utils';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from 'lib-juniper';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import de from '../translations/de.json';
import en from '../translations/en.json';
import fr from '../translations/fr.json';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnChanges {

  protected loginInitPending$: ReplaySubject<boolean> = new ReplaySubject(1);
  loginInitDone: boolean = false;

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private modalService: ModalService,
    public authService: AuthService,
    private categoryService: CategoryService,
    private localStorageService: LocalStorageService,
    private heapService: HeapService,
    private uiStateService: UiStateService
  ) { }

  ngOnInit() {
    this.translateService.setTranslation('en', en, true);
    this.translateService.setTranslation('de', de, true);
    this.translateService.setTranslation('fr', fr, true);

    this.translateService.use('en');

    this.route.queryParams.subscribe((params: { hl?: string }) => {
      if (params.hl) {
        this.translateService.use(params.hl);
      }
    });

    this.router.events.pipe(takeUntil(this.loginInitPending$)).subscribe(() => {
      this.afterLoginInit();
    });

    this.afterLoginInit();
  }

  ngOnChanges() {
    this.afterLoginInit();
  }

  private afterLoginInit() {
    if (!this.authService.isLoggedIn() || this.loginInitDone) {
      return;
    }
    this.loginInitPending$.complete();
    this.loginInitDone = true;
    const showIntro = !localStorage.getItem('pupMarketplaceIntroViewed');
    if (showIntro) {
      localStorage.setItem('pupMarketplaceIntroViewed', 'true');
      this.modalService.open(IntroModalComponent);
    }

    this.categoryService.fetchCategories();
    this.localStorageService.restoreCategoryServiceState();
    if (environment.production) {
      this.heapService.initialize();
    }
  }

  get errorMessage(): string | undefined {
    let message;
    if (!this.uiStateService.invalidMarketplaces?.length) {
      return;
    }
    if (this.uiStateService.invalidMarketplaces?.length === 1) {
      const marketplace = this.uiStateService.invalidMarketplaces[0];
      const type = marketplace.active === null ?
        '' : this.translateService.instant(`marketplaces.amazon.${marketplace.active}.central`);
      const country = this.translateService.instant(getCountryTranslateKey(marketplace.country.code));

      message = this.translateService.instant('marketplaces.global-errors.invalid-marketplace.single',
        {
          country,
          marketplaceType: type
        }
      );
    } else {
      message = this.translateService.instant('marketplaces.global-errors.invalid-marketplace.multiple');
    }
    return message;
  }
}
