import { Component } from '@angular/core';

import { range } from '@app/shared/range';
import { JuniperComponent } from 'lib-juniper';

@Component({
  selector: 'app-skeleton-table',
  templateUrl: './skeleton-table.component.html',
  styleUrls: ['./skeleton-table.component.scss'],
})
export class SkeletonTableComponent extends JuniperComponent {

  range = range;

}
