<app-marketplace-sidebar [marketplace]="marketplace">
  <ng-container *ngIf="marketplace; else spinner">
    <pup-content>
      <app-top-error-notification [marketplaceActivated]="marketplace?.activated"></app-top-error-notification>

      <pup-breadcrumbs home="/" [navigate]="navigate">
        <pup-breadcrumb [url]="'../..'">
          <span>{{ 'marketplaces.breadcrumbs.marketplaces' | translate }}</span>
        </pup-breadcrumb>

        <pup-breadcrumb [url]="'..'">
          <span>{{ marketplace.description + ' ' +  countryName}} ({{ marketplace.domain }})</span>
        </pup-breadcrumb>

        <pup-breadcrumb>{{ 'marketplaces.marketplace-settings.page-title' | translate }}</pup-breadcrumb>
      </pup-breadcrumbs>

      <pup-heading [level]="3">{{ 'marketplaces.marketplace-settings.page-title' | translate }}</pup-heading>

      <app-marketplace-settings
        [marketplace]="marketplace"
        (marketplaceChange)="updateMarketplace($event)"
        (marketplaceEditClick)="handleEditMarketplaceClick()"
        [showEdit]="true"
      ></app-marketplace-settings>

    </pup-content>
  </ng-container>
</app-marketplace-sidebar>

<ng-template #spinner>
  <pup-content>
    <pup-loading-visual></pup-loading-visual>
  </pup-content>
</ng-template>
