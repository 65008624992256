import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonVariant, TooltipPlacement } from 'lib-juniper';

import { InputBase } from '@app/components/dynamic-form/inputs/input-base';
import { InputControlType } from '@app/interfaces';

@Component({
  selector: 'app-imported-value-tooltip',
  styleUrls: ['./imported-value-tooltip.component.scss'],
  templateUrl: './imported-value-tooltip.component.html'
})
export class ImportedValueTooltipComponent {

  ButtonVariant = ButtonVariant;
  TooltipPlacement = TooltipPlacement;
  InputControlType = InputControlType;

  @Input()
  importedValue: string | null = null;

  @Output()
  resetValueClick: EventEmitter<void> = new EventEmitter();

  resetInputValue() {
    this.resetValueClick.emit();
  }
}
