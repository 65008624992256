import { ChangeDetectorRef, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Marketplace } from '../../interfaces/marketplace';
import { MarketplacesService } from '../../services/marketplaces.service';
import { PageWithProducts } from '../page-with-products';
import { CategoryService } from '@app/services/category.service';
import { ModalService } from 'lib-juniper';
import { LocalStorageService } from '@app/services/local-storage.service';

export abstract class MarketplaceWithProductsPage extends PageWithProducts {

  marketplace$: Observable<Marketplace | undefined>;

  constructor(
    ref: ElementRef,
    public router: Router,
    private marketplaceService: MarketplacesService,
    public categoryService: CategoryService,
    public modalService: ModalService,
    public cdr: ChangeDetectorRef,
    public activatedRoute: ActivatedRoute,
    public localStorageService: LocalStorageService,
  ) {
    super(ref, router, categoryService, modalService, cdr, activatedRoute, localStorageService);
    this.marketplace$ = this.marketplaceService.selectedMarketplace;
  }
}
