<ng-container *ngTemplateOutlet="marketplaceTag; context: {type: 'seller', marketplaces: groupedMarketplaces.seller}"></ng-container>
<ng-container *ngTemplateOutlet="marketplaceTag; context: {type: 'vendor', marketplaces: groupedMarketplaces.vendor}"></ng-container>

<ng-template #marketplaceTag let-type="type" let-marketplaces="marketplaces">
  <div *ngIf="marketplaces.length" class="marketplace-container" [tooltip]="tooltip" [tooltipPlacement]="TooltipPlacement.Right">
    <img class="logo" src="/assets/visuals/merchants/amazon.svg">
    <img class="logo" *ngIf="type === 'seller'" src="/assets/visuals/merchants/amazon_seller_icon_small.svg">
    <img class="logo" *ngIf="type === 'vendor'" src="/assets/visuals/merchants/amazon_vendor_icon_small.svg">
    <div class="count-container" [ngClass]="type">
      {{ marketplaces.length }}
    </div>
  </div>

  <ng-template #tooltip>
    <div class="tooltip--wrapper">
      <div *ngFor="let marketplace of marketplaces" class="tooltip--single-wrapper">
        <img
          class="flag"
          default-src
          [src]="getFlagImageSrc(marketplace)"
        >
        <span class="tooltip--country-name">{{getCountryTranslateKey(marketplace.country.code) | translate}}</span>
      </div>
    </div>
  </ng-template>
</ng-template>
