import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AmazonAuthFailedComponent } from '@app/components/authentication/amazon/amazon-auth-failed.component';
import { AmazonAuthSuccessComponent } from '@app/components/authentication/amazon/amazon-auth-success.component';
import { ButtonOrInputComponent } from '@app/components/button-or-input/button-or-input.component';
import { DynamicFormInputComponent } from '@app/components/dynamic-form/dynamic-form-input.component';
import { DynamicFormComponent } from '@app/components/dynamic-form/dynamic-form.component';
import {
  ImportedValueTooltipComponent
} from '@app/components/dynamic-form/imported-value-tooltip/imported-value-tooltip.component';
import {
  DynamicDateInputComponent
} from '@app/components/dynamic-form/view/dynamic-date-input/dynamic-date-input.component';
import {
  DynamicDropdownInputComponent
} from '@app/components/dynamic-form/view/dynamic-dropdown-input/dynamic-dropdown-input.component';
import {
  DynamicToggleInputComponent
} from '@app/components/dynamic-form/view/dynamic-toggle-input/dynamic-toggle-input.component';
import { ImageGroupComponent } from '@app/components/dynamic-form/view/image-group/image-group.component';
import {
  LabelWithTooltipComponent
} from '@app/components/dynamic-form/view/label-with-tooltip/label-with-tooltip.component';
import { TextGroupInputComponent } from '@app/components/dynamic-form/view/text-group-input/text-group-input.component';
import { EmptyPlaceholderComponent } from '@app/components/empty-placeholder/empty-placeholder.component';
import { ErrorNotificationComponent } from '@app/components/error/error-notification/error-notification.component';
import { FilterSwitcherComponent } from '@app/components/filter-switcher/filter-switcher.component';
import { HeaderComponent } from '@app/components/header/header.component';
import { InfoBar } from '@app/components/info-bar/info-bar.component';
import {
  FilteredSelectedListComponent
} from '@app/components/list-select/selected-attributes/filtered-selected-list.component';
import { ListingStatusComponent } from '@app/components/listing-status/listing-status.component';
import { LogoutComponent } from '@app/components/logout/logout.component';
import { MarketplacesFiltersComponent } from '@app/components/marketplaces-filters/marketplaces-filters.component';
import { CountryTagComponent } from '@app/components/marketplaces/country-tag/country-tag.component';
import {
  MarketplaceActivityComponent
} from '@app/components/marketplaces/marketplace-activity/marketplace-activity.component';
import {
  MarketplaceDomainTagComponent
} from '@app/components/marketplaces/marketplace-domain-tag/marketplace-domain-tag.component';
import { MarketplaceFlagsComponent } from '@app/components/marketplaces/marketplace-flags/marketplace-flags.component';
import {
  MarketplaceLogoFlagComponent
} from '@app/components/marketplaces/marketplace-logo-flag/marketplace-logo-flag.component';
import { MarketplaceTagComponent } from '@app/components/marketplaces/marketplace-tag/marketplace-tag.component';
import { MarketplaceTypeComponent } from '@app/components/marketplaces/marketplace-type/marketplace-type.component';
import {
  ReadinessIndicatorComponent
} from '@app/components/marketplaces/readiness-indicator/readiness-indicator.component';
import { RegionTagComponent } from '@app/components/marketplaces/region-tag/region-tag.component';
import { MarketplaceSettingsComponent } from '@app/components/marketplaces/settings/marketplace-settings.component';
import {
  SubmitListingDeleteModalComponent
} from '@app/components/modals/submit-listing-delete/submit-listing-delete-modal.component';
import { NotificationBarComponent } from '@app/components/notification-bar/notification-bar.component';
import { ColumnTitleComponent } from '@app/components/products/column-title/column-title.component';
import { ProductTableComponent } from '@app/components/tables/product-table/product-table.component';
import { SkeletonTableComponent } from '@app/components/tables/skeleton/skeleton-table.component';
import { TableControlsComponent } from '@app/components/tables/table-controls/table-controls.component';
import { TopErrorNotificationComponent } from '@app/components/top-error-notification/top-error-notification.component';
import { TranslateModule } from '@ngx-translate/core';
import { JuniperModule } from 'lib-juniper';

const EXPORTS = [
  AmazonAuthFailedComponent,
  AmazonAuthSuccessComponent,
  ButtonOrInputComponent,
  ColumnTitleComponent,
  CountryTagComponent,
  DynamicDateInputComponent,
  DynamicDropdownInputComponent,
  DynamicFormComponent,
  DynamicFormInputComponent,
  DynamicToggleInputComponent,
  TextGroupInputComponent,
  ErrorNotificationComponent,
  EmptyPlaceholderComponent,
  InfoBar,
  HeaderComponent,
  FilteredSelectedListComponent,
  FilterSwitcherComponent,
  ImageGroupComponent,
  InfoBar,
  LabelWithTooltipComponent,
  ListingStatusComponent,
  ImportedValueTooltipComponent,
  LogoutComponent,
  MarketplaceDomainTagComponent,
  MarketplaceTagComponent,
  MarketplaceLogoFlagComponent,
  MarketplaceSettingsComponent,
  MarketplaceFlagsComponent,
  MarketplaceTypeComponent,
  MarketplaceActivityComponent,
  ProductTableComponent,
  ReadinessIndicatorComponent,
  RegionTagComponent,
  SkeletonTableComponent,
  FilterSwitcherComponent,
  SubmitListingDeleteModalComponent,
  TableControlsComponent,
  EmptyPlaceholderComponent,
  MarketplacesFiltersComponent,
  LabelWithTooltipComponent,
  TopErrorNotificationComponent,
  NotificationBarComponent
];

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    JuniperModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    DragDropModule
  ],
  exports: EXPORTS,
  declarations: EXPORTS
})
export class SharedModule {
}
