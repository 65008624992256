<div [formGroup]="form">
  <div [formArrayName]="input.key">
    <pup-input
      *ngFor="let control of getFormArray(form.get(input.key)).controls; let index = index"
      [required]="input.required"
      [id]="input.key"
      [formControlName]="index"
      placeholder=""
      [invalid]="!!control.getError('invalid')"
      [validationHint]="control.getError('invalid')"
      [disabled]="disabled"
    >
    </pup-input>
  </div>
</div>
