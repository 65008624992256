import { Component, ElementRef, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Product } from '@app/interfaces';
import { ListingsService } from '@app/services/listings.service';
import { ButtonVariant, ModalDialogComponent, ImagePlaceholderVariant, ActiveModal } from 'lib-juniper';

@Component({
  selector: 'app-submit-listing-delete-modal',
  styleUrls: ['submit-listing-delete-modal.component.scss'],
  templateUrl: 'submit-listing-delete-modal.component.html'
})
export class SubmitListingDeleteModalComponent extends ModalDialogComponent {

  submitted = false;
  loading = false;

  @Input()
  products: Product[] = [];

  ButtonVariant = ButtonVariant;
  ImagePlaceholderVariant = ImagePlaceholderVariant;

  constructor(ref: ElementRef, activeModal: ActiveModal, private router: Router, private listingsService: ListingsService) {
    super(ref, activeModal);

  }

  goToListing(product: Product) {
    const { marketplaceId, id } = product;
    const url = this.router.serializeUrl(this.router.createUrlTree(
      ['marketplaces', marketplaceId, 'listings', id]
    ));
    window.open(url, '_blank');
  }

  async submitDeleteRequest() {
    this.loading = true;
    await this.listingsService.submitRetryDeleteListings().toPromise();
    this.loading = false;
    this.submitted = true;
  }
}
