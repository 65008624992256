import {
  ChangeDetectionStrategy,
  Component, Input,
} from '@angular/core';
import { InputBase } from '@app/components/dynamic-form/inputs/input-base';
import { JuniperComponent, ImagePlaceholderVariant } from 'lib-juniper';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-image-group',
  styleUrls: ['image-group.component.scss', '../../inputs-common.scss'],
  templateUrl: './image-group.component.html',
})
export class ImageGroupComponent extends JuniperComponent {

  ImagePlaceholderVariant = ImagePlaceholderVariant;

  @Input()
  images: InputBase[] = [];

}
