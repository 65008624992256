<app-table-controls
  [allToggled]="markedOnPage.length > 0"
  [disabled]="!items.length || loading || !marketplaceActivity"
  [disabledSelect]="disabledSelect"
  [selectTooltip]="disabledSelect ? selectTooltip : ''"
  [showSelectBox]="allSelected || marked.length > 0"
  [showSelectAll]="isSelectAllVisible"
  [searchResults]="searchResults"
  [selectedCount]="allSelected ? (total - marked.length - excludedFromTotalCount) : marked.length"
  [selectLabel]="selectedLabel"
  [showClearSelections]="marked.length > markedOnPage.length || allSelected"
  [total]="total - excludedFromTotalCount"
  [(page)]="page"
  [(perPage)]="perPage"
  (clearAll)="clearAll()"
  (selectAll)="selectAll()"
  (toggleAll)="toggleAllOnPage()"
>
  <ng-content select=".actions"></ng-content>
</app-table-controls>
<pup-table *ngIf="!loading">
  <pup-table-head cdkDropList cdkDropListOrientation="horizontal" (cdkDropListDropped)="drop($event)">

    <pup-table-cell class="locked"></pup-table-cell>
    <pup-table-cell
      *ngFor="let attribute of lockedAttributes"
      [class.locked]="displayedAttributes.length > NO_LOCKED_CELLS_THRESHOLD"
      [sortable]="attribute.sortable"
      [ascending]="sorting?.[attribute.value]?.ascending"
      [descending]="sorting?.[attribute.value]?.descending"
      (sortClick)="setColumnSorting($event, attribute.value)"
    >
      <app-column-title [column]="attribute.name"></app-column-title>
    </pup-table-cell>
    <ng-container *ngFor="let attribute of displayedAttributes">
      <pup-table-cell
        *ngIf="attributeIsNotLocked(attribute)"
        cdkDrag
        cdkDragLockAxis="x"
        [sortable]="attribute.sortable"
        [ascending]="sorting?.[attribute.value]?.ascending"
        [descending]="sorting?.[attribute.value]?.descending"
        (sortClick)="setColumnSorting($event, attribute.value)"
      >
        <div *cdkDragPlaceholder class="table-cell-drag-placeholder"></div>
        <pup-icon class="drag-handle" path="line/ic-drag-line"></pup-icon>
        <app-column-title [column]="attribute.name"></app-column-title>
      </pup-table-cell>
    </ng-container>
  </pup-table-head>

  <ng-container *ngIf="items.length > 0 && queryResults; else empty">
    <pup-table-row
      *ngFor="let product of items"
      (select)="handleSelect(product)"
      [tooltip]="getRowTooltip(product)"
      [tooltipPlacement]="TooltipPlacement.Float"
      [tooltipAlignment]="TooltipAlignment.Left"
      tooltipClass="capped-width"
    >
      <pup-table-cell
        (click)="toggleItem(product, isSelectDisabled(product))"
        class="locked select"
      >
        <pup-toggle
          (toggledChange)="toggleItem(product, isSelectDisabled(product))"
          [toggled]="!isSelectDisabled(product) && (allSelected ? !marked.includes(product.id) : marked.includes(product.id))"
          [disabled]="isSelectDisabled(product)"
          [tooltip]="selectTooltip"
        ></pup-toggle>
      </pup-table-cell>

      <pup-table-cell
        [class.locked]="displayedAttributes.length > NO_LOCKED_CELLS_THRESHOLD"
        *ngFor="let attribute of lockedAttributes"
        [ngSwitch]="attribute.value"
      >
        <pup-image
          [src]="product.image"
          *ngSwitchCase="'image'"
          [placeholderVariant]="ImagePlaceholderVariant.ExtraSmall"
        ></pup-image>
        <div *ngSwitchCase="'title'">
          <span>{{ product[attribute.value] }}</span>
          <ng-container *ngIf="!product.status">
            <div *ngIf="isProductDeleted(product)" class="pending-deletion-tag">
              <pup-icon path="line/ic-clock" color="#FF842C" size="16px"></pup-icon>
              <span>{{ 'marketplaces.products.pending-deletion' | translate }}</span>
            </div>
            <div *ngIf="isProductDeleteFailed(product)" class="pending-deletion-failed-tag">
              <pup-icon path="line/ic-warning--circle" color="#F04359" size="16px"></pup-icon>
              <span>{{ 'marketplaces.products.pending-deletion-failed' | translate }}</span>
            </div>
          </ng-container>
        </div>
        <ng-container *ngSwitchDefault>{{ product[attribute.value] }}</ng-container>
      </pup-table-cell>

      <ng-container *ngFor="let attribute of displayedAttributes">
        <pup-table-cell
          *ngIf="!lockedAttributes.includes(attribute)"
          [ngSwitch]="attribute.value"
          [class.marketplaces]="attribute.value === 'marketplaces'"
        >
          <ng-container *ngSwitchCase="'category'">
            {{ ('marketplaces.listings.category.' + product[attribute.value]) | translate }}
          </ng-container>
          <ng-container *ngSwitchCase="'lastUpdate'">{{ product.lastUpdate | dateTime }}</ng-container>
          <ng-container *ngSwitchCase="'marketplaces'">
            <app-marketplace-flags [marketplaces]="product.marketplaces"></app-marketplace-flags>
          </ng-container>
          <ng-container *ngSwitchCase="'status'">
            <app-listing-status [status]="product.status"></app-listing-status>
          </ng-container>
          <ng-container *ngSwitchCase="'valid'">
            <app-readiness-indicator [ready]="product.valid"></app-readiness-indicator>
          </ng-container>
          <ng-container *ngSwitchDefault>{{ product[attribute.value] }}</ng-container>
        </pup-table-cell>
      </ng-container>
    </pup-table-row>
  </ng-container>

  <ng-container *ngIf="!queryResults">
    <app-empty-placeholder
      class="empty-placeholder"
      [emptyPlaceholderVariant]="PlaceholderVariant.NotFoundV2"
      [emptyPlaceholderMessage]="noResultsFoundMessage"
      [emptyPlaceholderDescription]="noResultsFoundDescription"
    >
    </app-empty-placeholder>
  </ng-container>
</pup-table>

<ng-template #empty>
  <app-empty-placeholder
    *ngIf="queryResults"
    class="empty-placeholder"
    [emptyPlaceholderVariant]="placeholderVariant"
    [emptyPlaceholderImgSrc]="placeholderImgSrc"
    [emptyPlaceholderMessage]="noProductsMessageKey"
    [emptyPlaceholderDescription]="noProductsDescriptionKey"
  >
  </app-empty-placeholder>
</ng-template>


<app-skeleton-table *ngIf="loading"></app-skeleton-table>

<pup-pagination
  [disabled]="!items.length || loading"
  [totalCount]="total"
  [(page)]="page"
  [(perPage)]="perPage"
  [direction]="DropdownDirection.Up"
  *ngIf="total > MIN_PAGE_SIZE && !loading"
></pup-pagination>
