import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit } from '@angular/core';
import { Marketplace } from '@app/interfaces/marketplace';
import {
  ToastAlignment,
  ToastPosition,
  ToastService,
  ToggleVariant,
  TooltipPlacement
} from 'lib-juniper';
import { ExternalAuthService } from '@app/services/external-auth.service';
import { TranslateService } from '@ngx-translate/core';
import { MarketplacesService } from '@app/services/marketplaces.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Page } from '@app/pages/page';
import { takeUntil } from 'rxjs/operators';
import { getCountryTranslateKey } from "@app/shared/utils";
import { MARKETPLACE_TYPE_INFO, MarketplaceType, MarketplaceTypeInfo } from '@app/interfaces/marketplaceType';

enum WizardPage {
  SelectType = 1,
  SelectCountry = 2,
  Setup = 3,
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-add-marketplace-page',
  templateUrl: './add-marketplace.page.html',
  styleUrls: ['./add-marketplace.page.scss'],
})
export class AddMarketplacePage extends Page implements OnInit {

  regions: string[] = [];
  filteredRegions: string[] = [];
  regionMarketplaces: Record<string, Marketplace[]> = {};
  selectedMarketplaceType: string = '';
  authDone: boolean = false;
  errorMessage: string = '';
  loading: boolean = false;
  pageIndex: number = WizardPage.SelectType;
  selectedMarketplace?: Marketplace;
  validationHint: string = '';
  WizardPage = WizardPage;

  ToggleVariant = ToggleVariant;
  TooltipPlacement = TooltipPlacement;
  //TODO Information about available marketplace types and providers should come from backend
  marketplaceTypes: MarketplaceTypeInfo = MARKETPLACE_TYPE_INFO;

  constructor(
    ref: ElementRef,
    private externalAuthService: ExternalAuthService,
    private marketplacesService: MarketplacesService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private cdr: ChangeDetectorRef,
    public router: Router,
    public activatedRoute: ActivatedRoute,
  ) {
    super(ref, router, activatedRoute);
  }

  ngOnInit() {
    this.marketplacesService.fetchMarketplaces();
    this.marketplacesService.meta.pipe(takeUntil(this.destroyed$)).subscribe(({ regions, marketplacesByRegion }) => {
      this.regions = regions;
      this.regionMarketplaces = marketplacesByRegion;
      this.cdr.detectChanges();
      this.loading = false;
    });
  }

  checkSelection(type: string) {
    this.filteredRegions = [];
    this.selectedMarketplaceType = type;
    this.regions.forEach((region) => {
      this.regionMarketplaces[region].forEach((marketplace) => {
        if (marketplace.type.includes(type)) {
          if (!this.filteredRegions.includes(region)) {
            this.filteredRegions.push(marketplace.country.region);
          }
        }
      });
    });
  }

  get page() {
    return this.pageIndex;
  }

  set page(page: number) {
    this.pageIndex = page;

    if (page === WizardPage.SelectType) {
      this.selectedMarketplace = undefined;
    }

    this.validationHint = '';
  }

  validate = (page: number): boolean => {
    if (page === WizardPage.SelectType && !this.selectedMarketplaceType) {
      this.validationHint = 'marketplace.add-marketplace.errors.no-marketplace-type-selected';
      return false;
    }

    if (page === WizardPage.SelectCountry && !this.selectedMarketplace) {
      this.validationHint = 'marketplace.add-marketplace.errors.no-marketplace-selected';
      return false;
    }

    if (page === WizardPage.SelectCountry && !this.authDone && this.selectedMarketplace) {
      const { id, country } = this.selectedMarketplace;
      this.redirectToExternalUrl(id, country.id, this.selectedMarketplaceType);
      return false;
    }
    this.validationHint = '';
    return true;
  };

  private redirectToExternalUrl = (marketplaceId: number, countryId: number, type: string) => {
    this.externalAuthService.authenticateAmazon({ marketplaceId, countryId, type })
      .then(() => {
        this.authDone = true;
        this.validationHint = '';
        this.pageIndex = WizardPage.Setup;

        this.cdr.detectChanges();
      })
      .catch(() => {
        this.toastService.error(
          this.translateService.instant('marketplace.add-marketplace.errors.authenticate.amazon.error'),
          {
            alignment: ToastAlignment.Right,
            position: ToastPosition.BottomRight,
            autohide: false,
          });
      });
  };

  async finish() {
    if (!this.selectedMarketplace) {
      return;
    }
    const countryCode = this.translateService.instant(getCountryTranslateKey(this.selectedMarketplace?.country?.code));
    const params = { marketplace: countryCode + ' (' + this.selectedMarketplace?.domain + ')' };
    await this.marketplacesService.updateMarketplace(this.selectedMarketplace).toPromise();
    this.toastService.success(this.translateService.instant('marketplaces.marketplaces.marketplace-updated.success', params));
    this.close();
  }

  close() {
    this.router.navigate(['/marketplaces']);
  }
}
