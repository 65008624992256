import { ActivatedRoute } from '@angular/router';
import { Component } from '@angular/core';
import { EXTERNAL_AUTH_RESPONSE_TYPE, ExternalAuthResponse } from '@app/interfaces/externalAuthResponse';

@Component({ template: '' })
export class AmazonAuthSuccessComponent {

  constructor(
    private route: ActivatedRoute
  ) {
    this.route.params.subscribe((response) => {

      const message: ExternalAuthResponse = {
        success: true,
        type: EXTERNAL_AUTH_RESPONSE_TYPE,
        body: response,
      };

      if (window?.opener) {
        window.opener.postMessage(message, window.location.origin);
      }
      window.close();
    });
  }

}
