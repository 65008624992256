import { Component } from '@angular/core';
import { MarketplacePage } from '@app/pages/marketplace/marketplace.page';

@Component({
  selector: 'app-marketplace-order-page',
  templateUrl: './marketplace-orders.page.html',
  styleUrls: ['../../page.scss', './marketplace-orders.page.scss'],
})
export class MarketplaceOrdersPage extends MarketplacePage {

}
