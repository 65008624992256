import { ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Breadcrumb, ButtonVariant, JuniperComponent } from 'lib-juniper';
import { RequestParameters } from 'lib-platform-common';

export abstract class Page extends JuniperComponent {

  loading: boolean = true;
  breadcrumbs: Breadcrumb[] = [];

  ButtonVariant = ButtonVariant;

  constructor(
    ref: ElementRef,
    public router: Router,
    public route: ActivatedRoute
  ) {
    super(ref);
  }

  navigate = (url: string, queryParams?: RequestParameters) => {
    this.router.navigate([url], { queryParams, relativeTo: this.route });
  }
}

