import { Injectable } from '@angular/core';
import { ApiClient } from 'lib-platform-common';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

export interface ValidationError {
  message: string;
  missingProperty?: boolean;
  property?: string;
  path: string[];
  fullPath?: string;
}

type ApiValidationError = {
  attribute: string;
  message: string;
}

@Injectable({
  providedIn: 'root'
})
export class ListingValidationService {

  currentErrorCount$: Subject<number> = new BehaviorSubject<number>(0);

  errorCountByGroup$: Subject<{ [key: string]: number }> = new BehaviorSubject<{ [key: string]: number }>({});

  constructor(private api: ApiClient) {}

  setErrorCount(errorCount: number) {
    this.currentErrorCount$.next(errorCount);
  }

  setErrorCountByMenu(errorCounts: { [key: string]: number }) {
    this.errorCountByGroup$.next(errorCounts);
  }

  validateApi(id: string, data: any): Observable<ValidationError[]> {
    return this.api.post<ApiValidationError[]>({
      data,
      endpoint: `listings/${id}/validate`
    }).pipe(
      map((errors: ApiValidationError[]): ValidationError[] => {
        return errors.map((error) => {
          const splitAttribute = error.attribute.split('.');
          return {
            message: error.message,
            // TODO a better approach to determine missing property would be nice
            missingProperty: error.message.includes('is required'),
            property: splitAttribute[splitAttribute.length - 1],
            path: splitAttribute.map((item) => item.replace(/\[.*\]/, '')),
            fullPath: error.attribute
          };
        });
      })
    );
  }
}
