import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ButtonVariant, JuniperComponent, TooltipPlacement } from 'lib-juniper';
import { Marketplace } from '@app/interfaces';
import { TranslateService } from '@ngx-translate/core';
import { getCountryTranslateKey } from '@app/shared/utils';
import { UiStateService } from '@app/services/ui-state.service';

@Component({
  selector: ' app-marketplace-sidebar',
  templateUrl: './marketplace-sidebar.component.html',
  styleUrls: ['./marketplace-sidebar.component.scss']
})
export class MarketplaceSidebarComponent extends JuniperComponent {

  open: boolean;
  ButtonVariant = ButtonVariant;
  TooltipPlacement = TooltipPlacement;

  @Input()
  marketplace?: Marketplace;

  @Input()
  parentRoute: string = '..';

  @Input()
  set opened(open: boolean) {
    if (this.open === open) {
      return;
    }
    this.open = open;
    this.uiStateService.saveMarketplaceSidebarOpen(open);
    this.openedChange.emit(this.open);
  };

  get opened(): boolean {
    return this.open;
  }

  @Output()
  openedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    public router: Router,
    ref: ElementRef,
    public translateService: TranslateService,
    public uiStateService: UiStateService
  ) {
    super(ref);
    this.open = this.uiStateService.state.marketplaceSidebarOpen;
  }

  get flagImageSrc() {
    return `/assets/visuals/flags/${this.marketplace?.country?.code}.svg`
  }

  get tooltipMessage(): string {
    if (!this.marketplace?.country?.code) {
      return 'Amazon';
    }
    const countryName = this.translateService.instant(getCountryTranslateKey(this.marketplace?.country?.code));
    return `Amazon ${countryName}`;
  }

  isSelected(path: string): boolean {
    return !!this.router.url.match(`marketplaces\/[0-9]+\/${path}`);
  }

  getRoute(route: string): string[] {
    return [this.parentRoute, route];
  }

}
