import { Directive, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Page } from '../page';
import { Observable } from 'rxjs';
import { Marketplace } from '@app/interfaces/marketplace';
import { MarketplacesService } from '@app/services/marketplaces.service';
import { takeUntil } from 'rxjs/operators';

@Directive()
export abstract class MarketplacePage extends Page {

  marketplace$: Observable<Marketplace | undefined>;

  constructor(
    ref: ElementRef,
    public router: Router,
    public marketplacesService: MarketplacesService,
    public activatedRoute: ActivatedRoute,
  ) {
    super(ref, router, activatedRoute);
    this.marketplace$ = this.marketplacesService.selectedMarketplace.pipe(takeUntil(this.destroyed$));
  }
}

