import { Component } from '@angular/core';
import { ModalDialogComponent, ImagePlaceholderVariant } from 'lib-juniper';

@Component({
  selector: 'app-intro-modal',
  templateUrl: './intro-modal.component.html',
  styleUrls: ['./intro-modal.component.scss']
})
export class IntroModalComponent extends ModalDialogComponent  {

  pages: number = 5;
  currentPage: number = 1;
  ImagePlaceholderVariant = ImagePlaceholderVariant;

  get title(): string {
    return `marketplaces.modal.greetings-info.page.${this.currentPage}.title`
  }

  get content(): string {
    return `marketplaces.modal.greetings-info.page.${this.currentPage}.content`
  }

  nextPage() {
    this.currentPage += 1;
  }
}
