import { Component, Input } from '@angular/core';
import { PlaceholderVariant } from 'lib-juniper';

@Component({
  selector: 'app-empty-placeholder',
  templateUrl: './empty-placeholder.component.html',
  styleUrls: ['./empty-placeholder.component.scss']
})
export class EmptyPlaceholderComponent {

  @Input()
  emptyPlaceholderVariant = PlaceholderVariant.NotFoundV2;

  @Input()
  emptyPlaceholderImgSrc = '';

  @Input()
  emptyPlaceholderMessage = 'marketplaces.products.no-results.message';

  @Input()
  emptyPlaceholderDescription = 'marketplaces.products.no-results.description';
}
