import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-top-error-notification',
  templateUrl: './top-error-notification.component.html',
  styleUrls: ['./top-error-notification.component.scss']
})
export class TopErrorNotificationComponent {

  @Input()
  marketplaceActivated: boolean | undefined = true;

  @Input()
  notificationText: string = 'marketplaces.marketplace-settings.inactive-marketplace';

}
