import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { JuniperComponent, SwitchSize } from 'lib-juniper';
import { MarketplaceFilter, MarketplaceStatus } from '@app/interfaces/marketplaceType';

@Component({
  selector: 'app-marketplaces-filters',
  templateUrl: './marketplaces-filters.component.html',
  styleUrls: ['./marketplaces-filters.component.scss']
})
export class MarketplacesFiltersComponent extends JuniperComponent {

  SwitchSize = SwitchSize;

  @Input() selected: MarketplaceStatus = null;
  @Input() attributes: MarketplaceFilter[] = [];

  @Output() select = new EventEmitter<MarketplaceStatus>();

  constructor(public ref: ElementRef) {
    super(ref);
  }
}
