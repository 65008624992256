<div class="actions-container">
  <div class="left">
    <pup-toggle
      [disabled]="disabled || disabledSelect"
      [toggled]="allToggled"
      [partial]="true"
      [tooltip]="selectTooltip"
      (toggledChange)="toggleAll.emit()"
      *ngIf="!hideSelect"
    ></pup-toggle>
    <ng-content></ng-content>
  </div>
  <pup-pagination
    [disabled]="disabled"
    [totalCount]="total"
    [page]="page"
    [perPage]="perPage"
    (pageChange)="pageChange.emit($event)"
    (perPageChange)="perPageChange.emit($event)"
    *ngIf="total > MIN_PAGE_SIZE"
  ></pup-pagination>
</div>
<div [class.wide]="showSelectBox" *ngIf="!disabled">
  <div *ngIf="showSelectBox" class="selection-info-box">
    <span [innerHTML]="selectLabel | translate: { count: selectedCount }"></span>
    <pup-button class="clear-button" [variant]="ButtonVariant.Icon" (click)="clearAll.emit()" *ngIf="showClearSelections">
      <pup-icon path="line/ic-close-thick" size="20px"></pup-icon>
    </pup-button>
    <pup-button
      class="select-all-button"
      [variant]="ButtonVariant.SimpleLink"
      [size]="ButtonSize.Small"
      (click)="selectAll.emit()"
      *ngIf="showSelectAll && !searchResults"
    >
      {{ 'marketplaces.products.selected-all-products' | translate:{ count: total } }}
    </pup-button>
  </div>
</div>

