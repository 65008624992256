import { HttpErrorResponse } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { AbstractControl, FormArray, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DynamicFormComponent } from '@app/components/dynamic-form/dynamic-form.component';
import { InputBase } from '@app/components/dynamic-form/inputs/input-base';
import { GroupInput } from '@app/components/dynamic-form/inputs/input-group';
import {
  SubmitListingDeleteModalComponent
} from '@app/components/modals/submit-listing-delete/submit-listing-delete-modal.component';
import { CanComponentDeactivate } from '@app/guards/can-deactivate.guard';

import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_PAGE_SIZE,
  DEFAULT_TOTAL_COUNT,
  InputControlType,
  ListingStatus,
  Pagination,
  Product
} from '@app/interfaces';
import { Marketplace } from '@app/interfaces/marketplace';
import { MarketplacePage } from '@app/pages/marketplace/marketplace.page';
import { CategoryService } from '@app/services/category.service';
import { FormsService } from '@app/services/forms.service';
import { ListingValidationService, ValidationError } from '@app/services/listing-validation.service';
import { ListingsService } from '@app/services/listings.service';
import { ComponentType, MarketplacesService } from '@app/services/marketplaces.service';
import { ProductsService } from '@app/services/products.service';
import { getCountryTranslateKey } from '@app/shared/utils';
import { TranslateService } from '@ngx-translate/core';
import { ModalService, SelectVariant, ToastService, TooltipPlacement } from 'lib-juniper';
import { get } from 'lodash';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

// TODO refactor this huge component - move out all logic
@Component({
  selector: 'app-listing-details',
  templateUrl: './listing-details.page.html',
  styleUrls: ['../../page.scss', '../../product-details-form.scss', './listing-details.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListingDetailsPage extends MarketplacePage implements OnInit, AfterViewInit, OnDestroy, CanComponentDeactivate {

  marketplace?: Marketplace;
  regions: string[] = [];
  regionMarketplaces: Record<string, Marketplace[]> = {};
  failedToDeleteProducts: Partial<Product>[] = [];

  listingId!: string;
  listing?: Product;
  listingJson: any;

  onlyOptional: boolean = false;
  onlyRequired: boolean = false;
  luggage: boolean = true;
  requiredCount: number = 0;
  validationErrors: ValidationError[] = [];
  ready: boolean = false;
  hasUnsavedChanges: boolean = false;

  pagination: Pagination = {
    currentPage: DEFAULT_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    totalCount: DEFAULT_TOTAL_COUNT
  };

  formComponent!: DynamicFormComponent;

  @ViewChild(DynamicFormComponent, { static: false })
  set setFormComponent(formComponent: DynamicFormComponent) {
    if (formComponent && !this.formComponent) {
      this.formComponent = formComponent;
      this.headingScrollTops = this.formComponent.getHeadingTopsMap();
      this.validateForm();
    }
  }

  @ViewChild('formContainer', { read: ElementRef })
  formContainer!: ElementRef;

  form!: FormGroup;
  inputs: { [key: string]: InputBase } = {};
  inputsArray: InputBase[] = [];
  headingScrollTops: number[] = [];
  schema: any;
  schemaMeta: any;
  menuItems: { key: string; label: string }[] = [];
  hasSubmissionErrors: boolean = false;
  ProductStatus = ListingStatus;
  SelectVariant = SelectVariant;
  TooltipPlacement = TooltipPlacement;
  tooltipType = ComponentType;

  scroll$: Subject<number> = new BehaviorSubject<number>(0);
  selectedMenuIndex: number = 0;

  initDone: boolean = false;

  constructor(
    public router: Router,
    public marketplacesService: MarketplacesService,
    private listingsService: ListingsService,
    ref: ElementRef,
    activatedRoute: ActivatedRoute,
    private productsService: ProductsService,
    private modalService: ModalService,
    private toastService: ToastService,
    private translateService: TranslateService,
    public listingValidationService: ListingValidationService,
    private formsService: FormsService,
    private cdr: ChangeDetectorRef,
    private categoryService: CategoryService
  ) {
    super(ref, router, marketplacesService, activatedRoute);
  }

  get submitTooltip(): string {
    if (this.isListingDeleted() && this.marketplace) {
      const country = this.translateService.instant(getCountryTranslateKey(this.marketplace.country.code));
      return this.translateService.instant(
        'marketplaces.marketplace.listing-details.submit.disabled-tooltip-listing-deleted',
        { country }
      );
    }
    const tooltip = this.marketplacesService.getTooltipText(this.marketplace, this.tooltipType.Submit);
    if (tooltip?.length) {
      this.translateService.instant(tooltip);
    }
    return '';
  }

  beforeUnloadNotify(event: any) {
    event.preventDefault();
    // browsers have custom notifications and don't show this message, thus localization is not required

    return event.returnValue = 'Are you sure you want to exit?';
  }

  // FIXME this is a quick hack, a  proper communication approach is required
  async listItem() {
    this.mapFormDetailsToProduct();
    await this.editListing(this.listingJson);
  }

  async saveListing() {
    this.mapFormDetailsToProduct();
    await this.editListing(this.listingJson, false);
    this.hasUnsavedChanges = false;
    this.form.markAsPristine();
    this.cdr.detectChanges();
    removeEventListener('beforeunload', this.beforeUnloadNotify, { capture: true });
  }

  private mapFormDetailsToProduct() {
    const values = this.formsService.getDirtyValues(this.form);
    if (values && Object.keys(values)?.length) {
      this.hasUnsavedChanges = true;
      addEventListener('beforeunload', this.beforeUnloadNotify, { capture: true });
    }
    this.formsService.mapDirtyKeysToJson(this.listingJson, {
      values,
      parents: [],
      inputs: this.inputs
    });
  }

  async ngOnInit() {
    this.loading = true;
    this.listingId = this.activatedRoute.snapshot.params.productId;

    const marketplaces: Marketplace[] = await this.marketplacesService.fetchActiveMarketplaces().toPromise();
    const { regions, marketplacesByRegion } = this.marketplacesService.splitToRegions(marketplaces);

    this.regions = regions;

    this.regionMarketplaces = marketplacesByRegion;
    this.marketplace$.pipe(takeUntil(this.destroyed$)).subscribe(async (marketplace) => {
      if (!marketplace) {
        return;
      }
      this.marketplace = marketplace;

      const countryName = this.translateService.instant(getCountryTranslateKey(marketplace.country.code));

      this.breadcrumbs = [
        { url: '../../..', name: 'marketplaces.breadcrumbs.marketplaces' },
        { url: '..', name: `${marketplace.description} ${countryName} (${marketplace.domain})` },
        { url: '..', name: 'marketplaces.marketplace-products.page-title' }
      ];
      this.loadProduct();
    });


    this.scroll$.pipe(takeUntil(this.destroyed$), debounceTime(200)).subscribe((scroll) => {
      this.calculateSelectedMenuItem(scroll);
      this.cdr.detectChanges();
    });

    this.failedToDeleteProducts = await this.listingsService.fetchDeleteFailedListings().toPromise();
  }

  navigateErrors(sku: string) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['../../errors'], { queryParams: { sku }, relativeTo: this.route })
    );

    window.open(url, '_blank');
  }

  ngAfterViewInit(): void {
    this.initDone = true;
    this.cdr.detectChanges();
  }

  async loadProduct() {
    this.loading = true;
    if (!this.marketplace) {
      return;
    }
    const [listing, listingJson] = await Promise.all([
      this.listingsService.getSingleListing(this.listingId).toPromise(),
      this.listingsService.getSingleListingJson(this.listingId).toPromise()
    ]);

    this.listing = Object.assign({}, listing.data, listing);

    const productId: string = String(this.listing.productId);
    const importedProductJson = await this.productsService.getSingleProductJson(productId).toPromise();

    delete this.listing.data;
    const category = this.listing?.category;
    const [schema, schemaMeta] = await Promise.all([
      this.categoryService.fetchCategorySchema(this.marketplace.id, category).toPromise(),
      this.categoryService.fetchCategorySchemaMeta(this.marketplace.id, category).toPromise()
    ]);

    if (!schema || !schemaMeta) {
      this.validationErrors.push({ message: 'marketplaces.listings.details.errors.missing-schema', path: [] });
      this.loading = false;
      return;
    }

    this.schema = schema;
    this.schemaMeta = schemaMeta;

    this.listing = Object.assign({}, listing.data, listing);
    delete this.listing.data;
    this.listingJson = listingJson;


    if (category?.length) {
      this.breadcrumbs.push({
        url: '..',
        name: this.translateService.instant(`marketplaces.listings.category.${category}`),
        queryParams: { category }
      });
    }
    this.breadcrumbs.push({ url: '', name: this.listing.sku });

    const [form, inputs] = this.formsService.getFormAndInputs(listingJson, schema);

    this.checkForImportedValues(inputs, importedProductJson);

    this.form = form;
    this.inputs = inputs;
    this.inputsArray = Object.values(inputs);
    this.requiredCount = Object.values(this.inputs).filter(input => input.required).length;
    this.makeMenuItems();

    const { pageSize, currentPage } = this.pagination;

    const listingErrorOptions = {
      pageSize: pageSize,
      currentPage: currentPage,
      marketplaceId: this.marketplace?.id
    };

    const listingErrors = this.listingsService.fetchErrorsByListing(listingErrorOptions, this.listing?.sku);
    listingErrors.subscribe((next) => {
      this.hasSubmissionErrors = next.data.length > 0;
    });

    this.loading = false;
    this.validateForm();
    this.cdr.detectChanges();
    this.form.markAsPristine();
  }

  async editListing(listing: any, submit: boolean = true) {
    try {
      await this.listingsService.editListing(this.listingId, listing, submit).toPromise();
      const messageKey = submit ? 'marketplaces.toast.listing-details-submitted' : 'marketplaces.toast.listing-details-updated';
      this.toastService.success(this.translateService.instant(messageKey));
    } catch (error) {
      if (error instanceof HttpErrorResponse && error?.status === 304) {
        if (submit) {
          this.toastService.success(this.translateService.instant('marketplaces.toast.listing-details-submitted'));
        } else {
          this.toastService.warning(this.translateService.instant('marketplaces.toast.listing-not-modified'));
        }
        return;
      }
      const messageKey = submit ? 'marketplaces.toast.submit-listing-failed' : 'marketplaces.toast.save-listing-failed';
      this.toastService.error(this.translateService.instant(messageKey));
    }
  }

  toggleOptional() {
    this.onlyOptional = !this.onlyOptional;
    this.makeMenuItems();
  }

  toggleRequired() {
    this.onlyRequired = !this.onlyRequired;
    this.makeMenuItems();
  }

  scrollToIndex(index: number) {
    this.formContainer.nativeElement.scrollTop = this.headingScrollTops[index + 1] - this.headingScrollTops[0] + 200;
    this.selectedMenuIndex = index + 1;
    this.cdr.detectChanges();
  }

  scrollToTop() {
    this.formContainer.nativeElement.scrollTop = 0;
    this.cdr.detectChanges();
  }

  getScrollTop(index: number) {
    return this.headingScrollTops[index];
  }

  makeMenuItems(): void {
    this.menuItems = Object.values(this.inputs)
      .filter(input => this.isShown(input) && input instanceof GroupInput)
      .map(input => ({ label: input.label, key: input.key }));
  }

  private isShown(input: InputBase) {
    return (this.onlyRequired && input.required) || (this.onlyOptional && !input.required) || (!this.onlyOptional && !this.onlyRequired);
  }

  calculateSelectedMenuItem(scroll: number) {
    let index = 0;
    const scrollWithOffset = scroll + this.headingScrollTops[0] - 100; // TODO offset shouldn't be needed - there's a bug most likely somewhere
    while (scrollWithOffset > this.getScrollTop(index + 1) && index < this.menuItems.length) {
      index += 1;
    }
    this.selectedMenuIndex = index;
  }

  onFormScroll(event: Event) {
    const scroll = (event.target as HTMLElement).scrollTop || 0;
    this.scroll$.next(scroll);
  }

  onFormChange() {
    this.mapFormDetailsToProduct();
    this.validateForm();
  }

  private async validateForm() {
    const listingId = this.listing?.id;
    if (!this.schema || !this.schemaMeta || !this.listingJson || !this.formComponent || !listingId) {
      return;
    }
    this.formsService.clearFormErrors(this.form);

    const errors = await this.listingValidationService.validateApi(listingId, this.listingJson).toPromise();

    this.ready = !errors.length;

    this.validationErrors = errors.filter(({ property }) => {
      return !property;
    });

    this.mapErrorsToForm(errors);

    if (this.formComponent) {
      this.formComponent.recalculateErrors();
    }
    this.cdr.detectChanges();
  }

  private mapErrorsToForm(errors: ValidationError[]): void {
    errors.forEach((error) => {
      let path: string[] = [];
      let propertyName = error.property;
      let group: FormGroup | null = null;

      if (!propertyName) {
        return;
      }

      if (error.path?.length) {
        path = error.path;
        group = this.parseError(path);
      }

      if (!group && error.property && Object.keys(this.inputs).includes(propertyName)) {
        group = this.parseError([propertyName]);
      }

      if (error?.property?.length && this.inputs[propertyName]?.controlType === InputControlType.Group) {
        this.markAllChildrenRequired(this.inputs[propertyName] as GroupInput);
      }
      if (!group?.controls[propertyName] && path?.length > 1) {
        // hack: sometimes error property is mapped to 'value' property which is not reflected in the inputs structure - trying the previous prop for the path then
        propertyName = path[path.length - 2];
      }
      if (!group || !propertyName || !group.controls[propertyName]) {
        this.validationErrors.push(error);
        return;
      }

      let index;
      if (error.fullPath) {
        const regex = new RegExp(`${propertyName}\\[(.*)\\]`, 'g');
        const match = regex.exec(error.fullPath);
        if (match?.length === 2) {
          index = parseInt(match[1], 10);
        }
      }

      this.setErrorsRecursively(group.controls[propertyName], error.message, error.missingProperty, index);
    });
  }

  setErrorsRecursively(group: AbstractControl, error: string, required: boolean = false, index?: number) {
    if (group instanceof FormArray && index !== undefined && (group as FormArray).at(index)) {
      const control: AbstractControl = (group as FormArray).at(index);
      control.setErrors({ invalid: error });
      return;
    }
    group.setErrors({ invalid: error });
    if (required) {
      group.setValidators(Validators.required);
    }
    const formGroupControls = (group as FormGroup)?.controls;
    if (formGroupControls) {
      Object.keys(formGroupControls).forEach(key => {
        this.setErrorsRecursively(formGroupControls[key], error, required, index);
      });
    }
  }

  // TODO this should be in listing-validation.service
  // returns the form group containing control that has the error
  parseError(path?: string[]): FormGroup | null {
    if (!path) {
      return null;
    }
    let group: FormGroup | null = this.form;
    let inputGroup: { [key: string]: InputBase } = this.inputs;

    if (path?.length) {
      for (let item of path) {
        // TODO this if is a hack-fix sometimes path includes the property name and sometimes doesn't - ideally should be consistent
        const newInputGroup = inputGroup[item];
        if (!(newInputGroup instanceof GroupInput)) {
          break;
        }
        group = (group?.get(item) as FormGroup);
        inputGroup = (newInputGroup as GroupInput)?.groupItems;
        if (!inputGroup) {
          group = null;
          break;
        }
      }
    }
    return group;
  }

  // TODO remove this one
  getMessage(error: ValidationError) {
    return `${(error.property || '')}:${error.message || ''}`;
  }

  markAllChildrenRequired(input: GroupInput, parents: string[] = []) {
    Object.keys(input.groupItems).forEach(key => {
      if (input.groupItems[key].controlType === InputControlType.Group) {
        this.markAllChildrenRequired(input.groupItems[key] as GroupInput, parents.concat([input.key]));
        return;
      }
      const path = parents.concat([input.key, key]);
      const group = this.parseError(path);
      if (!group) {
        return;
      }
      group.controls[key].setValidators(Validators.required);
      group.controls[key].setErrors({ invalid: this.translateService.instant('marketplaces.errors.invalid-input') });
    });
  }

  async canDeactivate(): Promise<boolean> {
    if (this.hasUnsavedChanges) {
      try {
        await this.modalService.confirm({
          heading: 'marketplaces.marketplace.listing-details.confirm-modal.heading',
          content: this.translateService.instant(
            'marketplaces.marketplace.listing-details.confirm-modal.content',
            { sku: this.listing?.sku }
          ),
          cancelLabel: 'marketplaces.marketplace.listing-details.confirm-modal.cancel',
          confirmLabel: 'marketplaces.marketplace.listing-details.confirm-modal.confirm'
        });
      } catch (discardPressed: unknown) {
        if (typeof discardPressed === 'boolean') {
          return discardPressed;
        }
        return true;
      }
      await this.saveListing();
    }

    return true;
  }

  checkForImportedValues(inputs: { [key: string]: InputBase }, importedProductJson: any) {
    Object.values(inputs).forEach((input) => {
      if (input.controlType === InputControlType.Group) {
        this.checkForImportedValues((input as GroupInput).groupItems, importedProductJson);
        return;
      }
      const importedValue = get(importedProductJson, input.pathInSchema, null);
      if (importedValue !== null && (String(input.value) !== String(importedValue))) {
        input.importedValue = importedValue;
      }
    });
  }

  isListingDeleted(): boolean {
    if (!this.listing) {
      return false;
    }
    return this.listing.status === ListingStatus.PendingDeletionFailed || this.listing.status === ListingStatus.PendingDeletion;
  }

  showSubmitDeleteModal(event: Event) {
    event.preventDefault();
    const ref = this.modalService.open(SubmitListingDeleteModalComponent);
    ref.instance.products = this.failedToDeleteProducts;
  }
}
