import { Component, ElementRef, OnInit } from '@angular/core';
import { Page } from '@app/pages/page';
import { ActivatedRoute, Router } from '@angular/router';
import { ButtonVariant } from 'lib-juniper';
import { MarketplacesService } from '@app/services/marketplaces.service';

@Component({
  selector: 'app-marketplace',
  templateUrl: './marketplace.component.html',
  styleUrls: ['./marketplace.component.scss', '../page.scss'],
})
export class MarketplaceComponent extends Page implements OnInit {

  ButtonVariant = ButtonVariant;

  opened: boolean = true;

  constructor(
    public router: Router,
    ref: ElementRef,
    private marketplaceService: MarketplacesService,
    private activatedRoute: ActivatedRoute,
  ) {
    super(ref, router, activatedRoute);
  }

  ngOnInit() {
    const marketplaceId = parseInt(this.activatedRoute.snapshot.params?.marketplaceId, 10);
    this.marketplaceService.selectMarketplace(marketplaceId);
  }
}
