import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonVariant, ImagePlaceholderVariant, PlaceholderVariant } from 'lib-juniper';

import { ErrorTableBase } from '@app/components/tables/error-table-base.component';
import { ErrorByListing } from '@app/interfaces/errors';

@Component({
  selector: 'app-listing-errors-table',
  templateUrl: './listing-errors.component.html',
  styleUrls: ['../table.scss', './listing-errors.component.scss'],
})
export class ListingErrorsComponent extends ErrorTableBase<ErrorByListing> {

  PlaceholderVariant = PlaceholderVariant;
  ImagePlaceholderVariant = ImagePlaceholderVariant;
  ButtonVariant = ButtonVariant;

  expandedRows: number[] = [];

  @Output()
  selectSku = new EventEmitter<number>();

  handleSelectSku(listingId: any) {
    this.selectSku.emit(listingId);
  }

  toggleRowExpand(event: Event, rowIndex: number) {
    event.stopPropagation();
    const index = this.expandedRows.indexOf(rowIndex);
    if (index === -1) {
      this.expandedRows.push(rowIndex);
    } else {
      this.expandedRows.splice(index, 1);
    }
  }
}
