import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Marketplace } from '@app/interfaces/marketplace';
import {
  MarketplaceFilter,
  MarketplaceStatus,
  MarketplaceType,
} from '@app/interfaces/marketplaceType';
import { Page } from '@app/pages/page';

import { MarketplacesService } from '@app/services/marketplaces.service';
import { getCountryTranslateKey } from '@app/shared/utils';
import { TranslateService } from '@ngx-translate/core';
import { ModalService, PlaceholderVariant, ToastService } from 'lib-juniper';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-marketplaces-page',
  templateUrl: './marketplaces.page.html',
  styleUrls: ['../page.scss', './marketplaces.page.scss']
})


export class MarketplacesPage extends Page implements OnInit {

  regions: string[] = [];
  regionMarketplaces: Record<string, Marketplace[]> = {};
  filteredRegionMarketplaces: Record<string, Marketplace[]> = {};
  filter: string = '';
  filterResults: boolean = false;
  selectedFilter: MarketplaceStatus = null;
  filteredRegions: string[] = [];
  PlaceholderVariant = PlaceholderVariant;

  marketplaceFilters: MarketplaceFilter[] = [
    {
      key: null,
      value: 'marketplaces.marketplaces.filter.all',
      count: 0
    },
    {
      key: MarketplaceType.Seller,
      value: 'marketplaces.amazon.seller.central',
      count: 0
    },
    {
      key: MarketplaceType.Vendor,
      value: 'marketplaces.amazon.vendor.central',
      count: 0
    }
  ];

  constructor(
    public router: Router,
    public marketplacesService: MarketplacesService,
    public modalService: ModalService,
    private cdr: ChangeDetectorRef,
    private translateService: TranslateService,
    private toastService: ToastService,
    ref: ElementRef,
    public activatedRoute: ActivatedRoute
  ) {
    super(ref, router, activatedRoute);
  }

  async ngOnInit() {
    const marketplaces: Marketplace[] = await this.marketplacesService.fetchActiveMarketplaces().toPromise();
    const { regions, marketplacesByRegion } = this.marketplacesService.splitToRegions(marketplaces);

    this.marketplaceFilters.forEach((filter) => {
      const filteredMarketplaces = marketplaces.filter((marketplace) => marketplace.active === filter.key);
      filter.count = filter.key ? filteredMarketplaces.length : marketplaces.length;
    });

    this.regions = regions;
    this.regionMarketplaces = marketplacesByRegion;
    this.filteredRegionMarketplaces = { ...this.regionMarketplaces };
    this.filteredRegions = [...this.regions];
    this.filterResults = !!Object.keys(this.filteredRegionMarketplaces).length;
    this.loading = false;
    this.cdr.detectChanges();
  }

  filterMarketplaces(status: MarketplaceStatus) {
    this.filteredRegionMarketplaces = { ...this.regionMarketplaces };
    this.selectedFilter = status;
    this.filterResults = !!Object.keys(this.filteredRegionMarketplaces).length;

    if (status) {
      this.filteredRegions.forEach((region) => {
        this.filteredRegionMarketplaces[region] = this.filteredRegionMarketplaces[region].filter((filteredMarketplaces) => filteredMarketplaces.active === status);

        this.filterResults = !!this.filteredRegionMarketplaces[region].length;
      });
    }
  }

  handleAddMarketPlaceClick() {
    this.router.navigate(['add-marketplace']);
  }

  handleSettingsClick(event: Event) {
    event.preventDefault();
    event.stopPropagation();
  }

  handleMarketplaceSelect(marketplaceId: number) {
    this.router.navigate([`marketplaces/${marketplaceId}/listings`]);
  }

  onFilterUpdate(filter: string) {
    this.filter = filter;
    this.filterResults = true;
    if (filter && filter.length) {
      this.filteredRegionMarketplaces = {};
      this.filteredRegions = [];

      Object.keys(this.regionMarketplaces).forEach(region => {
        const filteredMarketplaces = this.regionMarketplaces[region].filter(({ country }) => {
          const countryName = this.translateService.instant(getCountryTranslateKey(country.code));
          return countryName.toLowerCase().includes(this.filter.toLowerCase());
        });
        if (filteredMarketplaces.length) {
          this.filteredRegions.push(region);
          this.filteredRegionMarketplaces[region] = filteredMarketplaces;
        }
      });
    } else {
      this.filteredRegionMarketplaces = { ...this.regionMarketplaces };
      this.filteredRegions = [...this.regions];
    }
    this.cdr.detectChanges();
  }

}

