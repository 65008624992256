import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { AccessibleComponent } from 'lib-juniper';

// TODO more common code can be extracted into this class
@Directive()
export abstract class DynamicInputComponent extends AccessibleComponent {

  @Output()
  readonly resetInputValue = new EventEmitter<void>();

  @Input()
  disabled = false;

  resetInputValueClick() {
    this.resetInputValue.emit();
  }

  writeValue(value: any) {
  }

  setDisabledState(disabled: boolean) {
  }

  forceFocus() {
    // TODO pending implementation
  }

  forceBlur() {
    // TODO pending implementation
  }
}
