import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Marketplace } from '@app/interfaces/marketplace';
import { ButtonVariant, ToggleVariant } from 'lib-juniper';

enum MarketplaceProp {
  ListingManagement,
  AutoSync,
  OrderManagement,
  Activity
}

@Component({
  selector: 'app-marketplace-settings',
  templateUrl: './marketplace-settings.component.html',
  styleUrls: ['./marketplace-settings.component.scss']
})
export class MarketplaceSettingsComponent {

  MarketplaceProp = MarketplaceProp;

  @Input()
  marketplace?: Marketplace;

  @Output()
  marketplaceChange = new EventEmitter<Marketplace>();

  @Output()
  marketplaceEditClick = new EventEmitter<void>();

  @Input()
  showEdit = false;

  ToggleVariant = ToggleVariant;
  ButtonVariant = ButtonVariant;

  async toggleMarketplaceProp(prop: MarketplaceProp) {
    if (this.marketplace?.id) {
      const activity = this.marketplace.activated;
      this.marketplace.activated = !activity;
      this.marketplaceChange.emit(this.marketplace);
    }
  }

  handleEditMarketplaceClick() {
    this.marketplaceEditClick.emit()
  }
}
