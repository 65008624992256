
  <td colSpan="100%">
    <pup-placeholder
      [variant]="emptyPlaceholderVariant"
      [imageSrc]="emptyPlaceholderImgSrc"
      [message]="emptyPlaceholderMessage | translate"
      [description]="emptyPlaceholderDescription | translate"
    ></pup-placeholder>
  </td>

