<pup-wizard
  (close)="close()"
  (finish)="applyAttributeSelection()"
  caption="{{ 'marketplaces.modal.attribute-select.title' | translate }}"
  finishLabel="{{ 'marketplaces.modal.attribute-select.save' | translate }}"
  class="custom-wizard"
  [loading]="loading"
>
  <ng-template wizard-page>
    <pup-heading class="wizard-title" [level]="3"
      >{{'marketplaces.modal.attribute-select.sub-title' |
      translate}}</pup-heading
    >
    <div class="content-container" *ngIf="!loading">
      <div class="category-container" *ngIf="showCategorySelection">
        <label class="container-label category-label"
          >{{ 'marketplaces.modal.attribute-select.category-title' | translate
          }}</label
        >
        <app-info-bar
          mainMessage="{{ 'marketplaces.modal.attribute-select.category-message' | translate }}"
          *ngIf="!allCategories"
        ></app-info-bar>
        <pup-input
          class="search-input"
          [clearable]="true"
          placeholder="marketplaces.modal.category-select.search-categories"
          [value]="categoryFilter"
          (valueChange)="onCategoryFilterUpdate($event)"
          [disabled]="disabled"
        >
          <pup-icon path="line/ic-search"></pup-icon>
        </pup-input>
        <pup-menu *ngIf="filteredCategories.length">
          <pup-menu-item
            *ngFor="let category of filteredCategories;"
            class="category-item"
            (select)="handleCategorySelect(category)"
            [selected]="category === selectedCategory"
          >
            {{ category.name }}
          </pup-menu-item>
        </pup-menu>
        <pup-placeholder
          *ngIf="!categories.length"
          class="empty-placeholder"
          [variant]="PlaceholderVariant.Categories"
          description="marketplaces.modal.attributes.category.not-found.description"
          message="marketplaces.modal.attributes.category.not-found.message"
        ></pup-placeholder>
        <pup-placeholder
          *ngIf="!filteredCategories.length && categoryFilter"
          class="empty-placeholder"
          [variant]="PlaceholderVariant.NotFoundV2"
          description="marketplaces.modal.attributes.category.filtered-not-found.description"
          message="marketplaces.modal.attributes.category.filtered-not-found.message"
        ></pup-placeholder>
      </div>
      <div
        class="selection-container"
        [class.with-categories]="showCategorySelection"
      >
        <pup-list-select
          (add)="addSingle($event)"
          (addAll)="addAll()"
          (remove)="removeSingle($event)"
          (removeAll)="removeAll()"
          (reorder)="handleReorder($event)"
          [selectedItems]="selectedAttributes"
          [availableItems]="filteredAttributes"
          [isSelected]="isSelected"
          labelProp="name"
        >
          <pup-list-select-available
            [labels]="{ containerLabel: 'marketplaces.modal.attribute-select.available-attributes' }"
            [listTemplate]="itemAvailable"
            [useVirtualScroll]="true"
          >
            <app-filter-switcher
              class="additional-header"
              [selected]="availableAttributesGroupFilter"
              (select)="selectAttributesGroupSwitcher('available', $event)"
              [items]="attributes"
            ></app-filter-switcher>
            <pup-input
              class="search-input additional-header"
              [clearable]="true"
              placeholder="marketplaces.modal.attribute-select.search-attributes"
              [value]="filter"
              (valueChange)="onFilterUpdate($event)"
              [disabled]="disabled"
            >
              <pup-icon path="line/ic-search"></pup-icon>
            </pup-input>
            <div class="empty-placeholder" *ngIf="loadingAttributes">
              <pup-loading-visual></pup-loading-visual>
            </div>
            <pup-placeholder
              *ngIf="!loadingAttributes && !filteredCategories.length && !filter"
              class="empty-placeholder"
              [variant]="PlaceholderVariant.Attributes"
              description="marketplaces.modal.attributes.not-found.description"
              message="marketplaces.modal.attributes.not-found.messag"
            ></pup-placeholder>
            <pup-placeholder
              *ngIf="!loadingAttributes && !filteredAttributes.length && filter"
              class="empty-placeholder"
              [variant]="PlaceholderVariant.NotFoundV2"
              description="marketplaces.modal.attributes.filtered-not-found.description"
              message="marketplaces.modal.attributes.filtered-not-found.message"
            ></pup-placeholder>
          </pup-list-select-available>
          <app-filtered-list-selection
            [lockedAttributes]="disabled ? [] : lockedAttributes"
            [labels]="{ containerLabel: 'marketplaces.modal.attribute-select.selected-attributes' }"
            [sortable]="true"
            [onlySystem]="filterSelected"
            [useVirtualScroll]="true"
          >
            <app-filter-switcher
              class="additional-header"
              [selected]="selectedAttributesGroupFilter"
              (select)="selectAttributesGroupSwitcher('selected', $event)"
              [items]="allSelectedAttributes"
            ></app-filter-switcher>
            <pup-placeholder
              class="empty-placeholder"
              *ngIf="availableAttributes"
              imageSrc="assets/visuals/arrows.svg"
              message="marketplaces.modal.attribute-select.empty-selected"
              description=""
            ></pup-placeholder>
            <pup-placeholder
              class="empty-placeholder"
              *ngIf="!availableAttributes"
              [variant]="PlaceholderVariant.Attributes"
              message="marketplaces.modal.attributes.not-found.messag"
              description="marketplaces.modal.attributes.seelected-not-found.description"
            ></pup-placeholder>
          </app-filtered-list-selection>
        </pup-list-select>
      </div>
    </div>
    <pup-loading-visual *ngIf="loading"></pup-loading-visual>
  </ng-template>
</pup-wizard>

<ng-template #itemAvailable let-item="item">
  <div class="attribute-item">
    <div
      class="item-container"
      [innerHTML]="item.name | highlight: filter"
    ></div>
    <pup-icon
      class="icon-item"
      path="line/ic-arrow/right"
      *ngIf="!isSelected(item)"
      [tooltip]="'marketplaces.modal.attributes.tooltip.insert' | translate"
    ></pup-icon>
  </div>
</ng-template>
