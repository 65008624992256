<pup-dropdown-date-select
  [date]="value"
  (dateChange)="updateDate($event)"
  [showTimeSelect]="true"
  [invalid]="invalid"
  [validationHint]="hint"
  [disabled]="disabled"
>
</pup-dropdown-date-select>
<pup-button class="clear-button" [variant]="ButtonVariant.Cancel" (click)="clearDate()" *ngIf="!!value">
  <pup-icon path="line/ic-close"></pup-icon>
</pup-button>
