import { Injectable } from '@angular/core';
import { Attribute } from '@app/interfaces';
import { Category } from '@app/interfaces/category';
import { CategoryService } from '@app/services/category.service';

export const SELECTIONS_STORAGE_KEY = 'pup_marketplaces_selections_storage_key';
export const CATEGORY_STATE_STORAGE_KEY = 'pup_marketplaces_category_state_storage_key';


interface CategoryServiceState {
  selectedCategories: Category[],
  displayedProductAttributes: Attribute[],
  displayedListingAttributes: Attribute[],
}

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor(public categoryService: CategoryService) {
  }

  saveProductSelections(data: { selections: string[], all: boolean, url: string }) {
    sessionStorage.setItem(this.getStorageKeyWithHostname(SELECTIONS_STORAGE_KEY), JSON.stringify(data));
  }

  getProductSelections(): { all: boolean, selections: string[], url: string } | undefined | never {
    const selections = sessionStorage.getItem(this.getStorageKeyWithHostname(SELECTIONS_STORAGE_KEY));
    if (!selections) {
      return;
    }
    return JSON.parse(selections);
  }

  clearProductSelections() {
    sessionStorage.removeItem(SELECTIONS_STORAGE_KEY);
  }

  saveCategoryServiceState() {
    localStorage.setItem(this.getStorageKeyWithHostname(CATEGORY_STATE_STORAGE_KEY), JSON.stringify({
      selectedCategories: this.categoryService.selectedCategories,
      displayedProductAttributes: this.categoryService.displayedProductAttributes,
      displayedListingAttributes: this.categoryService.displayedListingAttributes
    }));
  }

  restoreCategoryServiceState() {
    try {
      const json = this.tryKeyWithHostname(CATEGORY_STATE_STORAGE_KEY);
      if (!json) {
        return;
      }
      const state: CategoryServiceState = JSON.parse(json);
      this.categoryService.selectedCategories = state.selectedCategories;
      this.categoryService.displayedListingAttributes = state.displayedListingAttributes;
      this.categoryService.displayedProductAttributes = state.displayedProductAttributes;
    } catch {
    }
  }

  saveData(key: string, data: any) {
    localStorage.setItem(key, JSON.stringify(data));
  }

  restoreData(key: string): any {
    try {
      const json = localStorage.getItem(key);
      if (!json) {
        return;
      }
      return JSON.parse(json);
    } catch {
    }
  }

  getStorageKeyWithHostname(key: string): string {
    const { hostname } = location;
    if (!hostname) {
      return key;
    }
    return `${key}-${hostname}`;
  }

  tryKeyWithHostname(key: string) { // TODO remove this method later, just fetch with hostname
    let json = localStorage.getItem(this.getStorageKeyWithHostname(key));
    if (!json) {
      json = localStorage.getItem(key);
      // clear the current key
      localStorage.removeItem(key);
    }
    return json;
  }
}
