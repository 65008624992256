import { Component, Input } from '@angular/core';

import { JuniperComponent } from 'lib-juniper';

@Component({
  selector: 'app-error-notification',
  templateUrl: './error-notification.component.html',
  styleUrls: ['./error-notification.component.scss'],
})
export class ErrorNotificationComponent extends JuniperComponent {

  @Input()
  message?: string;
}
