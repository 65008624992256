<span *ngIf="active && !invalid" class="status active">
  {{ 'marketplaces.marketplaces.active' | translate }}
</span>

<span *ngIf="!active && !invalid" class="status inactive">
  {{ 'marketplaces.marketplaces.inactive' | translate }}
</span>

<span  *ngIf="invalid" class="status invalid-auth">
  {{ 'marketplaces.marketplaces.status.invalid-authentication' | translate }}
</span>

