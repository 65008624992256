import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap, } from 'rxjs/operators';
import { ApiClient } from 'lib-platform-common';
import { JwtHelperService } from '@auth0/angular-jwt';

import { CategoryService } from '@app/services/category.service';

interface LoginResponse {
  token: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  redirectUrl: string = '';

  constructor(
    protected http: HttpClient,
    protected router: Router,
    private api: ApiClient,
    public categoryService: CategoryService
  ) {}

  public login(email: string, password: string): Observable<LoginResponse> {
    return this.api.post<LoginResponse>({ endpoint: '/login', data: { password, username: email } }).pipe(
      tap(({ token }: LoginResponse) => {
        const url = this.redirectUrl;
        localStorage.setItem('access_token', token);
        this.router.navigate([url]);
      })
    );
  }

  public isLoggedIn(): boolean {
    const helper = new JwtHelperService();
    const token = localStorage.getItem('access_token');
    return !!token && !helper.isTokenExpired(token);
  }

}
