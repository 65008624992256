import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  template: '',
})
export class LogoutComponent implements OnInit {

  constructor(public router: Router) {}

  ngOnInit() {
    localStorage.removeItem('access_token');
    this.router.navigate(['login']);
  }

}
