import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { TableBaseComponent } from '@app/components/tables/table-base.component';
import { Attribute, ListingDeleteStatus, ListingStatus, MIN_PAGE_SIZE, Product } from '@app/interfaces';
import { TranslateService } from '@ngx-translate/core';
import {
  ButtonVariant,
  DropdownDirection,
  ImagePlaceholderVariant,
  PlaceholderVariant,
  TooltipAlignment,
  TooltipPlacement
} from 'lib-juniper';

const DEFAULT_ATTRIBUTES: Attribute[] = [
  { name: 'Category', value: 'category', isSystem: true },
  { name: 'Last update', value: 'lastUpdate', isSystem: true },
  { name: 'Marketplaces', value: 'marketplaces', isSystem: true }
];

const NO_LOCKED_CELLS_THRESHOLD = 3;

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-product-table',
  templateUrl: './product-table.component.html',
  styleUrls: ['../table.scss', './product-table.component.scss']
})
export class ProductTableComponent extends TableBaseComponent<Product> {

  @Input()
  displayedAttributes: Attribute[] = DEFAULT_ATTRIBUTES;

  @Input()
  noResultsFoundMessage = 'marketplaces.listings.no-results.message';

  @Output()
  attributesReorder = new EventEmitter<{ currentIndex: number, previousIndex: number }>();

  @Input()
  lockedAttributes: Attribute[] = [];

  @Input()
  marketplaceActivity: boolean | undefined = true;

  @Input()
  disabledSelect: boolean = false;

  @Input()
  selectTooltip: string = '';

  @Input()
  failedToDeleteProductCount: number = 0;

  @Input()
  excludedFromTotalCount: number = 0;

  MIN_PAGE_SIZE = MIN_PAGE_SIZE;
  NO_LOCKED_CELLS_THRESHOLD = NO_LOCKED_CELLS_THRESHOLD;
  PlaceholderVariant = PlaceholderVariant;
  ImagePlaceholderVariant = ImagePlaceholderVariant;
  ButtonVariant = ButtonVariant;
  DropdownDirection = DropdownDirection;
  TooltipPlacement = TooltipPlacement;
  TooltipAlignment = TooltipAlignment;

  constructor(private translateService: TranslateService, ref: ElementRef) {
    super(ref);
  }

  drop(event: CdkDragDrop<string[]>) {
    const { currentIndex, previousIndex } = event;
    this.attributesReorder.emit({ currentIndex, previousIndex });
  }

  attributeIsNotLocked(attribute: Attribute) {
    return !this.lockedAttributes.find(item => item.value === attribute.value);
  }

  isSelectDisabled(item: Product): boolean {
    return this.disabledSelect || this.isProductDeleted(item) || this.isProductDeleteFailed(item) || !this.marketplaceActivity;
  }

  isProductDeleted(item: Product): boolean {
    return (item.deletedAt !== null && item.deletionStatus === ListingDeleteStatus.Pending) || item.status === ListingStatus.PendingDeletion;
  }

  isProductDeleteFailed(item: Product): boolean {
    return (item.deletedAt !== null && item.deletionStatus === ListingDeleteStatus.Failed) || item.status === ListingStatus.PendingDeletionFailed;
  }

  getRowTooltip(product: Product): string {
    if (this.isProductDeleted(product)) {
      return this.translateService.instant('marketplaces.product.deleted.tooltip-hover');
    }
    if (this.isProductDeleteFailed(product) && this.failedToDeleteProductCount) {
      return this.translateService.instant(
        'marketplaces.product.delete-failed.tooltip-hover',
        { count: this.failedToDeleteProductCount }
      );
    }
    return '';
  }

  getSelectableItems(): Product[] {
    return this.items.filter(product => !this.isProductDeleted(product) && !this.isProductDeleteFailed(product));
  }
}
