import { Component } from '@angular/core';
import { MarketplacePage } from '@app/pages/marketplace/marketplace.page';

@Component({
  selector: 'app-marketplace-dashboard-page',
  templateUrl: './marketplace-dashboard.page.html',
  styleUrls: ['../../page.scss', './marketplace-dashboard.page.scss'],
})
export class MarketplaceDashboardPage extends MarketplacePage {

}
