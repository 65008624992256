<span class="label" *ngIf="showLabel">{{ 'marketplaces.marketplace-settings.auth.marketplace-type' | translate }}:</span>
<div class="info">
  <div class="marketplaces-type__container" *ngIf="marketplaceTypes[marketplaceType] as mType">
    <div class="marketplaces-type__logo">
      <img src="assets/visuals/merchants/{{ mType.icon }}" alt="{{ mType.name }} logo">
    </div>
    <div class="marketplaces-type__name" *ngIf="showName">
      <span>{{ mType.name }}</span>
    </div>
  </div>
</div>

