<pup-wizard
  class="add-marketplace-wizard"
  [validate]="validate"
  [loading]="loading"
  (close)="close()"
  (finish)="finish()"
  [(page)]="page"
  [showCancelLabel]="authDone && !authSuccess"
  [finishLabel]="authDone && !authSuccess ? 'marketplaces.edit-marketplace.edit-auth' : 'marketplaces.edit-marketplace.finish'"
  cancelLabel="marketplaces.edit-marketplace.finish"
  caption="{{ 'marketplaces.edit-marketplace.caption.main' | translate }}"
>

  <ng-template wizard-page>
    <div class="first-page">
      <ng-container *ngTemplateOutlet="marketplaceInfo"></ng-container>
      <span
        class="footer-note"
        [innerHTML]="'marketplaces.edit-marketplace.content.footer-text.amazon-vendor' | translate"
        *ngIf="marketplace?.active === MarketplaceType.Vendor"
      ></span>
      <span
        class="footer-note"
        [innerHTML]="'marketplaces.edit-marketplace.content.footer-text.amazon-seller' | translate"
        *ngIf="marketplace?.active === MarketplaceType.Seller"
      ></span>
    </div>
  </ng-template>

  <ng-template wizard-page>
    <div *ngIf="authSuccess" class="result success">
      <pup-icon size="90px" color="#2BAD4F" path="solid/ic-check--fill"></pup-icon>
      <span class="message">{{ 'marketplaces.edit-marketplace.finish.success' | translate }}</span>
    </div>
    <div *ngIf="!authSuccess" class="result failure">
      <pup-icon size="90px" color="#F04359" path="solid/ic-close--fill"></pup-icon>
      <span class="message">{{ 'marketplaces.edit-marketplace.finish.failure' | translate }}</span>
      <span *ngIf="marketplace?.active === MarketplaceType.Vendor">
        {{ 'marketplaces.edit-marketplace.finish.failure.message-vendor' | translate }}
      </span>
      <span *ngIf="marketplace?.active === MarketplaceType.Seller">
        {{ 'marketplaces.edit-marketplace.finish.failure.message-seller' | translate }}
      </span>
    </div>
    <ng-container *ngTemplateOutlet="marketplaceInfo"></ng-container>
  </ng-template>

  <ng-template #marketplaceInfo>
    <div *ngIf="marketplace" class="marketplace-info">

      <span class="label">{{ 'marketplaces.marketplace-settings.auth.marketplace-type' | translate }}:</span>
      <app-marketplace-type
        *ngIf="marketplace.active !== null"
        [marketplaceType]="marketplace.active"
        [showLabel]="false"
        [showName]="true"
      ></app-marketplace-type>

      <span class="label">{{ 'marketplaces.marketplace-settings.auth.marketplace' | translate }}:</span>
      <app-country-tag [marketplace]="marketplace"></app-country-tag>
    </div>
  </ng-template>
</pup-wizard>
