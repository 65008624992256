<app-table-controls
  [allToggled]="allSelected || marked.length > 0"
  [disabled]="!items.length || loading"
  [hideSelect]="true"
  [showSelectBox]="allSelected || marked.length > 0"
  [showSelectAll]="isSelectAllVisible"
  [selectedCount]="allSelected ? (total - marked.length) : marked.length"
  [selectLabel]="selectedLabel"
  [total]="total"
  [(page)]="page"
  [(perPage)]="perPage"
  (selectAll)="selectAll()"
  (toggleAll)="toggleAllOnPage()"
>
  <ng-content select=".actions"></ng-content>
</app-table-controls>
<pup-table *ngIf="!loading" class="collapsible-table">
  <pup-table-head cdkDropList cdkDropListOrientation="horizontal" (cdkDropListDropped)="drop($event)">
    <ng-container *ngFor="let column of displayColumns">
      <pup-table-cell *ngIf="column.fieldName === 'action'"></pup-table-cell>
      <pup-table-cell cdkDrag cdkDragLockAxis="x"
                      *ngIf="column.fieldName !== 'action'
                      && !column.isSortable">
        <div *cdkDragPlaceholder class="table-cell-drag-placeholder"></div>
        <pup-icon class="drag-handle" path="line/ic-drag-line"></pup-icon>
        {{ column.columnKey | translate }}
      </pup-table-cell>

      <pup-table-cell
        *ngIf="column.isSortable"
        cdkDrag
        cdkDragLockAxis="x"
        [sortable]="true"
        (sortClick)="setColumnSorting($event, column.fieldName)"
        [ascending]="sorting?.[column.fieldName]?.ascending"
        [descending]="sorting?.[column.fieldName]?.descending">
        <div *cdkDragPlaceholder class="table-cell-drag-placeholder"></div>
        <pup-icon class="drag-handle" path="line/ic-drag-line"></pup-icon>
        {{ column.columnKey | translate }}
      </pup-table-cell>
    </ng-container>
  </pup-table-head>

    <ng-container *ngIf="items.length > 0 && queryResults; else empty">
      <ng-container *ngFor="let listing of items; let listingIndex = index">
        <ng-container *ngFor="let error of listing.errors; let errorIndex = index">
          <pup-table-row
            *ngIf="errorIndex < 1 || expandedRows.includes(listingIndex)"
            [class]="errorIndex >= 1 && expandedRows.includes(listingIndex) ? 'listing-errors expanded':'listing-errors'"
          >
            <ng-container *ngFor="let column of displayColumns">
              <pup-table-cell *ngIf="column.fieldName === 'action'"
                              [class.blank]="errorIndex > 0" class="control-cell action">
                <ng-container *ngIf="errorIndex < 1">
                  <pup-icon
                    *ngIf="errorIndex === 0 && listing.errors.length > 1"
                    path="{{ expandedRows.includes(listingIndex) ? 'line/ic-chevron/down' : 'line/ic-chevron/right' }}"
                    (click)="toggleRowExpand($event, listingIndex)"
                  ></pup-icon>
                </ng-container>
              </pup-table-cell>
              <pup-table-cell  *ngIf="column.fieldName === 'sku'"
                               [class.blank]="errorIndex > 0"
                               class="sku strong action"
                               (click)="handleSelectSku(listing.listingId)">
                <ng-container *ngIf="errorIndex < 1">{{ listing?.[column.fieldName] }}</ng-container>
              </pup-table-cell>
              <pup-table-cell  *ngIf="column.fieldName === 'image'"
                               [class.blank]="errorIndex > 0">
                <ng-container *ngIf="errorIndex < 1">
                  <pup-image class="listing-image" [placeholderVariant]="ImagePlaceholderVariant.Small" [src]="listing.image">
                  </pup-image>
                </ng-container>
              </pup-table-cell>
              <pup-table-cell  *ngIf="column.fieldName === 'title'"
                               [class.blank]="errorIndex > 0">
                <ng-container *ngIf="errorIndex < 1">{{ listing.name }}</ng-container>
              </pup-table-cell>
              <pup-table-cell
                *ngIf="column.fieldName === 'code'"
                class="error-count">
                <span>{{ error?.[column.fieldName] }}</span>
                <span class="extra-error-count" *ngIf="errorIndex === 0 && listing.errors.length > 1">+{{listing.errors.length - 1}}</span>
              </pup-table-cell>
              <pup-table-cell  *ngIf="column.fieldName === 'type'"
                               class="error-type"
                               [ngClass]="{ other: error.group === ErrorGroupType.Other }">
              <span>
                {{ 'marketplaces.errors.table.error-type.' + error.group | translate }}
              </span>
              </pup-table-cell>
              <pup-table-cell *ngIf="column.fieldName === 'message'">{{ formatErrorMessage(error) }}</pup-table-cell>
              <pup-table-cell *ngIf="column.fieldName === 'submissionId'">{{ error?.[column.fieldName] }}</pup-table-cell>
              <pup-table-cell *ngIf="column.fieldName === 'submissionTimestamp'">{{ error.submissionTimestamp | dateTime }}</pup-table-cell>
            </ng-container>
          </pup-table-row>
        </ng-container>
      </ng-container>
  </ng-container>

  <ng-container *ngIf="!queryResults">
    <app-empty-placeholder
      class="empty-placeholder"
      [emptyPlaceholderVariant]="PlaceholderVariant.NotFoundV2"
      [emptyPlaceholderMessage]="'marketplaces.errors.no-results.message'"
      [emptyPlaceholderDescription]="'marketplaces.errors.no-results.description'"
    >
    </app-empty-placeholder>
  </ng-container>
</pup-table>

<ng-template #empty>
  <app-empty-placeholder
    *ngIf="queryResults"
    class="empty-placeholder"
    [emptyPlaceholderVariant]="placeholderVariant"
    [emptyPlaceholderImgSrc]="placeholderImgSrc"
    [emptyPlaceholderMessage]="noProductsMessageKey"
    [emptyPlaceholderDescription]="noProductsDescriptionKey"
  >
  </app-empty-placeholder>
</ng-template>

<app-skeleton-table *ngIf="loading"></app-skeleton-table>
