import { Injectable, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { ApiClient } from 'lib-platform-common';
import { Category } from '@app/interfaces/category';
import { map } from 'rxjs/operators';
import { Attribute } from '@app/interfaces/product';

const lockedAttributes: Attribute[] = [
  { name: 'Main image', value: 'image', isSystem: false },
  { name: 'Product name', value: 'title', isSystem: false, sortable: true },
  { name: 'Product SKU', value: 'sku', isSystem: false, sortable: true }
];

const defaultAttributes: Attribute[] = [
  { name: 'Category', value: 'category', isSystem: true },
  { name: 'Last update', value: 'lastUpdate', isSystem: true, sortable: true },
  { name: 'Marketplaces', value: 'marketplaces', isSystem: true }
];

const defaultListingAttributes: Attribute[] = [
  { name: 'Status', value: 'status', isSystem: true },
  { name: 'Readiness score', value: 'valid', isSystem: true }
];

@Injectable({
  providedIn: 'root'
})
export class CategoryService implements OnDestroy {
  private _selectedCategories: Category[] = [];

  categories: Subject<Category[]> = new BehaviorSubject<Category[]>([]);
  displayedProductAttributes: Attribute[] = [defaultAttributes[1], defaultAttributes[2]];
  displayedListingAttributes: Attribute[] = defaultListingAttributes;

  lockedAttributes = lockedAttributes;

  constructor(private api: ApiClient, private translateService: TranslateService) {}

  get selectedCategories(): Category[] {
    return this._selectedCategories;
  }

  set selectedCategories(categories: Category[]) {
    // this whole mapping is needed for some categories from localStorage are coming with translations
    // TODO remove one day
    this._selectedCategories = categories.map((category: Category) => {
      if (!category.translatedName?.length) {
        category.translatedName = this.translateService.instant(`marketplaces.listings.category.${category.name}`);
      }
      return category;
    });
  }

  ngOnDestroy(): void {
    this.categories.complete();
  }

  // TODO this should be fetching our 'internal' categories once they're there, not a specific marketplace ones
  fetchCategories(): void {
    this.api
      .get<string[]>({ endpoint: 'categories' })
      .subscribe((categories) => {
        if (!categories) {
          return;
        }
        const categoriesWithIds: Category[] =
          categories.map((name: string, index: number) => ({
            name,
            id: String(index),
            translatedName: this.translateService.instant(`marketplaces.listings.category.${name}`)
          }));

        this.categories.next(categoriesWithIds);
      });
  }

  fetchAttributes(category: string, isListing: boolean = false): Observable<Attribute[]> {
    return this.api
      .get<string[]>({ endpoint: `attributes`, parameters: { category } })
      .pipe(
        map((response) =>
          response.map((name) => ({ name, value: name, isSystem: false }))
        ),
        map((categories: Attribute[]) => { // TODO this should be coming from api
          if (isListing) {
            return categories.concat(defaultListingAttributes);
          }
          return categories.concat(defaultAttributes);
        })
      );
  }

  fetchCategorySchema(marketplaceId: number, category: string = 'LUGGAGE'): Observable<any> {
    return this.api.get<any>({ endpoint: `marketplaces/${marketplaceId}/product-types/${category}` })
      .pipe(map((schema: any) => {
        delete schema.$schema;
        delete schema.$id;
        return schema;
      }));
  }

  fetchCategorySchemaMeta(marketplaceId: number, category: string = 'LUGGAGE'): Observable<any> {
    return this.api.get<any>({ endpoint: `marketplaces/${marketplaceId}/product-types/${category}/meta` })
      .pipe(map((schema: any) => {
        delete schema.$schema;
        delete schema.$id;
        return schema;
      }));
  }

  resetDisplayedProductAttributes() {
    this.displayedProductAttributes = [defaultAttributes[1], defaultAttributes[2]];
  }
}
