import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { JuniperComponent, TooltipPlacement } from 'lib-juniper';
import { Marketplace } from '@app/interfaces/marketplace';
import { getCountryTranslateKey } from '@app/shared/utils';
import { MarketplaceType } from '@app/interfaces/marketplaceType';

type GroupedMarketplaces = {
  seller: Marketplace[],
  vendor: Marketplace[],
};

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-marketplace-flags',
  styleUrls: ['marketplace-flags.component.scss'],
  templateUrl: 'marketplace-flags.component.html'
})
export class MarketplaceFlagsComponent extends JuniperComponent {

  groupedMarketplaces: {
    seller: Marketplace[],
    vendor: Marketplace[],
  } = {
    seller: [],
    vendor: []
  };

  TooltipPlacement = TooltipPlacement;

  @Input('marketplaces')
  set setMarketplaces(marketplaces: Marketplace[]) {
    this.groupedMarketplaces = marketplaces.reduce((acc: GroupedMarketplaces, marketplace: Marketplace) => {
      if (marketplace.active === MarketplaceType.Seller) {
        acc.seller.push(marketplace);
      }
      if (marketplace.active === MarketplaceType.Vendor) {
        acc.vendor.push(marketplace);
      }

      return acc;
    }, { seller: [], vendor: [] });
  }

  marketplaces!: Marketplace[];

  getFlagImageSrc(marketplace: Marketplace) {
    return `/assets/visuals/flags/${marketplace?.country?.code}.svg`
  }

  getCountryTranslateKey(code: string): string {
    return getCountryTranslateKey(code);
  }
}
