import { Component, Input } from '@angular/core';
import { JuniperComponent } from 'lib-juniper';

@Component({
  selector: 'app-marketplace-activity',
  styleUrls: ['marketplace-activity.component.scss'],
  templateUrl: 'marketplace-activity.component.html'
})
export class MarketplaceActivityComponent extends JuniperComponent {

  @Input()
  invalid = false;

  @Input()
  active = false;

}
