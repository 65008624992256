import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { EditMarketplacePage } from '@app/pages/wizards/edit-marketplace/edit-marketplace.page';
import { TranslateModule } from '@ngx-translate/core';

import { JuniperModule } from 'lib-juniper';

import { SharedModule } from '@app/shared/shared.module';
import { AddMarketplacePage } from '@app/pages/wizards/add-marketplace/add-marketplace.page';
import { CategorySelectorPage } from '@app/pages/wizards/category-selector/category-selector.page';
import { AttributeSelectorPage } from '@app/pages/wizards/attribute-selector/attribute-selector.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    JuniperModule,
    TranslateModule,
    SharedModule,
  ],
  declarations: [
    AddMarketplacePage,
    AttributeSelectorPage,
    CategorySelectorPage,
    EditMarketplacePage
  ]
})
export class WizardsModule { }
