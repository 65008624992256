import { InputBase } from '@app/components/dynamic-form/inputs/input-base';
import { InputControlType } from '@app/interfaces';

export class GroupInput extends InputBase {

  controlType = InputControlType.Group;

  topGroup: boolean = false;

  groupItems: { [key: string]: InputBase } = { };
}

