import { Inject, Injectable } from '@angular/core';
import { ApiClient, Heap, TrackingMetadata } from 'lib-platform-common';
import { DOCUMENT } from '@angular/common';
import { Observable } from 'rxjs';

declare var heap: Heap;

// TODO this is a copy of the same service from lib-platform-common. It should go away in favor of the latter, once there's common auth
@Injectable({
  providedIn: 'root'
})
export class HeapService {

  constructor(
    @Inject('heapAppId') private heapAppId: string,
    @Inject(DOCUMENT) private document: Document,
    private api: ApiClient
  ) {}

  public async initialize() {
    await this.appendScript();
    await this.fetchMetadata();
  }

  identify(identity: string) {
    heap.identify(identity);
  }

  addUserProperties(props: Object) {
    heap.addUserProperties(props);
  }

  appendScript(): Promise<boolean> {
    // TODO: There certainly CERTAINLY is a better way to do this
    return new Promise<boolean>((resolve) => {
      const headTag = this.document.getElementsByTagName('head')[0];
      const scriptTag = this.document.createElement('script');
      scriptTag.id = 'HeapScript';
      scriptTag.innerHTML = `
window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
heap.load("${this.heapAppId}");
`;
      headTag.insertBefore(scriptTag, headTag.firstChild);
      resolve(true);
    });
  }

  async fetchMetadata() {
    const { identity, ...tracking } = await this.getTrackingMetadata().toPromise();

    this.identify(identity);
    this.addUserProperties(tracking);
  }

  // User metadata for tracking
  getTrackingMetadata(): Observable<TrackingMetadata> {
    return this.api.get<TrackingMetadata>({
      endpoint: 'user/authenticated'
    });
  }
}
