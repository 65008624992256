<div class="heading-wrapper">
  <label *ngIf="labels.containerLabel" class="container-label">{{ labels.containerLabel | translate }}</label>
  <pup-button
    *ngIf="showHandleAll"
    [variant]="ButtonVariant.Link"
    [disabled]="!attributes.length"
    (click)="handleRemoveAll()"
  >
    <span translate>{{ labels.allActionLabel }}</span>
  </pup-button>
</div>
<ng-content select=".additional-header"></ng-content>
<div
  class="list-container"
  cdkDropList
  [cdkDropListDisabled]="!sortable || filterSelected"
  (cdkDropListDropped)="drop($event)"
>
  <ng-container *ngIf="lockedAttributes">
    <div *ngFor="let item of lockedAttributes" class="list-item locked">
      <pup-icon path="solid/ic-authentication" class="lock-icon" color="#2F4255"
      ></pup-icon>
      <pup-icon *ngIf="icon" [path]="icon" class="item-icon"></pup-icon>
      {{ getItemValue(item) }}
    </div>
  </ng-container>

  <ng-content *ngIf="!(attributes.length)" select=".empty-placeholder"></ng-content>
  <ng-container *ngFor="let item of attributes; let index = index">
    <div
      (cdkDragEnded)="dragEnd()"
      (cdkDragStarted)="dragStart()"
      *ngIf="!(lockedAttributes && lockedAttributes.includes(item))"
      cdkDrag
      class="list-item"
    >
      <div *cdkDragPlaceholder class="list-item-placeholder"></div>
      <pup-icon
        cdkDragHandle
        path="line/ic-drag-line"
        class="list-selection-drag-handle"
        color="#a3afbb"
        [tooltip]="filterSelected ? ('marketplaces.modal.attributes.tooltip.disabled-drag' | translate) : ''"
        *ngIf="sortable"
      ></pup-icon>
      <app-button-or-input
        *ngIf="!filterSelected"
        [value]="index + 1"
        (valueUpdate)="changeIndex($event)"
      ></app-button-or-input>
      <pup-icon *ngIf="icon" [path]="icon" class="item-icon"></pup-icon>
      <div class="item-container">{{ getItemValue(item) }}</div>
      <pup-badge *ngIf="item.isSystem" class="info-badge">system</pup-badge>
      <pup-icon
        *ngIf="isSelected(item)"
        class="remove-icon"
        path="line/ic-close"
        (click)="handleRemove(item)"
        [tooltip]="'marketplaces.modal.attributes.tooltip.remove' | translate"
      ></pup-icon>
    </div>
  </ng-container>


</div>
