import { Marketplace } from '@app/interfaces/marketplace';
import { Identifiable } from '@app/interfaces/identifiable';
import { ListingStatus } from '@app/interfaces/listingStatus';

export enum ListingDeleteStatus {
  Pending = 'pending',
  Failed = 'failed',
}

export interface Product extends Identifiable {
  sku: string;
  image: string;
  lastUpdate: string;
  marketplaces: Marketplace[];
  title: string;
  status?: ListingStatus;
  category?: string;
  valid: boolean;
  deletedAt: string | null;
  deletionStatus: ListingDeleteStatus | null;
  marketplaceId?: string;

  [x:string]: unknown;
}

export type ProductKey = string;

export interface Attribute {
  name: string;
  value: string;
  isSystem: boolean;
  sortable?: boolean;
}
