import { Component, Input } from '@angular/core';
import { JuniperComponent } from 'lib-juniper';


@Component({
  selector: 'app-readiness-indicator',
  templateUrl: './readiness-indicator.component.html',
  styleUrls: ['./readiness-indicator.component.scss']
})
export class ReadinessIndicatorComponent extends JuniperComponent {

  @Input()
  ready: boolean = true;

  @Input()
  dotOnTheLeft: boolean = false;

  get componentStyleClasses() {
    return this.compileStyleClasses({ ready: this.ready });
  }

}
