import { Component, Input } from '@angular/core';
import { Marketplace } from '@app/interfaces/marketplace';
import { MarketplaceTagComponent } from '@app/components/marketplaces/marketplace-tag/marketplace-tag.component';

@Component({
  selector: 'app-marketplace-logo-flag',
  styleUrls: ['marketplace-logo-flag.component.scss'],
  templateUrl: 'marketplace-logo-flag.component.html'
})
export class MarketplaceLogoFlagComponent extends MarketplaceTagComponent {

  @Input()
  marketplace!: Marketplace;

}
