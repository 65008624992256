export enum InputControlType {
  Image = 'image',
  ImageGroup = 'image-group',
  Text = 'text',
  TextGroup = 'text-group',
  TextArea = 'textArea',
  Number = 'number',
  Toggle = 'toggle',
  Group = 'group',
  Choice = 'choice',
  Date = 'date',
}
