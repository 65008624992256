import { Component } from '@angular/core';
import { MarketplaceTagComponent } from '@app/components/marketplaces/marketplace-tag/marketplace-tag.component';

@Component({
  selector: 'app-marketplace-domain-tag',
  styleUrls: ['marketplace-domain-tag.component.scss'],
  templateUrl: 'marketplace-domain-tag.component.html'
})
export class MarketplaceDomainTagComponent extends MarketplaceTagComponent {

}
