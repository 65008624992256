<app-header></app-header>
<pup-content>
  <app-notification-bar
    [errorMessage]="'marketplaces.products.error-notification.pending-delete-failed.message' | translate: { count: failedToDeleteProducts.length }"
    *ngIf="failedToDeleteProducts.length">
    <span>
      {{ 'marketplaces.products.error-notification.pending-delete-failed.action.message-start' | translate }}
      <a class="action-link" href="#" (click)="showSubmitDeleteModal($event)">
        {{ 'marketplaces.products.error-notification.pending-delete-failed.action.message-link' | translate }}
      </a>
      {{ 'marketplaces.products.error-notification.pending-delete-failed.action.message-end' | translate }}
    </span>
  </app-notification-bar>
  <pup-heading [level]="2">
    <span>{{ 'marketplaces.products.page-title' | translate }}</span>
    <ng-container *ngIf="productLanguages$ | async as languages">
      <ng-container *ngIf="languages.length">
        <div class="vertical-divider"></div>
        <div
          [tooltip]="languages.length < 2 ? ('marketplaces.products.languages.tooltip-one-language' | translate) : ''"
          tooltipClass="tooltip--limited-width"
        >
          <pup-dropdown-select
            [chevron]="ChevronVariant.Caret"
            [variant]="InputVariant.Transparent"
            [alignment]="DropdownAlignment.Right"
            [disabled]="languages.length < 2"
          >
            <pup-paragraph label>
              {{ ('marketplaces.products.languages.' + (selectedLanguage || languages[0])) | translate }}
            </pup-paragraph>
            <pup-dropdown-menu-item *ngFor="let language of languages" (select)="selectLanguage(language)">
              {{ ('marketplaces.products.languages.' + language) | translate }}
            </pup-dropdown-menu-item>
          </pup-dropdown-select>
        </div>
      </ng-container>
    </ng-container>
  </pup-heading>

  <pup-paragraph class="filter-box">
    <pup-input
      class="mr-6 products--search"
      [clearable]="true"
      [disabled]="loading"
      [placeholder]="'marketplaces.products.search' | translate"
      [(value)]="query"
      (enter)="resetPaginationAndLoad()"
      (clear)="resetPaginationAndLoad()"
    >
      <pup-icon class="search-trigger" path="line/ic-search" (click)="loadProducts()"></pup-icon>
    </pup-input>

    <ng-content select=".extra-controls"></ng-content>

    <pup-button [variant]="ButtonVariant.DarkLink" (click)="showCategorySelectionModal()">
      <pup-icon path="line/ic-category"></pup-icon>
      <span *ngIf="this.categoryService.selectedCategories?.length">
        ({{ this.categoryService.selectedCategories?.length }})&nbsp;
      </span>
      {{ 'marketplaces.products.categories' | translate }}
    </pup-button>

    <pup-button [variant]="ButtonVariant.DarkLink" (click)="showAttributeSelectionModal()">
      <pup-icon path="line/ic-setup"></pup-icon>
      {{ 'marketplaces.products.attributes' | translate }}
    </pup-button>
  </pup-paragraph>
  <span class="search-count" *ngIf="searchResults && products.length">
      {{ 'marketplaces.products.search-results-count' | translate: { count: pagination.totalCount } }}
    </span>

  <app-product-table
    class="products-table"
    [currentPage]="pagination.currentPage"
    [displayedAttributes]="categoryService.displayedProductAttributes"
    [lockedAttributes]="categoryService.lockedAttributes"
    [items]="products"
    [loading]="loading"
    [(marked)]="selectedProductIds"
    [(allSelected)]="allProductsSelected"
    [total]="pagination.totalCount"
    [searchResults]="searchResults"
    (paginationChange)="handlePaginationChange($event)"
    (attributesReorder)="reorderAttributes($event)"
    [sorting]="uiStateService.state?.sorting?.products"
    (sortingChange)="handleSortingChange($event)"
    [queryResults]="queryResults"
    placeholderImgSrc="/assets/visuals/placeholders/empty_product-table.svg"
    [noResultsFoundMessage]="'marketplaces.errors.no-results.message'"
    [noResultsFoundDescription]="'marketplaces.products.no-results.description'"
    [failedToDeleteProductCount]="failedToDeleteProducts.length"
    [excludedFromTotalCount]="pagination.deleted || 0"
  >
    <div class="actions">
      <div class="vertical-divider"></div>
      <pup-button [disabled]="disabled" (click)="addToMarketplace()" [variant]="ButtonVariant.Secondary">
        {{ "marketplaces.products.add-to-marketplace" | translate }}
      </pup-button>
    </div>
  </app-product-table>
</pup-content>

