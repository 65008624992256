import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TooltipPlacement } from 'lib-juniper';

@Component({
  selector: 'app-label-with-tooltip',
  styleUrls: ['./label-with-tooltip.component.scss'],
  templateUrl: './label-with-tooltip.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LabelWithTooltipComponent {

  TooltipPlacement = TooltipPlacement;

  @Input()
  label?: string;

  @Input()
  description?: string;

  @Input()
  required?: boolean;
}
