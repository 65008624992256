import { Component, Input } from '@angular/core';
import { Marketplace } from '@app/interfaces/marketplace';
import { MarketplaceTagComponent } from '@app/components/marketplaces/marketplace-tag/marketplace-tag.component';

@Component({
  selector: 'app-country-tag',
  styleUrls: ['country-tag.component.scss'],
  templateUrl: 'country-tag.component.html'
})
export class CountryTagComponent extends MarketplaceTagComponent {

  @Input()
  marketplace!: Marketplace;

  @Input()
  disabled: boolean = false;

  @Input()
  vendorsCentral: boolean = false;

  @Input()
  sellersCentral: boolean = false;

  @Input()
  showLogo: boolean = false;

  get componentStyleClasses() {
    return this.compileStyleClasses({
      disabled: this.disabled,
    });
  }

}
