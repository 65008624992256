import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Directive, EventEmitter, Injectable, Input, Output } from '@angular/core';

import { TableBaseComponent } from '@app/components/tables/table-base.component';
import { Error, ErrorGroupType, ListingErrorsDisplay } from '@app/interfaces';
import { Identifiable } from '@app/interfaces/identifiable';

// this text does not need to be translated, as it's always part of English error message
const UNKNOWN_ATTRIBUTE_PREFIX = 'unknown attribute';

@Injectable()
@Directive()
export abstract class ErrorTableBase<Item extends Identifiable> extends TableBaseComponent<Item> {

  ErrorGroupType = ErrorGroupType;

  @Input()
  displayColumns: ListingErrorsDisplay[] = [];

  @Output()
  selectError = new EventEmitter<number>();

  @Output()
  attributesReorder = new EventEmitter<{ currentIndex: number, previousIndex: number }>();

  handleSelectError(errorId: number) {
    this.selectError.emit(errorId);
  }

  drop(event: CdkDragDrop<string[]>) {
    const { currentIndex, previousIndex } = event;

    this.attributesReorder.emit({ currentIndex, previousIndex });
  }

  formatErrorMessage(error: Pick<Error, 'group' | 'attributeName' | 'message'> & Partial<Error>, group?: string) {
    let prefix = '';
    const errorGroup = group || error.group;

    if (errorGroup === ErrorGroupType.Other || errorGroup === ErrorGroupType.MarketplaceFeedback && !error.message.includes('[')) {
      prefix = `[${error.attributeName || UNKNOWN_ATTRIBUTE_PREFIX}] `;
    }
    return `${prefix}${error.message}`;
  }
}
