import { Component, Input } from '@angular/core';
import { JuniperComponent } from 'lib-juniper';

@Component({
  selector: 'app-notification-bar',
  templateUrl: './notification-bar.component.html',
  styleUrls: ['./notification-bar.component.scss'],
})
export class NotificationBarComponent extends JuniperComponent {

  @Input()
  errorMessage = '';

  @Input()
  iconPath = 'solid/ic-warning--fill';

}
