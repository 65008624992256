import { Component, Input } from '@angular/core';
import { ButtonVariant, ModalDialogComponent, TooltipPlacement } from 'lib-juniper';
import { Marketplace } from '@app/interfaces/marketplace';

@Component({
  selector: 'app-marketplace-select-modal',
  styleUrls: ['marketplace-select-modal.component.scss'],
  templateUrl: 'marketplace-select-modal.component.html'
})
export class MarketplaceSelectModal extends ModalDialogComponent {

  @Input()
  regions: string[] = [];

  @Input()
  regionMarketplaces: Record<string, Marketplace[]> = {};

  @Input()
  existingMarketplaceIds: number[] = [];

  selectedMarketplaces: number[] = [];

  @Input()
  count?: number;

  ButtonVariant = ButtonVariant;
  TooltipPlacement = TooltipPlacement;

  resolveModal() {
    this.activeModal.close(this.selectedMarketplaces);
  }

  toggleMarketplaceSelection({ id }: Marketplace) {
    const index = this.selectedMarketplaces.indexOf(id);
    if (index > -1) {
      this.selectedMarketplaces.splice(index, 1);
    } else {
      this.selectedMarketplaces.push(id);
    }
  }
}
